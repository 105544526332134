import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const TPA = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}> TAMIZAJE NEONATAL - AMPLIADO </Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Metodo:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 20, paddingHorizontal: 4 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 3 }}>
              <Text style={blueTextStyle}> Prueba </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 10 }]}>
                TSH - Tirotrofina Neonatal
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                17 - Hidroxi Progesterona Neonatal
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                PKU - Fenilalanina Neonatal
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                IRT - Fibrosis Quistica Neonatal
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                Biotinidasa
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                Glucosa 6 Fosfato Desidrogenasa Neonatal
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                Galactosa Neonatal
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={blueTextStyle}> Resultado </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 10 }]}>
                {` ${getValueField(estudio.camposEstudio, "tsh")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "hidroxi")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "pku")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "irt")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "biotinidasa")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "glucosa")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "galactosa")} `}
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={blueTextStyle}> Valor de Referencia </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 10 }]}>
                {` ${getValueField(estudio.camposEstudio, "tsh", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "hidroxi", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "pku", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "irt", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "biotinidasa", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "glucosa", "referencia")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                {` ${getValueField(estudio.camposEstudio, "galactosa", "referencia")} `}
              </Text>
            </View>
          </View>
          {
            getValueField(estudio.camposEstudio, "observaciones") !== "" ?
            <View style={[dataContainerStyle, {marginTop: 20}]}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Observaciones:
                  <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>
                </Text>
              </View>
            </View> : null
          }
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />
        <Footer />
      </Page>
  )
}

export default TPA;