import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportAM = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> {estudio.estudioID.metodo}  </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
              <Text style={[textStyle, dataTextStyle, { textAlign: 'center', marginTop: 5 }]}> {getValueField(estudio.camposEstudio, 'formula_resultado')}</Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:</Text>
              <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion')}</Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="acgh"
          styleFromParent={{ marginTop: 10 }}
          isSigned={estudio.isSigned}
        />

        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )
}

export default ReportAM;