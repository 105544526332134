import createDataContext from './createDataContext';

const INITIAL_STATE = {
  errorMessage: '',
  loading: false,
  user: {
    valid: false
  },
  token: null,
  nav: 1,
  redirectTo: '',
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'clear_message': {
      return { ...state, errorMessage: '' }
    }
    case 'change_waiting_value': {
      return { ...state, loading: !state.loading }
    }
    case 'change_nav_value': {
      return { ...state, nav: action.payload, redirectTo: '' }
    }
    case 'add_error': {
      return { ...state, errorMessage: action.payload, loading: false }
    }
    case 'redirect': {
      return { ...state, redirectTo: action.payload }
    }
    default: return state;
  }
}

const clearMessage = dispatch => () => {
  dispatch({ type: 'clear_message' })
}

const changeWaitingValue = dispatch => () => {
  dispatch({ type: 'change_waiting_value' })
}

const setNavigation = dispatch => (value) => {
  dispatch({ type: 'change_nav_value', payload: value })
}

const setError = dispatch => (error) => {
  dispatch({ type: 'add_error', payload: `${error}` })
}

const setRedirect = dispatch => (path) => {
  console.log(`setRedirect ${path}`)
  dispatch({ type: 'redirect', payload: path })
}

export const { Provider, Context } = createDataContext(authReducer,
  {
    clearMessage,
    changeWaitingValue,
    setNavigation,
    setError,
    setRedirect,
  },
  INITIAL_STATE
)