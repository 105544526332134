import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportCP = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Indicación Clínica:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "indicacion_clinica")} `} </Text>
              </Text>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "tipo_estudio")} `} </Text>
              </Text>
              <Text style={textStyle}>Metodología:
                <Text style={dataTextStyle}> {` ${estudio.estudioID.metodo} `} </Text>
              </Text>
              <View style={dividerStyle}></View>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Cariotipo:</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Conteo de Células en Metafase:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "conteo_celulas_metafase")} `}</Text>
              </Text>
              <Text style={textStyle}>Análisis de Células en Metafase:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "analisis_celulas_metafase")} `}</Text>
              </Text>
              <Text style={textStyle}>Cariotipaje de Células en Metafase:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "cariotipo_celulas")} `}</Text>
              </Text>
              <Text style={textStyle}>Número de Cultivos:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "nro_cultivos")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Método de Bandeo:
                <Text style={dataTextStyle}>{` GTG `}</Text>
              </Text>
              <Text style={textStyle}>Resolución de Bandas:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resolucion_bandas")} `}</Text>
              </Text>
              <Text style={textStyle}>Calidad de la Muestra:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "calidad_de_la_muestra")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Interpretación:</Text>
              <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "interpretacion")} `}</Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Recomendaciones:</Text>
              <Text style={[textStyle, dataTextStyle]}> {` ${getValueField(estudio.camposEstudio, "recomendaciones")} `}</Text>
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco3") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco3", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco4") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco4", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco5") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco5", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco6") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco6", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco7") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco7", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco8") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco8", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco9") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco9", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco10") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco10", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco11") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco11", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Nota:</Text>
              <Text style={[textStyle, dataTextStyle]}>{` El número de células analizadas (20) se estableció mediante estándares internacionales como una medida de detección a un posible mosaicismo, o más de una línea celular presente en el cultivo/paciente. El estudio en 20 células descarta la posibilidad de un mosaicismo en un 14% con un intervalo de confianza del 95% o un mosaicismo en un 21% con un intervalo de confianza del 99%. `}</Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Limitaciones del Estudio:</Text>
              <Text style={[textStyle, dataTextStyle]}> Los resultados citogenéticas convencionales informados aquí pueden no identificar un mosaicismo de bajo porcentaje o una pequeña aberración estructural (sutil).</Text>
              <Text style={[textStyle, dataTextStyle]}> La metodología citogenética estándar utilizada en este análisis puede no detectar pequeños reordenamientos, microdeleciones u otros cambios.</Text>
              <Text style={[textStyle, dataTextStyle]}> Las pruebas realizadas en ausencia de una indicación clínica precisa pueden no dar resultados concluyentes.</Text>
              <Text style={[textStyle, dataTextStyle]}> El análisis de cromosomas no detectará condiciones genéticas con etiologías mendelianas, multifactoriales o ambientales.</Text>
              <Text style={[textStyle, dataTextStyle]}> Cualquier imagen que acompañe a este informe es solo una imagen representativa y no debe utilizarse para el diagnóstico de un paciente.</Text>
            </View>
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 15 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer />
      </Page>
  )
}

export default ReportCP;