import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import FirmaEspecialista from '../informes/FirmaEspecialista';
import { ResultadosHemograma } from './index';

const ReportHC = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Método:
              <Text style={dataTextStyle}> {` ${estudio.estudioID.metodo} `} </Text>
            </Text>
          </View>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Tipo de Muestra:
              <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>Fecha toma de muestra:
              <Text style={dataTextStyle}> {moment(estudio.fechaTomaDeMuestra).utc().format("DD/MM/YYYY")} </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
        <View>
          <View style={[dataContainerStyle, { marginBottom: 5, marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={[textStyle, dataTextStyle, { textAlign: 'left', textTransform: 'uppercase' }]}> Estudio</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle, { textAlign: 'right', textTransform: 'uppercase' }]}> Resultado</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle, { textAlign: 'center', textTransform: 'uppercase' }]}>Unidad</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle, { textAlign: 'right', textTransform: 'uppercase' }]}> Valor de Referencia</Text>
            </View>
          </View>
        </View>

        <ResultadosHemograma estudio={estudio} />

        
      </View>
      <FirmaEspecialista
        nombreEspecialista="ruth"
        styleFromParent={{ marginTop: 25 }}
        isSigned={estudio.isSigned}
      />
      <Footer />
    </Page>
  )
}

export default ReportHC;