import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody, TableCell } from '@david.kucsai/react-pdf-table'
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const FONT_SIZE = 8;

const ReportFQ = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={[pageStyle, { paddingBottom: 95 }]}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:</Text>
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco3") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco3", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco4") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco4", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, 'interpretacion_abierta') !== "" ?
                  <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion_abierta')}</Text> : null
              }
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Notas:</Text>
              <Text style={longTextStyle}> - Fibrosis Quística (FQ) es una enfermedad compleja y multisistémica que afecta el epitelio de varios órganos. Involucra el tracto respiratorio, pánceras exócrino, intestino, sistema hepatobiliar, tracto genital masculino y glándulas sudoríparas. Dicha patología tiene un modo de herencia recesivo e involucra la detección de mutaciones bi-alélicas en el gen CFTR. Pacientes con una mutación causante de FQ y otra variante asociada a enfermedades de menor severidad, pueden desarrollar desórdenes relacionados a CFTR (CFTR-RD). Estos desórdenes no cumplen los criterios clásicos de FQ e involucran insuficiencia pancreática, y/o ausencia congénita de vasos deferentes (CAVD) bilateral (CBAVD) o unilateral (CUAVD), y/o problemas respiratorios. Para más información http://www.ncbi.nlm.nih.gov/books/NBK1250/. </Text>
              <Text style={longTextStyle}> - Este estudio no permite establecer la fase (cis/trans) de las variantes encontradas. En los casos de que más de una variante haya sido identificada se recomienda analizar la portación de las mismas en los padres para confirmar/descartar el diagnóstico. </Text>
              <Text style={longTextStyle}> - El resultado de este estudio deberá ser siempre interpretado en el contexto de datos clínicos y familiares. </Text>
              <Text style={longTextStyle}> - Puede ser beneficioso para esta persona y sus familiares un asesoramiento genético-clínico para discutir las implicancias directas e indirectas de éste resultado.</Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Comentarios </Text>
              <Text style={longTextStyle}> - Es altamente recomendado que la interpretación de este informe se realice con el asesoramiento de un médico familiarizado con el fenotipo del paciente y las causas genéticas que lo producen. Los resultados de este estudio son complementarios y deben ser interpretados en el contexto clínico de cada paciente. </Text>
              <Text style={longTextStyle}> - La ausencia de variantes patogénicas en el gen estudiado no excluye la presencia de variantes patogénicas en otros genes potencialmente asociadas con esta patología. </Text>
              <Text style={longTextStyle}> - Nuestro laboratorio participa periódicamente de los ensayos intercomparativos (EQA Schemes) del "Cystic Fibrosis European Network". </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Metodología NGS:</Text>
              <Text style={longTextStyle}> Extracción y purificación del ADN genómico a partir de la muestra remitida utilizando DNeasy® Blood & Tissue Kit de Qiagen®. Amplificación mediante un Community Panel para CFTR diseñado con el software Ampliseq TM para amplificar el gen CFTR y análisis por secuenciación de amplicones mediante Post- LightTM Ion Semicondutor Sequencing (Next Generation Sequencing) en la plataforma Ion Personal Genome Machine ® System. La amplificación mediante la utilización de este pool de primers permite amplificar un 100 % de la secuencia codificante del gen CFTR y regiones intrónicas flanqueantes, además de 2 regiones intrónicas profundas de interés en búsqueda de mutaciones conocidas cómo causantes de FQ (1811+1.6kb A&gt;G y 3849 + 10 kb C&gt;T). La cobertura mínima admitida es de 30X por amplicón secuenciado. Identificación de variantes con respecto al genoma humano de referencia hg19 (Assembly GRCh37). Se analizan las variantes exónicas y las regiones intrónicas cercanas al exón (distancia menor o igual a 13 pares de bases). Se utiliza el Software Ingenuity Variant AnalysisTM para anotar las variantes encontradas y para el filtrado e interpretación de la significancia clínica de las mismas. Búsqueda de las variantes detectadas en bases de datos internacionales, entre ellas HGMD, CFTR1, CFTR2, CFTR France y bibliografía disponible. Secuencia Referencia CFTR: NM_000492.3. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 0 }]} break>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Limitaciones de la técnica:</Text>
              <Text style={longTextStyle}> Sobre las regiones amplificadas el análisis permite detectar los cambios nucleotídicos simples, así como INS/DEL de hasta aproximadamente 15 pb. </Text>
              <Text style={longTextStyle}> El porcentaje de variantes detectadas es mayor al 95%. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Trazabilidad de la muestra:</Text>
              <Text style={longTextStyle}> Hemos desarrollado un método eficaz para la identificación y seguimiento de la muestra dentro de nuestro laboratorio que nos permite conocer la identidad de la muestra de forma inequívoca desde el ingreso hasta el final del proceso. Para ellos se incluyen 6 STR (microsatélites) con un alto poder de discriminación al panel de amplicones del estudio genético a realizar. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Clasificación de variantes:</Text>
              <Text style={longTextStyle}> Las variantes se clasifican de acuerdo con las guías del Colegio Americano de Genética Médica y Genómica (ACMG). Las categorías de clasificación son: </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Patogénica</Text>: Variante ampliamente descrita en las bases de datos de referencia y/o en la literatura como causante de enfermedad. Evidencia clara de asociación genotipo-fenotipo. </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Patogénica de consecuencias variables con respecto a FQ*</Text>: Pacientes con este tipo de variantes pueden o no desarrollar la enfermedad. En estos casos el criterio clínico es fundamental para determinar su efecto en el paciente. Pacientes con este tipo de variantes pueden presentar características clínicas variables (en tipo y gravedad), incluso dentro de la misma familia. </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Probablemente patogénica</Text>: Variante con un mecanismo probable de patogenicidad que explica el fenotipo del paciente pero que la evidencia actual no permite ser totalmente concluyente. </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Significado incierto</Text>: Variante con información insuficiente o contradictoria acerca de su patogenicidad. </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Probablemente benigna</Text>: Variante que tiene una baja probabilidad de causar enfermedad pero que la evidencia actual no permite ser totalmente concluyente. </Text>
              <Text style={longTextStyle}><Text style={{ textDecoration: 'underline' }}>Benigna</Text>: Variante ampliamente descrita en las bases de datos de referencia y/o en la literatura como no causante de enfermedad. </Text>
              <Text style={longTextStyle}> *Se agrega esta categoría a la actual clasificación ACMG, de acuerdo a las bases de datos CFTR2 y CFTR-France. Solamente son reportadas en el recuadro de resultado las variantes patogénicas y probablemente patogénicas. Las variantes de significado incierto se reportan en observaciones. Variantes probablemente benignas se reportan en anexo. Las variantes benignas no son reportadas, siguiendo las recomendaciones de las guías europeas (Dequeker, 2009). Esta información se encuentra a disposición a solicitud del médico tratante. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Bibliografía y/o bases de datos consultadas: </Text>
              <Text style={longTextStyle}> Se utilizaron las siguientes bases de datos para clasificar y obtener las referencias: </Text>
              <Text style={longTextStyle}> http://www.hgmd.cf.ac.uk/ac/index.php </Text>
              <Text style={longTextStyle}> http://www.omim.org/ </Text>
              <Text style={longTextStyle}> http://www.ncbi.nlm.nih.gov/clinvar/ </Text>
              <Text style={longTextStyle}> http://www.genet.sickkids.on.ca/app </Text>
              <Text style={longTextStyle}> http://www.CFTR2.org/browse.php </Text>
              <Text style={longTextStyle}> https://www.ncbi.nlm.nih.gov/books/NBK1250/ </Text>
              <Text style={longTextStyle}> https://CFTR.iurc.montp.inserm.fr/CFTR/ </Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="amiloidosis"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />

        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )

}



export default ReportFQ;