import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import Header from './informes/Header';
import Footer from './informes/Footer';
import imgFirma from '../assets/ruth.png';
import moment from 'moment';
import { getEdad, calculateResultAN, getANResult } from '../utils/common';
import WaterMark from './informes/WaterMark';
const TIPO_DE_PRUEBA1 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const TECNICA = "ELISA";

const ReportN = ({ item, qrValue }) => {
  const { pageStyle, mainContainerStyle, containerTituloStyle, dataContainerStyle,
    blueTextStyle, titleStyle, textStyle, dividerStyle, ResultTextStyle, rowLeftStyle, rowRightStyle,
    notesTextStyle, showSign, notShowSign, interpretacionHeadlineStyle, qrImageStyle
  } = styles;

  let defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 2);
  if (defaultDateReport > moment()) {
    defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 1);
  }

  const edadPaciente = getEdad(item.clientID.fecha_nacimiento, item.clientID.edad);
  const resultadoAN = item.resultado.split('|');
  const valueAN = calculateResultAN(resultadoAN[0], resultadoAN[1], moment(item.fechaReporte, "DD-MM-YYYY"))
  const resultLabel = getANResult(valueAN);

  const RenderValorReferencia = ({ tipo }) => {
    switch (tipo) {
      case 'negativo': {
        return 'Menor a 1.0';
      }
      case 'bajo-positivo': {
        return '1.0 a 4.9';
      }
      case 'positivo-medio': {
        return '5.0 a 9.9';
      }
      case 'positivo-alto': {
        return 'Mayor a 10';
      }
    }
  }

  return (
    <Document>
      <Page size="A4" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="A4" />
        <View style={mainContainerStyle}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Informacion del paciente:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Nombres: {item.clientID.nombres ? `${item.clientID.nombres}` : ""}</Text>
              <Text style={textStyle}>Apellidos: {item.clientID.apellidos ? `${item.clientID.apellidos}` : ""}</Text>
              <Text style={textStyle}>Carnet de Identidad: {item.clientID.ci ? `${item.clientID.ci}` : ""}</Text>
              <Text style={textStyle}>C&oacute;digo de Paciente: {`${item.codigo}`}</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha de Nacimiento: {item.clientID.fecha_nacimiento ? `${moment(item.clientID.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")}` : ""}</Text>
              <Text style={textStyle}>Edad: {edadPaciente} </Text>
              <Text style={textStyle}>Sexo: {item.clientID.sexo ? `${item.clientID.sexo}` : ""}</Text>
              <Text style={textStyle}>Tel&eacute;fono: {item.clientID.telefono ? `${item.clientID.telefono}` : ""}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha Toma de Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha de Recepci&oacute;n de la Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")} </Text>
              <Text style={textStyle}>Fecha del Informe: {item.fechaReporte ? moment(item.fechaReporte, "DD-MM-YYYY").format("DD/MM/YYYY") : defaultDateReport.format("DD/MM/YYYY")} </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra: {item.tipoDeMuestra}</Text>
              <Text style={textStyle}>T&eacute;cnica: {TECNICA}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>PRUEBA: {TIPO_DE_PRUEBA1}</Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 4, marginBottom: 3 }]}>
            <Text style={textStyle}>RESULTADO:</Text>
            <Text style={ResultTextStyle}>{`${valueAN} (${resultLabel})`}</Text>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 5 }]}>
            <Text style={[interpretacionHeadlineStyle]}>Interpretaci&oacute;n del informe</Text>
          </View>

          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>NEGATIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}> <RenderValorReferencia tipo="negativo" /> </Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente no ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 15 }]}>BAJO POSITIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 15 }]}><RenderValorReferencia tipo="bajo-positivo" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, sin embargo es indicativo de un desarrollo bajo de anticuerpos.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, paddingTop: 15 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>POSITIVO MEDIO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1, paddingTop: 15 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}><RenderValorReferencia tipo="positivo-medio" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, sin embargo es indicativo de un desarrollo moderado de anticuerpos.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 15 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>POSITIVO ALTO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 15 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}><RenderValorReferencia tipo="positivo-alto" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, borderBottomWidth: 1, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, es indicativo de un alto desarrollo de anticuerpos.</Text>
            </View>
          </View>
          <View style={{ marginTop: 30 }}>
            <Image
              src={imgFirma}
              style={item.isSigned ? showSign : notShowSign}
            />
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  mainContainerStyle: {
    marginHorizontal: 40 //60
  },
  containerTituloStyle: {
    marginTop: 0, //20
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10 // 40
  },
  dataContainerStyle: {
    flexDirection: 'row',
  },
  medicoTextStyle: {
    color: '#333',
    fontSize: 12,
    paddingVertical: 2,
    marginLeft: 10
  },
  blueTextStyle: {
    textTransform: 'uppercase',
    color: '#61C0D4',
    fontSize: 12,
    paddingVertical: 2
  },
  titleStyle: {
    fontSize: 18
  },
  textStyle: {
    color: '#333',
    fontSize: 11,
    paddingVertical: 1
  },
  notesTextStyle: {
    color: '#333',
    fontSize: 9,
    paddingVertical: 1
  },
  ResultTextStyle: {
    color: '#000',
    fontSize: 13,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginLeft: 8,
    marginTop: -1
  },
  dividerStyle: {
    height: 2,
    backgroundColor: '#2DB9B4',
    marginVertical: 5 //20
  },
  rowLeftStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5
  },
  rowRightStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5
  },
  showSign: {
    objectFit: 'contain',
    height: 100,
    opacity: 1
  },
  qrImageStyle: {
    objectFit: 'contain',
    height: 80,
    width: 80
  },
  notShowSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 0
  },
  interpretacionHeadlineStyle: {
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
})

export default ReportN;