import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportARVI = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.nombre} `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 31 }]}>
                <Text style={dataTextStyle}>{`${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Técnica:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle]}> Resultados</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Virus Sincitial Respiratorio:
                <Text style={dataTextStyle}>{`    ${getValueField(estudio.camposEstudio, "resultado_virus_sincitial")} `} </Text>
              </Text>
              <Text style={textStyle}>Metapneumovirus:
                <Text style={dataTextStyle}>{`                 ${getValueField(estudio.camposEstudio, "resultado_metapneumovirus")} `} </Text>
              </Text>
              <Text style={textStyle}>Virus Parainfluenza 1:
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "resultado_parainfluenza_1")} `} </Text>
              </Text>
              <Text style={textStyle}>Virus Parainfluenza 2:
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "resultado_parainfluenza_2")} `} </Text>
              </Text>
              <Text style={textStyle}>Virus Parainfluenza 3:
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "resultado_parainfluenza_3")} `} </Text>
              </Text>
              <Text style={textStyle}>Virus Parainfluenza 4:          
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "resultado_parainfluenza_4")} `} </Text>
              </Text>
              <Text style={textStyle}>Enterovirus:      
                <Text style={dataTextStyle}>{`                            ${getValueField(estudio.camposEstudio, "resultado_enterovirus")} `} </Text>
              </Text>
              <Text style={textStyle}>Chlamydophilia pneumoniae:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado_Chlamydophilia_pneumoniae")} `} </Text>
              </Text>
              <Text style={textStyle}>Haemophilus influenza:  
                <Text style={dataTextStyle}>{`         ${getValueField(estudio.camposEstudio, "resultado_Haemophilus_influenzae")} `} </Text>
              </Text>
              <Text style={textStyle}>Moraxella catarrhalis:
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "resultado_Moraxella_catarrhalis")} `} </Text>
              </Text>
        <Text style={textStyle}>Coronavirus 229E:
                <Text style={dataTextStyle}>{`                 ${getValueField(estudio.camposEstudio, "resultado_coronavirus_229")} `} </Text>
              </Text>
        
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Coronavirus NL-63:
                <Text style={dataTextStyle}>{`              ${getValueField(estudio.camposEstudio, "resultado_coronavirus_nl")} `} </Text>
              </Text>
              <Text style={textStyle}>Coronavirus HKU-1:
                <Text style={dataTextStyle}>{`             ${getValueField(estudio.camposEstudio, "resultado_coronavirus_hku")} `} </Text>
              </Text>
              <Text style={textStyle}>Adenovirus:
                <Text style={dataTextStyle}>{`                            ${getValueField(estudio.camposEstudio, "resultado_adenovirus")} `} </Text>
              </Text>
              <Text style={textStyle}>Bocavirus:
                <Text style={dataTextStyle}>{`                              ${getValueField(estudio.camposEstudio, "resultado_bocavirus")} `} </Text>
              </Text>
              <Text style={textStyle}>Rhinovirus:
                <Text style={dataTextStyle}>{`                             ${getValueField(estudio.camposEstudio, "resultado_rhinovirus")} `} </Text>
              </Text>
              <Text style={textStyle}>Influenza A:
                <Text style={dataTextStyle}>{`                            ${getValueField(estudio.camposEstudio, "resultado_influenza_a")} `} </Text>
              </Text>
              <Text style={textStyle}>Influenza B:                             
                <Text style={dataTextStyle}>{`                            ${getValueField(estudio.camposEstudio, "resultado_influenza_b")} `} </Text>
              </Text>
              <Text style={textStyle}>Legionella pneumophila:    
                <Text style={dataTextStyle}>{`        ${getValueField(estudio.camposEstudio, "resultado_Legionella_pneumophila")} `} </Text>
              </Text>
              <Text style={textStyle}>Mycoplasma pneumoniae:     
                <Text style={dataTextStyle}>{`     ${getValueField(estudio.camposEstudio, "resultado_Mycoplasma_pneumoniae")} `} </Text>
              </Text>
              <Text style={textStyle}>Streptococcus pneumoniae:  
                <Text style={dataTextStyle}>{`  ${getValueField(estudio.camposEstudio, "resultado_Streptococcus_pneumoniae")} `} </Text>
              </Text>
        <Text style={textStyle}>Coronavirus OC-43:  
                <Text style={dataTextStyle}>{`              ${getValueField(estudio.camposEstudio, "resultado_coronavirus_oc")} `} </Text>
              </Text>
        
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Sensibilidad:
                <Text style={textStyle}>{` ${getValueField(estudio.camposEstudio, "sensibilidad")} ${getValueField(estudio.camposEstudio, "sensibilidad", "unidad")} `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "observaciones") !== "" ?
                  <Text style={textStyle}>Observaciones:
                    <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>
                  </Text>
                  : null
              }
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
          fechaReporte={estudio.fechaReporte}
        />
        <Footer />
      </Page>
  )

}

export default ReportARVI;