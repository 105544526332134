import React, { useMemo } from 'react';
import {
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
} from '@ant-design/icons';

const AttachmentFile = ({ iconStyle, mimeType, style, url }) => {
  const icon = useMemo(() => {
    if (mimeType === 'application/pdf') {
      return <FilePdfOutlined style={iconStyle} />;
    }

    if ([
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(mimeType)) {
      return <FileWordOutlined style={iconStyle} />;
    }

    if ([
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(mimeType)) {
      return <FileExcelOutlined style={iconStyle} />;
    }

    if (['image/jpeg', 'image/gif', 'image/png'].includes(mimeType)) {
      return <FileImageOutlined style={iconStyle} />;
    }

    return <FileOutlined style={iconStyle} />;
  }, [iconStyle, mimeType]);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" style={style} title="Descargar">
      {icon}
    </a>
  );
};

export default AttachmentFile;
