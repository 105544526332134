import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField, getEdadParto } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportCPPT = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    subtitleBlue
  } = stylesReport;

  const edadPaciente = getEdadParto(paciente.fecha_nacimiento, getValueField(estudio.camposEstudio, "dia_estimado_parto"));

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}>{estudio.estudioID.description} </Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 16 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Datos del Cribado :</Text>
              <Text style={textStyle}>Raza / Etnia:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "raza")} `}</Text>
              </Text>
              <Text style={textStyle}>Peso:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "peso")} ${getValueField(estudio.camposEstudio, "peso", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Fecha del Cribado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_cribado")} `}</Text>
              </Text>
              <Text style={textStyle}>Donante / Óvulos Congelados:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "donante_ovulos")} `}</Text>
              </Text>
              <Text style={textStyle}>Fumadora:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fumadora")} `}</Text>
              </Text>
              <Text style={textStyle}>Diabética:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "diabetica")} `}</Text>
              </Text>
              <Text style={textStyle}>Ovulación Inducida:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "ovolulacion_inducida")} `}</Text>
              </Text>
              <Text style={textStyle}>Gemelos:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "gemelos")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>INFORMACIÓN BIOQUÍMICA:</Text>
              <Text style={textStyle}>Fecha del Análisis de Sangre:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_analisis_sangre")} `}</Text>
              </Text>
              <Text style={textStyle}>Free beta hCG (Elisa):
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "free_beta")} ${getValueField(estudio.camposEstudio, "free_beta", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>PAPP-A (Elisa):
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "papp")} ${getValueField(estudio.camposEstudio, "papp", "unidad")} `}</Text>
              </Text>
              <Text style={[subtitleBlue, { marginTop: 4 }]}>INFORMACIÓN ECOGRÁFICA:</Text>
              <Text style={textStyle}>Fecha de la Ecografía:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_ultrasonido")} `}</Text>
              </Text>
              <Text style={textStyle}>CRL:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "crl")} ${getValueField(estudio.camposEstudio, "crl", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Traslucencia Nucal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "traslucencia")} ${getValueField(estudio.camposEstudio, "traslucencia", "unidad")} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle]}> Resultados del cribado</Text>
          </View>

        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[subtitleBlue, { textTransform: 'none' }]}>MARCADORES GAUSIANOS MoM :</Text>
              <Text style={textStyle}>Free beta hCG:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "free_beta_hcg")} ${getValueField(estudio.camposEstudio, "free_beta_hcg", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>PAPP - A:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "papp_a")} ${getValueField(estudio.camposEstudio, "papp_a", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Traslucencia Nucal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "traslucencia_nucal")} ${getValueField(estudio.camposEstudio, "traslucencia_nucal", "unidad")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>FECHA PREVISTA DE PARTO Y EDAD GESTACIONAL:</Text>
              <Text style={textStyle}>Día estimado del Parto:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "dia_estimado_parto")} (${edadPaciente}) `} </Text>
              </Text>
              <Text style={textStyle}>Edad Gestacional en la fecha del Cribado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "edad_gestacional_cribado")} `}</Text>
              </Text>
              <Text style={textStyle}>Edad Gestacional Método de Cálculo:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "edad_gestacional_metodo")} `}</Text>
              </Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Calculo del riesgo:</Text>
              <Text style={textStyle}>T21 Riesgo:
                <Text style={dataTextStyle}>{`            ${getValueField(estudio.camposEstudio, "t21")}         ${getValueField(estudio.camposEstudio, "t21") === "Riesgo Positivo"? getValueField(estudio.camposEstudio, "cantidad_t21_p") : getValueField(estudio.camposEstudio, "cantidad_t21_n") }`}</Text>
              </Text>
              <Text style={textStyle}>T18 / T13 Riesgo:
                <Text style={dataTextStyle}>{`   ${getValueField(estudio.camposEstudio, "t18")}         ${getValueField(estudio.camposEstudio, "t18") === "Riesgo Positivo"? getValueField(estudio.camposEstudio, "cantidad_t18_p") : getValueField(estudio.camposEstudio, "cantidad_t18_n") }`}</Text>
              </Text>
            </View>
          </View>
        </View>
        {
          getValueField(estudio.camposEstudio, "observaciones") !== "" ?
          <View style={[mainContainerStyle, { marginTop: 8 }]}>
            <View style={[dataContainerStyle]}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <Text style={subtitleBlue}>Observaciones: <Text style={[textStyle, dataTextStyle, {textTransform: 'none'}]}> {` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text></Text>
              </View>
            </View>
          </View>
          : null
        }

        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Comentarios:</Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 4 }]}> Dicho estudio detecta las siguientes condiciones y se limita a estudiar otros problemas genéticos:</Text>
              <Text style={[textStyle, dataTextStyle]}> Sd. de Down(Trisomía 21), Sd. de Edwards (Trisomía 18),Sd. de Patau(Trisomía 13).</Text>
              <Text style={[textStyle, dataTextStyle]}> Esta es una prueba de screening.</Text>
              <Text style={[textStyle, dataTextStyle]}> El índice de riesgo es un cálculo estadístico y no tiene ningún valor diagnóstico.</Text>
              <Text style={[textStyle, dataTextStyle]}>Por favor revise cuidadosamente todos los detalles del informe.</Text>
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />
        <Footer />
      </Page>
  )
}

export default ReportCPPT;