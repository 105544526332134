import { StyleSheet } from "@react-pdf/renderer";

const TEXT_SIZE_TITULOS = 9;
const TEXT_SIZE_SUBTITULOS = 8.5;
const TEXT_SIZE_BODY = 8;
const LONG_TEXT_SIZE = 7;
const SMALL_TEXT_SIZE = 6;
const LIGHT_BLUE = "#b2deec";
const DARK_BLUE = "#99D1DB";
const GRAY_COLOR = "#848484";
const BLACK_COLOR = "#000";
const BLACK_DARK_COLOR = "#000";
const DIVIDER_COLOR = "#2DB9B4";

const stylesReport = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    paddingBottom: 80,
  },
  pageStyleQR: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    paddingBottom: 0,
  },
  pageStyleInvoice: {
    flex: 1,
    backgroundColor: "#FFFFFF",
    paddingBottom: 80,
  },
  textStyle: {
    color: GRAY_COLOR,
    fontSize: TEXT_SIZE_BODY,
    paddingVertical: 1.5,
  },
  textBiggerStyle: {
    color: GRAY_COLOR,
    fontSize: TEXT_SIZE_TITULOS,
    paddingVertical: 1.5,
  },
  dataTextStyle: {
    color: BLACK_COLOR,
    textAlign: "justify",
    testJustify: "inter-word",
  },
  longTextStyle: {
    color: BLACK_COLOR,
    fontSize: TEXT_SIZE_BODY,
    paddingVertical: 1.3,
    textAlign: "justify",
    testJustify: "inter-word",
  },
  dataContainerStyle: {
    flexDirection: "row",
  },
  blueTextStyle: {
    textTransform: "uppercase",
    color: DARK_BLUE,
    fontSize: TEXT_SIZE_TITULOS,
    paddingBottom: 3,
  },
  subtitleBlue: {
    textTransform: "uppercase",
    color: DARK_BLUE,
    fontSize: TEXT_SIZE_SUBTITULOS,
    paddingBottom: 2,
  },
  mainContainerStyle: {
    marginHorizontal: 40, //60
  },
  mainContainerStyleInvoice: {
    marginHorizontal: 30, //60
  },
  dividerStyle: {
    height: 1,
    backgroundColor: DIVIDER_COLOR,
    marginVertical: 3,
  },
  rowLeftStyle: {
    borderColor: GRAY_COLOR,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5,
  },
  rowRightStyle: {
    borderColor: GRAY_COLOR,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5,
  },
  notesTextStyle: {
    color: BLACK_COLOR,
    fontSize: TEXT_SIZE_BODY,
    paddingVertical: 1,
  },
  semiTitlesTextStyle: {
    color: BLACK_COLOR,
    fontSize: TEXT_SIZE_TITULOS,
    paddingVertical: 1,
  },
  showSignSmall: {
    objectFit: "contain",
    height: 60,
    opacity: 1,
  },
  showSign: {
    objectFit: "contain",
    height: 80,
    opacity: 1,
  },
  notShowSign: {
    objectFit: "contain",
    height: 80,
    opacity: 0,
  },
  qrImageStyle: {
    objectFit: "contain",
    height: 80,
    width: 80,
  },
  qrImageStyleSmall: {
    objectFit: "contain",
    height: 40,
    width: 60,
  },
  pageNumberStyle: {
    position: "absolute",
    fontSize: TEXT_SIZE_BODY,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "right",
    color: GRAY_COLOR,
  },
  notaDeServicio: {
    fontSize: SMALL_TEXT_SIZE,
  },
});

export default stylesReport;
