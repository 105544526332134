import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, DatePicker, Table, Tag } from 'antd';
import { Redirect } from "react-router";
import { useManualQuery } from 'graphql-hooks';
import { GET_FICHAS_PACIENTE_PUBLIC, GET_ESTUDIOS_PACIENTE_PUBLIC } from '../queries';

import moment from 'moment';
import DownloadButton from '../components/informes/DownloadButton';
import OldDownloadButton from '../components/DownloadButton';

const ERROR_REPORTE_EMPRESA = `Por favor comunicarse con su empresa, o centro de salud\npara saber mas detalles sobre el resultado de su prueba.`;
const ERROR_REPORTE_PAGO = `Por favor confirmar su pago para acceder al resultado.`;
const DATELINE = moment("10/08/2020", "DD/MM/YYYY");
const INFORMES_NO_DISPONIBLES = ['EN PROCESO', 'NULO', 'CANCELADO', "PENDIENTE"];
const queryString = require('query-string');

// verify?key=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlc3R1ZGlvWE9yZGVuSWQiOiI2MDk0ODgzYTdjOTZiNDE4MjM3NzI5NWYiLCJpYXQiOjE2MjAzNTMwNTR9.qEFxvlmjm-FtvdGqf5cHVNPl_hg2bU-Nu7-uDhJbG-c
// resultados-orden?key=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcmRlbklkIjoiNjA2OTA2N2E4NjQ3YTIwMDA0NTkzMjVjIiwiaWF0IjoxNjIxNDc5NDk0fQ.Ed7t7yUWwt7qVA3wIdinpRLmDbKqUaOpvJbnmo00mKo

const MisResultados = (props) => {

  const { Title, Text, Paragraph } = Typography;

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (!parsed.key) {
      console.log("regreso a home")
      // return <Redirect to="/" />;
    }
    verificarOrden(parsed.key);
  }, [props.location.search]);



  const verificarOrden = (key) => {
    // verifyFicha({
    //   variables: { keyHash: key }
    // }).then(({ data, error }) => {
    //   if (error) {
    //     setError(error.graphQLErrors[0].message);
    //     return;
    //   }
    //   if (data.verifyFicha) {
    //     setInfo(data.verifyFicha);
    //   }
    // })
  }

  const columns = [
    {
      title: 'Nombre Estudio',
      dataIndex: 'estudioID.nombre',
      key: 'estudioID',
      render: (estudioID, item) => {
        if (item.testID) {
          return (
            <Text>{item.testID.nombre}</Text>
          )
        }
        return (
          <Text>{item.estudioID.nombre}</Text>
        )
      }
    },
    {
      title: 'Fecha Toma de Muestra',
      dataIndex: 'fechaTomaDeMuestra',
      key: 'fechaTomaDeMuestra',
      render: (fechaTomaDeMuestra, item) => {
        if (item.testID) {
          return (
            <Text>{item.fechaSolicitud}</Text>
          )
        }
        return (
          <Text>
            {
              moment(item.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                  ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY')}`
                    :
                ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY HH:mm')}`
            }
          </Text>
        )
      }
    },
    {
      title: 'Codigo Informe',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (codigo, item) => {
        if (item.testID) {
          return (
            <Text>{item.codigo}</Text>
          )
        }
        return (
          <Text>{item.codigoEstudio}{item.estudioNumero}</Text>
        )
      }
    },
    {
      title: 'Estatus',
      key: 'estadoEstudio',
      dataIndex: 'estadoEstudio',
      render: (estadoEstudio, item) => {
        let color = 'orange';
        if (item.testID) {
          if (item.tipoFicha.trim().toUpperCase() !== "PARTICULAR" && item.tipoFicha.trim().toUpperCase() !== "DOMICILIO") {
            return (
              <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mas' }}>
                {ERROR_REPORTE_EMPRESA}
              </Paragraph>
            )
          }

          if (moment(item.fechaSolicitud, "DD/MM/YYYY").isSameOrAfter(DATELINE)) {
            if (!item.pagoStatus || item.pagoStatus === "") {
              return (
                <Tag color={color}>
                  EN PROCESO
                </Tag>
              )
            }

            if (item.pagoStatus === "POR COBRAR") {
              return (
                <Tag color='orange'>
                  EN PROCESO
                </Tag>
              )
            }
          }

          if (!item.resultado) {
            return (
              <Tag color={color}>
                NO DISPONIBLE
              </Tag>
            )
          }

          if (INFORMES_NO_DISPONIBLES.includes(item.resultado.toUpperCase())) {
            return (
              <Tag color={color}>
                NO DISPONIBLE
              </Tag>
            )
          }

          return (
            <Tag color='green'>
              DISPONIBLE
            </Tag>
          )
        }

        if (item.ordenID.proveedorID.nombre.toUpperCase() !== "PARTICULAR") {
          return (
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mas' }}>
              {ERROR_REPORTE_EMPRESA}
            </Paragraph>
          )
        }

        if (item.estadoEstudio !== 'PROCESADO') {
          return (
            <Tag color={color}>
              {item.estadoEstudio}
            </Tag>
          )
        }

        color = 'green';

        return (
          <Tag color={color}>
            DISPONIBLE
          </Tag>
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => {
        if (item.testID) {
          if (item.tipoFicha.trim().toUpperCase() !== "PARTICULAR" && item.tipoFicha.trim().toUpperCase() !== "DOMICILIO") {
            return null;
          }

          if (moment(item.fechaSolicitud, "DD/MM/YYYY").isSameOrAfter(DATELINE)) {
            if (!item.pagoStatus) {
              return null;
            }

            if (item.pagoStatus === "POR COBRAR") {
              return null;
            }
          }

          if (!item.resultado) {
            return null;
          }

          if (INFORMES_NO_DISPONIBLES.includes(item.resultado.toUpperCase())) {
            return null;
          }

          return (
            <OldDownloadButton rowData={item} test={item.testID} />
          )
        }

        if (item.estadoEstudio !== 'PROCESADO' || item.ordenID.proveedorID.nombre.toUpperCase() !== "PARTICULAR") {
          return null;
        }

        return (
          <DownloadButton size="small" disabled={false} estudio={item} cliente={item.ordenID.clientID} />
        )
      },
    },
  ];

  return (
    <>
      <Row type="flex" style={{ backgroundColor: 'white' }}>

      </Row>
    </>
  )

}

export default MisResultados;