import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportDZC = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;


  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.nombre} `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 31 }]}>
                <Text style={dataTextStyle}>{`${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Técnica:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Dengue :
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "dengue")} `} </Text>
              </Text>
              <Text style={textStyle}>Chikungunya :
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "chikun")} `} </Text>
              </Text>
              <Text style={textStyle}>Zika :
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "zika")} `} </Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              {
                getValueField(estudio.camposEstudio, "observaciones") !== "" ?
                  <Text style={textStyle}>Observaciones:
                    <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>
                  </Text>
                  : null
              }
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
          fechaReporte={estudio.fechaReporte}
        />
        <Footer />
      </Page>
  )

}

export default ReportDZC;