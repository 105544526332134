import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import moment from 'moment';
import { getEdad } from '../utils/common';
import Header from './informes/Header';
import Footer from './informes/Footer';
import WaterMark from './informes/WaterMark';
import imgFirma from '../assets/ruth.png';

const TIPO_DE_PRUEBA = "Antigeno - SARS-CoV-2";
const TECNICA = "Inmunocromatografia";

const ReportA = ({ item, qrValue }) => {
  const { pageStyle, mainContainerStyle, containerTituloStyle, dataContainerStyle,
    blueTextStyle, titleStyle, textStyle, dividerStyle, ResultTextStyle,
    showSign, notShowSign, qrImageStyle
  } = styles;

  let defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 2);
  if (defaultDateReport > moment()) {
    defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 1);
  }
  const edadPaciente = getEdad(item.clientID.fecha_nacimiento, item.clientID.edad);
  return (
    <Document>
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <View style={mainContainerStyle}>
          <View style={[dataContainerStyle, { marginTop: 40 }]}>
            <Text style={blueTextStyle}>Informacion del paciente:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Nombres: {item.clientID.nombres ? `${item.clientID.nombres}` : ""}</Text>
              <Text style={textStyle}>Apellidos: {item.clientID.apellidos ? `${item.clientID.apellidos}` : ""}</Text>
              <Text style={textStyle}>Carnet de Identidad: {item.clientID.ci ? `${item.clientID.ci}` : ""}</Text>
              <Text style={textStyle}>C&oacute;digo de Paciente: {`${item.codigo}`}</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha de Nacimiento: {item.clientID.fecha_nacimiento ? `${moment(item.clientID.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")}` : ""}</Text>
              <Text style={textStyle}>Edad: {edadPaciente} </Text>
              <Text style={textStyle}>Sexo: {item.clientID.sexo ? `${item.clientID.sexo}` : ""}</Text>
              <Text style={textStyle}>Tel&eacute;fono: {item.clientID.telefono ? `${item.clientID.telefono}` : ""}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha Toma de Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha de Recepci&oacute;n de la Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha del Informe: {item.fechaReporte ? moment(item.fechaReporte, "DD-MM-YYYY").format("DD/MM/YYYY") : defaultDateReport.format("DD/MM/YYYY")} </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra: {item.tipoDeMuestra}</Text>
              <Text style={textStyle}>Prueba: {TIPO_DE_PRUEBA}</Text>
              <Text style={textStyle}>T&eacute;cnica: {TECNICA}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 3, marginBottom: 3 }]}>
            <Text style={textStyle}>Resultado:</Text>
            <Text style={ResultTextStyle}>{`${item.resultado}`}</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 5 }]} >
            <Text style={textStyle}>Observaciones: Los resultados emitidos deben ser interpretados por el médico tratante y/o valoración médica.</Text>
          </View>
          <View style={dividerStyle}></View>
          <View style={{ marginTop: 40 }}>
            <Image
              src={imgFirma}
              style={item.isSigned ? showSign : notShowSign}
            />
          </View>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  mainContainerStyle: {
    marginHorizontal: 60 //60
  },
  containerTituloStyle: {
    marginTop: 0, //20
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8 // 40
  },
  dataContainerStyle: {
    flexDirection: 'row',
  },
  blueTextStyle: {
    textTransform: 'uppercase',
    color: '#61C0D4',
    fontSize: 12,
    paddingVertical: 2
  },
  titleStyle: {
    fontSize: 18
  },
  textStyle: {
    color: '#333',
    fontSize: 11,
    paddingVertical: 1
  },
  notesTextStyle: {
    color: '#333',
    fontSize: 9,
    paddingVertical: 1
  },
  ResultTextStyle: {
    color: '#000',
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginLeft: 15,
    marginTop: -2
  },
  dividerStyle: {
    height: 2,
    backgroundColor: '#2DB9B4',
    marginVertical: 5 //20
  },
  rowLeftStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5
  },
  rowRightStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5
  },
  showSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 1
  },
  qrImageStyle: {
    objectFit: 'contain',
    height: 80,
    width: 80
  },
  notShowSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 0
  },
  interpretacionHeadlineStyle: {
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
})

export default ReportA;