import React from "react";
import { pdf, Document } from "@react-pdf/renderer";
import jwt from "jsonwebtoken";
import { saveAs } from "file-saver";
import { Button, Popconfirm } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import ReportElisa from "../../components/reports/ReportElisa";
import ReportPCRCOVID from "../../components/reports/C";
import ReportPPNI from "../../components/reports/PPNI";
import ReportPPNIE from "../../components/reports/PPNIE";
import ReportFishPrenatal from "../../components/reports/FP";
import ReportFishPOC from "../reports/FPOC";
import ReportCP from "../../components/reports/CP";
import ReportCPPT from "../../components/reports/CPPT";
import ReportCPPT2 from "../../components/reports/CPPT2";
import ReportDP from "../../components/reports/DP";
import ReportVPH from "../../components/reports/VPH";
import ReportCHEB from "../../components/reports/CHEB";
import ReportMT from "../../components/reports/MT";
import ReportHIV from "../../components/reports/HIV";
import ReportARVI from "../../components/reports/ARVI";
import TPB from "../../components/reports/TPB";
import TPA from "../../components/reports/TPA";
import R from "../../components/reports/R";
import TXG from "../../components/reports/TXG";
import CPRE from "../../components/reports/CPRE";
import HSG from "../../components/reports/HSG";
import PJ from "../../components/reports/PJ";
import FE from "../../components/reports/FE";
import AN from "../../components/reports/AN";
import ASC from "../../components/reports/ASC";
import AM from "../../components/reports/AM";
import ACGH from "../../components/reports/ACGH";
import EX from "../../components/reports/EX";
import FQ from "../../components/reports/FQ";
import XF from "../../components/reports/XF";
import PR from "../../components/reports/PR";
import PP from "../../components/reports/PP";
import BRNM from "../../components/reports/BRNM";
import CH from "../../components/reports/CH";
import {
  HA,
  ANT,
  CEN,
  ReportASC_EN,
  COR,
  Simple,
  HO,
  HC,
  DZC,
  CAB,
  GHLA,
  ReportFH,
  ReportPDM,
} from "../../components/reports";
import {
  areasResultadosSimple,
  CODES_ESTUDIES,
  excepcionesEstudiosNoSencillos,
} from "../../config/constants";
import QRCode from "qrcode.react";
const SECRET_HASH = process.env.REACT_APP_SECRET_HASH;

const DownloadButton = ({ size, disabled, estudio, cliente }) => {
  const reportIDHash = jwt.sign({ estudioXOrdenId: estudio.id }, SECRET_HASH);
  const verifiedReportURL = `https://vigilant-goldberg-714272.netlify.app/verificar-estudio?key=${reportIDHash}`;

  const generateLetterPdf = async (language = "ES") => {
    // const { _id } = item;
    // console.log(estudio)

    if (estudio) {
      const qrCodeCanvas = document.getElementById(`canvas-${estudio.id}`);
      const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);
      switch (estudio.codigoEstudio) {
        case "E": {
          console.log("ELISA");
          const blob = await pdf(
            <Document>
              <ReportElisa
                estudio={estudio}
                paciente={cliente}
                qrValue={qrCodeDataUri}
              />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ELICOV": {
          console.log("ELISA");
          const blob = await pdf(
            <Document>
              <ReportElisa
                estudio={estudio}
                paciente={cliente}
                qrValue={qrCodeDataUri}
              />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PCRC": {
          console.log("PCR COVID");
          const blob =
            language === "ES"
              ? await pdf(
                  <Document>
                    <ReportPCRCOVID
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                    />
                  </Document>
                ).toBlob()
              : await pdf(
                  <Document>
                    <CEN
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                      language={language}
                    />
                  </Document>
                ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PCRCS": {
          console.log("PCR COVID Saliva");
          const blob =
            language === "ES"
              ? await pdf(
                  <Document>
                    <ReportPCRCOVID
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                    />
                  </Document>
                ).toBlob()
              : await pdf(
                  <Document>
                    <CEN
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                      language={language}
                    />
                  </Document>
                ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PPNI": {
          console.log("Prueba Prenatal no invasiva - PANORAMA");
          const blob = await pdf(
            <Document>
              <ReportPPNI estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PPNIE": {
          console.log("Prueba Prenatal no invasiva - PANORAMA Extendido");
          const blob = await pdf(
            <Document>
              <ReportPPNIE estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FP": {
          console.log("FISH PRENATAL");
          const blob = await pdf(
            <Document>
              <ReportFishPrenatal estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FPOC": {
          console.log("FISH POC");
          const blob = await pdf(
            <Document>
              <ReportFishPOC estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FH": {
          console.log("FISH HEMATOLOGICO");
          const blob = await pdf(
            <Document>
              <ReportFH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FPE": {
          console.log("FISH PEDIATRICO");
          const blob = await pdf(
            <Document>
              <ReportFH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FE": {
          console.log("FISH en Esperma");
          const blob = await pdf(
            <Document>
              <FE estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CP": {
          console.log("Cariotipo Pediátrico");
          const blob = await pdf(
            <Document>
              <ReportCP estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CA": {
          console.log("Cariotipo ADULTO O PARENTAL");
          const blob = await pdf(
            <Document>
              <ReportCP estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CI": {
          console.log("Cariotipo Infertilidad");
          const blob = await pdf(
            <Document>
              <ReportCP estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CPRE": {
          console.log("Cariotipo Prenatal");
          const blob = await pdf(
            <Document>
              <CPRE estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CPPT": {
          console.log("CRIBADO DE HORMONAS EN EL 1ER TRIMESTRE ");
          const blob = await pdf(
            <Document>
              <ReportCPPT estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CPPT2": {
          console.log("CRIBADO DE HORMONAS EN EL 2DO TRIMESTRE ");
          const blob = await pdf(
            <Document>
              <ReportCPPT2 estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "DP": {
          console.log("DETECCIÓN DE LA PREECLAMPSIA");
          const blob = await pdf(
            <Document>
              <ReportDP estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "VPH": {
          console.log(
            "PCR VPH CON GENOTIPIFICACION, CUANTIFICACION Y DIFERENCIACION DE 14 SUBTIPOS"
          );
          const blob = await pdf(
            <Document>
              <ReportVPH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CHEB": {
          console.log(
            "PCR MULTIPLEX CUANTIFICACION Y DIFERENCIACION DE CMV, HERPES 6 Y EBV CUANTITATIVO"
          );
          const blob = await pdf(
            <Document>
              <ReportCHEB estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "MT": {
          console.log("MYCOBACTERIUM TUBERCULOSIS");
          const blob = await pdf(
            <Document>
              <ReportMT estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HIV": {
          console.log("PCR HIV CUANTITATIVO");
          const blob = await pdf(
            <Document>
              <ReportHIV estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ARVI": {
          console.log("PCR PANEL DE VIRUS RESPIRATORIO");
          const blob = await pdf(
            <Document>
              <ReportARVI estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "TPB": {
          console.log("TEST DEL PIECITO BÁSICO");
          const blob = await pdf(
            <Document>
              <TPB estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "TPA": {
          console.log("TEST DEL PIECITO AMPLIADO");
          const blob = await pdf(
            <Document>
              <TPA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "R": {
          console.log("RUBEOLA");
          const blob = await pdf(
            <Document>
              <R estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "TXG": {
          console.log("TOXOPLASMA");
          const blob = await pdf(
            <Document>
              <TXG estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HSG": {
          console.log("Herpes Simple I y II");
          const blob = await pdf(
            <Document>
              <HSG estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PJ": {
          console.log("PNEUMOCYSTIS JIROVECII");
          const blob = await pdf(
            <Document>
              <PJ estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "AN": {
          console.log("ANTICUERPOS NEUTRALIZANTES ACE2-RBD");
          // Por uso de un nuevo reactivo a partir del 26/11/2021 se va a usar el template de ANT
          const fechaSolicitud = moment(estudio.fechaSolicitud).utc();
          const blob = await pdf(
            <Document>
              {fechaSolicitud.isSameOrBefore("2021-11-25") ? (
                <AN
                  estudio={estudio}
                  paciente={cliente}
                  qrValue={qrCodeDataUri}
                />
              ) : (
                <ANT
                  estudio={estudio}
                  paciente={cliente}
                  qrValue={qrCodeDataUri}
                />
              )}
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ANT": {
          console.log("ANTICUERPOS NEUTRALIZANTES ACE2-RBD (TOTAL)");
          const blob = await pdf(
            <Document>
              <ANT
                estudio={estudio}
                paciente={cliente}
                qrValue={qrCodeDataUri}
              />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ASC": {
          console.log("ANTIGENO - SARS-COV2");
          const blob =
            language === "ES"
              ? await pdf(
                  <Document>
                    <ASC
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                    />
                  </Document>
                ).toBlob()
              : await pdf(
                  <Document>
                    <ReportASC_EN
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                      language={language}
                    />
                  </Document>
                ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ANTU": {
          console.log("ANTIGENO - SARS-COV2 (AUTOCOVID URUBO)");
          const blob =
            language === "ES"
              ? await pdf(
                  <Document>
                    <ASC
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                    />
                  </Document>
                ).toBlob()
              : await pdf(
                  <Document>
                    <ReportASC_EN
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                      language={language}
                    />
                  </Document>
                ).toBlob();

          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "AA": {
          console.log("ANTIGENO - SARS-COV2 (AUTOCOVID 4to anillo)");
          const blob =
            language === "ES"
              ? await pdf(
                  <Document>
                    <ASC
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                    />
                  </Document>
                ).toBlob()
              : await pdf(
                  <Document>
                    <ReportASC_EN
                      estudio={estudio}
                      paciente={cliente}
                      qrValue={qrCodeDataUri}
                      language={language}
                    />
                  </Document>
                ).toBlob();

          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "AM": {
          console.log("AMILOIDOSIS");
          const blob = await pdf(
            <Document>
              <AM estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "ACGH": {
          console.log("CGH ARRAY");
          const blob = await pdf(
            <Document>
              <ACGH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "EX": {
          console.log("EXOMA");
          const blob = await pdf(
            <Document>
              <EX estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "FQ": {
          console.log("FIBROSIS QUISTICA");
          const blob = await pdf(
            <Document>
              <FQ estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "XF": {
          console.log("XFRAGIL");
          const blob = await pdf(
            <Document>
              <XF estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PR": {
          console.log("PANEL 21 ENFERMEDADES");
          const blob = await pdf(
            <Document>
              <PR estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "PP": {
          console.log("PRUEBA PATERNIDAD");
          const blob = await pdf(
            <Document>
              <PP estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "BRNM": {
          console.log("BRACA 1 y 2 NGS y MLPA");
          const blob = await pdf(
            <Document>
              <BRNM estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();

          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CH": {
          console.log("Cariotipo HEMATOLÓGICO");
          const blob = await pdf(
            <Document>
              <CH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HA": {
          console.log("Hepatitis A");
          const blob = await pdf(
            <Document>
              <HA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HBC": {
          console.log("Hepatitis B Cuantitativo");
          const blob = await pdf(
            <Document>
              <HA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HBG": {
          console.log("Hepatitis B Genotipificacion");
          const blob = await pdf(
            <Document>
              <HA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HCC": {
          console.log("Hepatitis C Cuantitativo");
          const blob = await pdf(
            <Document>
              <HA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HCG": {
          console.log("Hepatitis C Genotipificacion");
          const blob = await pdf(
            <Document>
              <HA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "COR": {
          console.log("PCR COVID");
          const blob = await pdf(
            <Document>
              <COR
                estudio={estudio}
                paciente={cliente}
                qrValue={qrCodeDataUri}
              />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HO": {
          console.log("HORIZON (4)");
          const blob = await pdf(
            <Document>
              <HO estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "HC": {
          console.log("Hemograma Completo");
          const blob = await pdf(
            <Document>
              <HC estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "DZC": {
          console.log("PCR Multiplex Dengue Zika Chicungunya");
          const blob = await pdf(
            <Document>
              <DZC estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "CAB": {
          console.log("PCR Multiplex COVID-19, Influenza A, Influenza B");
          const blob = await pdf(
            <Document>
              <CAB
                estudio={estudio}
                paciente={cliente}
                qrValue={qrCodeDataUri}
              />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "GHLA": {
          console.log("PCR Multiplex COVID-19, Influenza A, Influenza B");
          const blob = await pdf(
            <Document>
              <GHLA estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }
        case "VPHG": {
          console.log("PCR VPH genotipo 16 y 18");
          const blob = await pdf(
            <Document>
              <ReportVPH estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }

        case "PDM": {
          console.log("PANEL DE MUTACIONES PARA FACTORES DE COAGULACIÓN");
          const blob = await pdf(
            <Document>
              <ReportPDM estudio={estudio} paciente={cliente} />
            </Document>
          ).toBlob();
          const name = cliente.nombres ? cliente.nombres : "";
          const familyName = cliente.apellidos ? cliente.apellidos : "";
          saveAs(
            blob,
            `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
          );
          break;
        }

        default: {
          if (areasResultadosSimple.includes(estudio.areaDeEstudioID.id)) {
            console.log("PERTENECE A UN ESTUDIO SIMPLE");
            const blob = await pdf(
              <Document>
                <Simple estudio={estudio} paciente={cliente} />
              </Document>
            ).toBlob();
            const name = cliente.nombres ? cliente.nombres : "";
            const familyName = cliente.apellidos ? cliente.apellidos : "";
            saveAs(
              blob,
              `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
            );
            break;
          }
          if (excepcionesEstudiosNoSencillos.includes(estudio.estudioID.id)) {
            console.log("es un resultado simple");
            const blob = await pdf(
              <Document>
                <Simple estudio={estudio} paciente={cliente} />
              </Document>
            ).toBlob();
            const name = cliente.nombres ? cliente.nombres : "";
            const familyName = cliente.apellidos ? cliente.apellidos : "";
            saveAs(
              blob,
              `${estudio.codigoEstudio}${estudio.estudioNumero}-${name} ${familyName}.pdf`
            );
            break;
          }

          console.log("NOT SUPPORTED " + estudio.codigoEstudio);
          break;
        }
      }
    } else {
      alert("El informe para esta prueba aun no esta disponible.");
    }
  };

  const confirm = () => {
    generateLetterPdf("ES");
  };

  const cancel = (e) => {
    generateLetterPdf("EN");
  };

  const showConfirmation = () => {
    confirmAlert({
      title: "¿Idioma en el que desea descargar el informe?",
      buttons: [
        {
          label: "Español",
          onClick: () => confirm(),
        },
        {
          label: "Ingles",
          onClick: () => cancel(),
        },
      ],
    });
  };

  const ConfirmButton = () => {
    // const path = `/informes/${rowData._id}/edit`;
    return (
      <Popconfirm
        title="¿Idioma en el que desea descargar el informe?"
        onConfirm={(e) => confirm()}
        onCancel={cancel}
        okText="Español"
        cancelText="Ingles"
        placement="left"
      >
        <Button
          type={disabled ? "text" : "primary"}
          shape="round"
          size="middle"
          disabled={disabled}
          icon={<DownloadOutlined />}
          onClick={(e) => {
            e.preventDefault();
          }}
        ></Button>
      </Popconfirm>
    );
  };

  if (size === "small") {
    return (
      <>
        <Button
          type={disabled ? "text" : "primary"}
          shape="round"
          icon={<DownloadOutlined />}
          size="middle"
          disabled={disabled}
          onClick={() =>
            CODES_ESTUDIES.includes(estudio.codigoEstudio)
              ? showConfirmation()
              : generateLetterPdf()
          }
        ></Button>
        <div style={{ display: "none" }}>
          <QRCode
            id={`canvas-${estudio.id}`}
            value={verifiedReportURL}
            size={128}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div style={{ display: "none" }}>
        <QRCode
          id={`canvas-${estudio.id}`}
          value={verifiedReportURL}
          size={128}
        />
      </div>
      <Button
        type={disabled ? "text" : "primary"}
        shape="round"
        icon={<DownloadOutlined />}
        size="middle"
        disabled={disabled}
        onClick={() => generateLetterPdf()}
      ></Button>
    </>
  );
};

export default DownloadButton;
