import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista'
import WaterMark from '../informes/WaterMark';

const ReportHO = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    textBiggerStyle,
    semiTitlesTextStyle,
    longTextStyle
  } = stylesReport;

  return (
    <Page size="A4" wrap={true} style={[pageStyle, { paddingBottom: 100 }]}>
      <Header />
      <WaterMark typePage="A4" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        CRH={getValueField(estudio.camposEstudio, "kit_r")}
        IDR={getValueField(estudio.camposEstudio, "id_referencia")}
        IDARCHIVO={getValueField(estudio.camposEstudio, "id_archivo")}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Prueba:
              <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
            </Text>
            <Text style={textStyle}>Tipo de Muestra:
              <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>Técnica:
              <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textBiggerStyle}>RESUMEN DE RESULTADO FINAL:
              <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resumen_resultado")} `}</Text>
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 4 }]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Enfermedades Examinadas:
              <Text style={dataTextStyle}>{` (A continuación se muestra la lista de todas las enfermedades examinadas y el resultado. Ciertas condiciones tienen valores numéricos únicos específicos del paciente, por lo tanto, los resultados para esas condiciones tienen un formato diferente.) `}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Autosómico recesivo:</Text>
            <Text style={[textStyle, { marginTop: 4 }]}>C</Text>
            <Text style={textStyle}>Fibrosis quística (CFTR)
              <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fibrosis")} `}</Text>
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={[textStyle, { marginTop: 4 }]}>S</Text>
            <Text style={textStyle}>Atrofia muscular espinal (SMN1)
              <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "atrofia")} ${getValueField(estudio.camposEstudio, "comment_smn1")}`}</Text>
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 8 }]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>Ligadas al cromosoma X:</Text>
            <Text style={[textStyle, { marginTop: 4 }]}>D</Text>
            <Text style={textStyle}>Distrofia muscular de Duchenne / Becker (DMD)
              <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "ligadas_x")} `}</Text>
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={[textStyle, { marginTop: 4 }]}>F</Text>
            <Text style={textStyle}>Síndrome de X frágil (FMR1)
              <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "sindrome_x_fragil")} ${getValueField(estudio.camposEstudio, "observaciones_x_fragil")}`}</Text>
            </Text>
          </View>
        </View>
        {
          getValueField(estudio.camposEstudio, "recomendaciones") !== "" ?
            <View style={[dataContainerStyle, { marginTop: 8 }]}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <Text style={textStyle}>Recomendaciones:</Text>
                <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "recomendaciones")} `}</Text>
              </View>
            </View> : null
        }
      </View>

      <View style={[dataContainerStyle, { marginTop: 20 }]}>
        <FirmaEspecialista
          nombreEspecialista="natalia"
          styleFromParent={{ flexDirection: 'column', flex: 1 }}
          isSigned={estudio.isSigned}
        />
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ flexDirection: 'column', flex: 1 }}
          isSigned={estudio.isSigned}
          fechaReporte={estudio.fechaReporte}
        />
      </View>
      <Footer CS />
    </Page>
  )

}

export default ReportHO;