import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import WaterMark from "../informes/WaterMark";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";

const ReportASC = ({ estudio, paciente, qrValue }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    qrImageStyle,
  } = stylesReport;

  const getLabelResultado = () => {
    const resultado = getValueField(estudio.camposEstudio, "resultado");
    switch (resultado) {
      case "NO DETECTADO": {
        return "NEGATIVO";
      }
      case "DETECTADO": {
        return "POSITIVO";
      }
    }
  };

  const ResultadoCovid = () => {
    const date = moment(estudio.fechaSolicitud).utc();
    if (moment(date.format("YYYY-MM-DD")).isSameOrAfter("2021-12-27")) {
      return (
        <Text style={textStyle}>
          Detección cualitativa del antígeno (Ag) del SARS-CoV-2:
          <Text style={dataTextStyle}>{` ${getLabelResultado()} `}</Text>
        </Text>
      );
    }

    return (
      <Text style={textStyle}>
        Resultado:
        <Text style={dataTextStyle}>{` ${getValueField(
          estudio.camposEstudio,
          "resultado"
        )} `}</Text>
      </Text>
    );
  };

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <View style={[{ marginTop: 50 }]}>
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud)
            .utc()
            .format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
          spaceMedico={true}
        />
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Prueba:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.description} `}</Text>
            </Text>
            <Text style={textStyle}>
              Técnica:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>
              Fecha toma de muestra:
              <Text style={dataTextStyle}>
                {moment(estudio.fechaTomaDeMuestra).utc().format("HH:mm") ===
                "00:00"
                  ? ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY")}`
                  : ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY HH:mm")}`}
              </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <ResultadoCovid />
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 8 }]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Observaciones:</Text>
            {getValueField(estudio.camposEstudio, "observacion") !== "" ? (
              <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(
                estudio.camposEstudio,
                "observacion"
              )} `}</Text>
            ) : null}

            <Text
              style={[textStyle, dataTextStyle]}
            >{` Los resultados emitidos deben ser interpretados por el médico tratante y/o valoración médica. `}</Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <FirmaEspecialista
        nombreEspecialista="ruth"
        styleFromParent={{ marginTop: 30 }}
        isSigned={estudio.isSigned}
      />
      <View style={[mainContainerStyle]}>
        <View style={dataContainerStyle}>
          <Image source={{ uri: qrValue }} style={[qrImageStyle]} />
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default ReportASC;
