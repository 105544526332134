import React from 'react';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Col, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;

const Navbar = ({ state }) => {
  const { Header } = Layout;

  return (
    <Header>
      <Row style={{ alignContent: 'space between' }}>
        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 0, }} lg={{ span: 0 }}>
          <NavLink to="/">
            <div className="logo" />
          </NavLink>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 6, }} lg={{ span: 6 }}>
          <NavLink to="/" style={{ color: 'white' }}>
            Laboratorio Origen
            <div className="logo" />
          </NavLink>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 18, }} lg={{ span: 18 }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ float: 'right' }}
            selectedKeys={[`${state.nav}`]}
          >
            <Menu.Item key="1">
              <NavLink to="/">Inicio</NavLink>
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 0, }} lg={{ span: 0 }}>
          <Menu
            theme="dark"
            mode="horizontal"
            style={{ float: 'right' }}
            selectedKeys={[`${state.nav}`]}
          >
            <SubMenu icon={<MenuOutlined />}>
              <Menu.Item key="1">
                <NavLink to="/">Inicio</NavLink>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Col>
      </Row>
    </Header>
  );
}

export default Navbar;