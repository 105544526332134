import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportCHEB = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  const getSubfieldResultado = (field) => {
    const cantidad1 = `cantidad_1_${field}`;
    const cantidad2 = `cantidad_2_${field}`;

    if (getValueField(estudio.camposEstudio, cantidad1) !== "") {
      return `   Cantidad ${getValueField(estudio.camposEstudio, cantidad1)} ${getValueField(estudio.camposEstudio, cantidad1, "unidad")} `
    }

    if (getValueField(estudio.camposEstudio, cantidad2) !== "") {
      return `   Cantidad ${getValueField(estudio.camposEstudio, cantidad2)} ${getValueField(estudio.camposEstudio, cantidad2, "unidad")} `
    }

    return "";

  }

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Técnica:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.metodo} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado Citomegalovirus:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado_citomegalovirus")} `} {getValueField(estudio.camposEstudio, "resultado_citomegalovirus") === "Positivo" ? getSubfieldResultado("resultado_citomegalovirus") : ''}</Text>
              </Text>
              <Text style={textStyle}>Resultado Virus Herpes Humano 6:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado_virus_herpes_humano_6")} `} {getValueField(estudio.camposEstudio, "resultado_virus_herpes_humano_6") === "Positivo" ? getSubfieldResultado("resultado_virus_herpes_humano_6") : ''}</Text>
              </Text>
              <Text style={textStyle}>Resultado Virus Epstein-Barr :
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado_virus_epstein_barr")} `} {getValueField(estudio.camposEstudio, "resultado_virus_epstein_barr") === "Positivo" ? getSubfieldResultado("resultado_virus_epstein_barr") : ''}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Sensibilidad en Sangre o Biopsia:
                <Text style={dataTextStyle}>{` 5 copias de DNA/10^5 células `}</Text>
              </Text>
              <Text style={textStyle}>Sensibilidad en Líquido Cefalorraquídeo:
                <Text style={dataTextStyle}>{` 400 copias/ml `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "observaciones") !== "" ?
                  <Text style={textStyle}>Observaciones:
                    <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>
                  </Text>
                  : null
              }
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
          fechaReporte={estudio.fechaReporte}
        />
        <Footer />
      </Page>
  )

}

export default ReportCHEB;