import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import WaterMark from "../informes/WaterMark";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import CabeceraUnificada from "../informes/CabeceraUnificada";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import { ResultadosEstudioUnificado } from "./index";

const EstudiosXArea = ({ estudios, orden, paciente, qrValue, key }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <CabeceraUnificada
        paciente={paciente}
        fechaSolicitud={moment(orden.fechaSolicitud).utc().format("DD/MM/YYYY")}
        ordenNo={orden.ordenNumero}
        medico={orden.nombreMedico}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        {estudios.map((area, index) => {
          let metodo = "";
          let tipoDeMuestra = "";

          let metodo1 = "";
          let tipoDeMuestra1 = "";

          return (
            <View key={`area-${index}`}>
              {area.map((est, i) => {
                if (i === 0) {
                  metodo = est.estudioID.metodo;
                  tipoDeMuestra = est.tipoDeMuestraID.nombre;
                } else {
                  if (metodo1 !== "" || tipoDeMuestra1 !== "") {
                    metodo = metodo1;
                    tipoDeMuestra = tipoDeMuestra1;
                    metodo1 = "";
                    tipoDeMuestra1 = "";
                  }
                  if (
                    metodo !== est.estudioID.metodo ||
                    tipoDeMuestra !== est.tipoDeMuestraID.nombre
                  ) {
                    metodo1 = est.estudioID.metodo;
                    tipoDeMuestra1 = est.tipoDeMuestraID.nombre;
                  }
                }

                return (
                  <View key={`exa-${i}`}>
                    {i === 0 && (
                      <View>
                        <View style={dataContainerStyle}>
                          <Text style={blueTextStyle}>
                            {" "}
                            {est.areaDeEstudioID.nombre}{" "}
                          </Text>
                        </View>
                        <View style={[dataContainerStyle, { marginBottom: 5 }]}>
                          <View style={{ flexDirection: "column", flex: 2 }}>
                            <Text style={[textStyle, dataTextStyle]}>
                              Nombre de la Prueba
                            </Text>
                          </View>
                          <View style={{ flexDirection: "column", flex: 2 }}>
                            <Text
                              style={[
                                textStyle,
                                dataTextStyle,
                                { textAlign: "right" },
                              ]}
                            >
                              {" "}
                              Resultado
                            </Text>
                          </View>
                          <View style={{ flexDirection: "column", flex: 2 }}>
                            <Text
                              style={[
                                textStyle,
                                dataTextStyle,
                                { textAlign: "center" },
                              ]}
                            >
                              Unidad
                            </Text>
                          </View>
                          <View style={{ flexDirection: "column", flex: 2 }}>
                            <Text
                              style={[
                                textStyle,
                                dataTextStyle,
                                { textAlign: "right" },
                              ]}
                            >
                              Valor de Referencia
                            </Text>
                          </View>
                        </View>
                      </View>
                    )}

                    <ResultadosEstudioUnificado
                      estudio={est}
                      key={`re-${i}`}
                      noEstudios={area.length}
                      metodoAComprar={metodo1}
                      tipoDeMuestraAComparar={tipoDeMuestra1}
                      metodo={metodo}
                      tipoDeMuestra={tipoDeMuestra}
                      currentIndex={i}
                    />
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
      <FirmaEspecialista
        nombreEspecialista="ruth"
        styleFromParent={{ marginTop: 20 }}
        isSigned
      />
      <Footer />
    </Page>
  );
};

export default EstudiosXArea;
