export const GET_PRUEBAS = `
  query GetTests {
    getTests {
      id
      nombre
      codigoPrueba
    }
  }
`;

export const GET_USUARIOS = `
  query getUsers {
    getUsers {
      id
      email
      nombre
      apellido
      role
      approved
    }
  }
`;

export const GET_PRUEBA = `
query getTest($id: ID!) {
  getTest(id: $id) {
    nombre
    id
    codigoPrueba
  }
}
`;

export const FIND_CLIENT_BY_CI = `
  query GetClient($input: SearchClient) {
    getClient(input: $input) {
      id
      nombres
      apellidos
      ci
      fecha_nacimiento
      telefono
      municipio
      departamento
      direccion
      sexo 
      edad
    }
  }
`;

export const GET_PACIENTES = `
query getClients($limit: Int) {
  getClients(limit: $limit) {
    id
    _id
    nombres
    apellidos
    ci
    fecha_nacimiento
    sexo
    edad
    telefono
    municipio
    departamento
    direccion
  }
}
`;

export const GET_PACIENTE = `
query getClient($input: SearchClient) {
  getClient(input: $input) {
    id
    nombres
    apellidos
    ci
    fecha_nacimiento
    sexo
    edad
    telefono
    municipio
    departamento
    direccion
  }
}
`;

export const GET_FICHAS_BY_TEST = `
query getFichasByTest($id: ID) {
  getFichasByTest(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
    }
  }
}
`;

export const GET_FICHAS_BY_TEST_2 = `
query getFichasByTestOptimized($id: ID) {
  getFichasByTestOptimized(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
  }
}
`;

export const GET_FICHAS_BY_TEST_POR_CONCILIAR = `
query getFichasByTestPorConciliar($id: ID) {
  getFichasByTestPorConciliar(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    noComprobante
    montoPagado
    pagoStatus
    noFactura
    clientID {
      nombres
      apellidos
      ci
    }
  }
}
`;

export const GET_FICHAS_BY_TEST_PENDING = `
query getFichasByTestPendientes($id: ID) {
  getFichasByTestPendientes(testId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const GET_FICHA = `
query getFicha($id: ID!) {
  getFicha(id: $id) {
    id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    inicioSintomas
    fechaReporte
    observacion
    testID {
      nombre
      codigoPrueba
      id
    }
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
      municipio
      departamento
      direccion
      id
    }
  }
}
`;

export const GET_FICHAS_BY_EMPRESA = `
query getFichasPorEmpresa($busqueda: [String], $testId: ID) {
  getFichasPorEmpresa(busqueda: $busqueda, testId: $testId) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
  }
}
`;

export const GET_FICHAS_MY_EMPRESA = `
query getFichasMyEmpresa($testId: ID) {
  getFichasMyEmpresa(testId: $testId) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
  }
}
`;

export const GET_EMPRESAS_STATS = `
query getFichasPorEmpresaStats($busqueda: [String]!, $testId: ID!) {
  getFichasPorEmpresaStats(busqueda: $busqueda, testId: $testId)
}
`;

export const GET_FICHAS_BY_CLIENT = `
query getFichasByClient($id: ID) {
  getFichasByClient(clientId: $id) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    clientID {
      nombres
      apellidos
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      nombre
      codigoPrueba
    }
  }
}
`;

export const GET_EMPRESAS = `
query getEmpresas {
  getEmpresas {
    compania
    keywords
    email
    nombre
    apellido
    id
    approved
  }
}
`;

export const GET_EMPRESA = `
query getEmpresa($id: ID!) {
  getEmpresa(id: $id) {
    compania
    keywords
    email
    nombre
    apellido
    id,
    approved
  }
}
`;

export const GET_ESTUDIOS_PACIENTE_PUBLIC = `
query getOrdenesByClientPublic($ci: String, $fechaNacimiento: String) {
  getOrdenesByClientPublic(ci: $ci, fechaNacimiento: $fechaNacimiento) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    ordenNo
    isSigned
    fechaReporte
    precio
    attachmentFiles {
      id
      name
      mimeType
      url
    }
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
      id
    }
    estudioID {
      nombre
      description
      metodo
      id
      isDerivable
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
        tipo
        informesPublicos
      }
    }
  }
}
`;

export const GET_ORDEN_DETALLES_PUBLIC = `
query getEstudiosPorOrdenPublic($keyHash: String) {
  getEstudiosPorOrdenPublic(keyHash: $keyHash) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
    }
    estudioID {
      nombre
      description
      metodo
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        passport
        ci
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
        tipo
        informesPublicos
      }
    }
  }
}
`;

export const GET_FICHAS_PACIENTE_PUBLIC = `
query getFichasByClientPublic($ci: String, $fechaNacimiento: String) {
  getFichasByClientPublic(ci: $ci, fechaNacimiento: $fechaNacimiento) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    pagoStatus
    clientID {
      nombres
      apellidos
      passport
      ci
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const VERIFY_FICHA = `
query verifyFicha($keyHash: String) {
  verifyFicha(keyHash: $keyHash) {
    _id
    fechaSolicitud
    codigo
    tipoFicha
    resultado
    muestraControl
    tipoDeMuestra
    fechaReporte
    observacion
    isSigned
    inicioSintomas
    pagoStatus
    clientID {
      nombres
      apellidos
      ci
      passport
      sexo
      edad
      telefono
      fecha_nacimiento
    }
    testID {
      codigoPrueba
      nombre
    }
  }
}
`;

export const VERIFY_ESTUDIO_ORDEN = `
query getEstudioPublic($keyHash: String) {
  getEstudioPublic(keyHash: $keyHash) {
    id
    fechaSolicitud
    fechaTomaDeMuestra
    estadoEstudio
    estudioNumero
    codigoEstudio
    ordenNo
    isSigned
    fechaReporte
    precio
    tipoDeMuestraID {
      nombre
    }
    areaDeEstudioID {
      nombre
    }
    estudioID {
      nombre
      description
      metodo
    }
    camposEstudio {
      nombre
      label
      valor
      referencia
      unidad
    }
    ordenID {
      nombreMedico
      clientID {
        nombres
        apellidos
        ci
        passport
        fecha_nacimiento
        sexo
        telefono
      }
      proveedorID {
        nombre
      }
    }
  }
}
`;
