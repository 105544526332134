import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import WaterMark from "../informes/WaterMark";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";

const ReportPCRCOVID = ({ estudio, paciente, qrValue }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle,
  } = stylesReport;

  const getLabelResultado = () => {
    const resultado = getValueField(estudio.camposEstudio, "resultado");
    switch (resultado) {
      case "NO DETECTADO": {
        return "( NEGATIVO )";
      }
      case "DETECTADO": {
        return "( POSITIVO )";
      }
    }
  };

  const ResultadoCovid = () => {
    const date = moment(estudio.fechaSolicitud).utc();
    if (moment(date.format("YYYY-MM-DD")).isSameOrAfter("2021-12-27")) {
      return (
        <Text style={textStyle}>
          ARN de SARS-CoV2:
          <Text style={dataTextStyle}>{` ${getValueField(
            estudio.camposEstudio,
            "resultado"
          )} ${getLabelResultado()} `}</Text>
        </Text>
      );
    }

    return (
      <Text style={textStyle}>
        Resultado:
        <Text style={dataTextStyle}>{` ${getValueField(
          estudio.camposEstudio,
          "resultado"
        )}`}</Text>
      </Text>
    );
  };

  const getNombreDeLaPrueba = () => {
    const date = moment(estudio.fechaSolicitud).utc();
    if (moment(date.format("YYYY-MM-DD")).isSameOrAfter("2021-12-27")) {
      return "Determinacion de SARS-CoV2 por RT - PCR EN TIEMPO REAL: ";
    }
    return estudio.estudioID.description;
  };

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Prueba:
              <Text style={dataTextStyle}>{` ${getNombreDeLaPrueba()} `}</Text>
            </Text>
            <Text style={textStyle}>
              Técnica:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>
              Fecha toma de muestra:
              <Text style={dataTextStyle}>
                {moment(estudio.fechaTomaDeMuestra).utc().format("HH:mm") ===
                "00:00"
                  ? ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY")}`
                  : ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY HH:mm")}`}
              </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <ResultadoCovid />
            {getValueField(estudio.camposEstudio, "observacion") !== "" ? (
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  estudio.camposEstudio,
                  "observacion"
                )} `}</Text>
              </Text>
            ) : null}
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={[blueTextStyle, { textAlign: "center" }]}>
            Interpretaci&oacute;n del informe:
          </Text>
        </View>
        <View style={[dataContainerStyle, { marginTop: 10 }]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text style={[notesTextStyle, { textAlign: "center" }]}>
              Resultado
            </Text>
          </View>
          <View style={[{ flexDirection: "column", flex: 3 }, rowRightStyle]}>
            <Text style={[notesTextStyle, { textAlign: "center" }]}>
              Interpretación
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 4 }]}
            >
              DETECTADO
            </Text>
          </View>
          <View
            style={[
              { flexDirection: "column", flex: 3, padding: 4 },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              Positivo, material gen&eacute;tico viral detectado.
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View
            style={[
              { flexDirection: "column", flex: 1, borderBottomWidth: 1 },
              rowLeftStyle,
            ]}
          >
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 10 }]}
            >
              NO DETECTADO
            </Text>
          </View>
          <View
            style={[
              {
                flexDirection: "column",
                flex: 3,
                padding: 4,
                borderBottomWidth: 1,
              },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              Material gen&eacute;tico viral No detectado (L&iacute;mite de
              detecci&oacute;n: 1000 copias de virus/ml de muestra). Este
              resultado puede ser indicativo de que el paciente NO es portador
              del virus o la carga viral presente est&aacute; por debajo del
              l&iacute;mite de detecci&oacute;n.
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 10 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>Nota:</Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 10 }]}>
              {" "}
              La detecci&oacute;n del pat&oacute;genos por ampliaci&oacute;n de
              &aacute;cidos nucleicos (PCR) es una importante herramienta
              diagnostica la cual debe ser integrada y valorada en conjunto con
              otros procedimientos biom&eacute;dicos.
            </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
              {" "}
              El resultado refleja directamente la carga viral presente en el
              hisopado del paciente al momento de la toma de muestra, no son
              resultados que reflejan s&iacute;ntomas del paciente.
            </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
              {" "}
              Los resultados pueden ser alterados si el paciente estuvo o se
              encuentra bajo alg&uacute;n tratamiento m&eacute;dico.
            </Text>
          </View>
        </View>
      </View>
      <FirmaEspecialista
        nombreEspecialista="sebastian"
        styleFromParent={{ marginTop: 10 }}
        isSigned={estudio.isSigned}
        fechaReporte={estudio.fechaReporte}
      />
      <View style={[mainContainerStyle]}>
        <View style={dataContainerStyle}>
          <Image source={{ uri: qrValue }} style={[qrImageStyle]} />
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default ReportPCRCOVID;
