import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Card } from 'antd';
import { Redirect } from "react-router";
import { useManualQuery } from 'graphql-hooks';
import { VERIFY_FICHA } from '../queries';
import { getResultElisa, calculateResultO, getOResult } from '../utils/common';
import moment from 'moment';
const queryString = require('query-string');
const TIPO_DE_PRUEBA1 = "SARS-CoV-2 (COVID19) IgG";
const TIPO_DE_PRUEBA2 = "SARS-CoV-2 (COVID19) IgM";
const TIPO_DE_PRUEBA3 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const VerificarInforme = (props) => {
  const { Title } = Typography;
  const [verifyFicha] = useManualQuery(VERIFY_FICHA);
  const [error, setError] = useState(null);
  const [information, setInfo] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (!parsed.key) {
      return <Redirect to="/" />;
    }
    verificarInforme(parsed.key);
  }, [props.location.search]);

  const verificarInforme = (key) => {
    verifyFicha({
      variables: { keyHash: key }
    }).then(({ data, error }) => {
      if (error) {
        setError(error.graphQLErrors[0].message);
        return;
      }
      if (data.verifyFicha) {
        setInfo(data.verifyFicha);
      }
    })
  }

  const getResultLabel = (value) => {
    console.log(value)
    if (moment(information.fechaReporte, "DD-MM-YYYY").isSameOrAfter('2020-11-24') && moment(information.fechaReporte, "DD-MM-YYYY").isBefore('2020-12-04')) {
      if (Number(value) > 1.1) return "(Reactivo)";
      if (Number(value) < 0.9) return "(No Reactivo)"
      if (Number(value) >= 0.9 && Number(value) <= 1.1) {
        return "(Indeterminado)"
      }
    } else {
      if (Number(value) > 11) return "NTU (Reactivo)";
      if (Number(value) < 9) return "NTU (No Reactivo)"
      if (Number(value) >= 9 && Number(value) <= 11) {
        return "NTU (Indeterminado)"
      }
    }
  }

  const renderResultado = (tipoDePrueba, resultado, item) => {
    if (tipoDePrueba === 'C' || tipoDePrueba === 'CS' || tipoDePrueba === 'A') {
      return <p>Resultado: <strong>{resultado}</strong></p>
    }
    if (tipoDePrueba === 'E') {
      const resultadoElisa = resultado.split('|');
      // muestra, cutoff, date
      return (
        <>
          <p>PRUEBA: {TIPO_DE_PRUEBA1}</p>
          <p>Resultado: <strong> {`${getResultElisa(resultadoElisa[0], resultadoElisa[1], moment(item.fechaReporte, "DD-MM-YYYY"))} `} {getResultLabel(getResultElisa(resultadoElisa[0], resultadoElisa[1], moment(information.fechaReporte, "DD-MM-YYYY")))}</strong></p>
          <p>PRUEBA:{TIPO_DE_PRUEBA2}</p>
          <p>PRUEBA: <strong> {`${getResultElisa(resultadoElisa[2], resultadoElisa[3], moment(item.fechaReporte, "DD-MM-YYYY"))} `} {getResultLabel(getResultElisa(resultadoElisa[2], resultadoElisa[3], moment(information.fechaReporte, "DD-MM-YYYY")))}</strong></p>
        </>
      )
    }
    if (tipoDePrueba === 'O') {
      const resultadoO = resultado.split('|');
      const valueO = calculateResultO(resultadoO[0], resultadoO[1], moment(item.fechaReporte, "DD-MM-YYYY"))
      const resultLabel = getOResult(valueO);
      // muestra, cutoff, date
      return (
        <>
          <p>PRUEBA: {TIPO_DE_PRUEBA3}</p>
          <p>Resultado: <strong> {` ${resultLabel} ${valueO}%`}</strong></p>
        </>
      )
    }
  }

  return (
    <>
      <Row type="flex" style={{ backgroundColor: 'white' }}>
        {error ?
          <Col span={24} style={{ padding: '40px' }}>
            <Title level={2} style={{ textAlign: 'center', color: 'red', alignSelf: 'center' }}>{error}</Title>
          </Col> : null
        }
        {
          information ?
          <Col span={24}>
            <Card title={`Informe ${information.codigo} ${information.testID.nombre}`} style={{ width: '100%' }}>
              <p>Nombres: {information.clientID.nombres} </p>
              <p>Apellidos: {information.clientID.apellidos}</p>
              <p>CI: {information.clientID.ci}</p>
              {
                information.clientID.passport ?
                  <p>Nro. de Pasaporte: {information.clientID.passport}</p> : null
              }
              {information.clientID.fecha_nacimiento ? <p>Fecha Nacimiento: {information.clientID.fecha_nacimiento}</p> : null}
              <p>Fecha Toma de Muestra: {information.fechaSolicitud}</p>
              <p>Fecha del Informe: {information.fechaReporte}</p>
              {renderResultado(information.testID.codigoPrueba, information.resultado, information)}
            </Card>
          </Col> : null
        }
      </Row>
    </>
  );
}

export default VerificarInforme;