import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody, TableCell } from '@david.kucsai/react-pdf-table'
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';
const FONT_SIZE = 8;

const ReportPP = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  const headerPadre = ` ${getValueField(estudio.camposEstudio, 'id_muestra_padre')} ${paciente.nombres} ${paciente.apellidos} (${paciente.sexo === "Femenino" ? "Presunta Madre" : "Presunto Padre"}) `;
  const headerHijo = ` ${getValueField(estudio.camposEstudio, 'id_muestra_hijo')} ${getValueField(estudio.camposEstudio, 'nombre_hijo')} (${getValueField(estudio.camposEstudio, 'nombre_hijo') === "Femenino" ? "Presunta Hija" : "Presunto Hijo"}) `;

  return (
      <Page size="LETTER" wrap={true} style={[pageStyle, { paddingBottom: 95 }]}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Antecedentes:
                <Text style={dataTextStyle}>{` A solicitud del Laboratorio ORIGEN, tengo a bien elevar el siguiente Informe de Investigación de Paternidad Biológica.`}</Text>
              </Text>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` Determinar a través de la prueba de ADN (ácido desoxirribonucleico) la paternidad biológica de la muestra rotulada como perteneciente al ${paciente.sexo === "Femenino" ? "Se&ntilde;ora" : "Señor"}: ${paciente.nombres} ${paciente.apellidos} frente a la muestra rotulada como perteneciente a: ${getValueField(estudio.camposEstudio, 'nombre_hijo')} `}</Text>
              </Text>
              <Text style={textStyle}>Descripción de las muestras:</Text>
              <Text style={[textStyle, { marginLeft: 10 }]}>{getValueField(estudio.camposEstudio, 'id_muestra_padre')}.-
                <Text style={dataTextStyle}> {` (Muestra de Referencia) Sangre con papel FTA, rotulada como perteneciente al ${paciente.sexo === "Femenino" ? "Señora" : "Señor"}: ${paciente.nombres} ${paciente.apellidos} (${paciente.sexo === "Femenino" ? "Presunta Madre" : "Presunto Padre"})`}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 10 }]}>{getValueField(estudio.camposEstudio, 'id_muestra_hijo')}.-
                <Text style={dataTextStyle}> {` (Muestra de Referencia) Sangre con papel FTA, rotulada como perteneciente a: ${getValueField(estudio.camposEstudio, 'nombre_hijo')} (${getValueField(estudio.camposEstudio, 'nombre_hijo') === "Femenino" ? "Presunta Hija" : "Presunto Hijo"})`}</Text>
              </Text>

            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Fundamentación Técnica:</Text>
              <Text style={[textStyle, dataTextStyle]}> La investigación biológica de la Paternidad se basa en la utilización de marcadores de identificación genética llamados STR (Short Tandem Repeat o Repeticiones cortas en Tandem), cuyo análisis permite obtener un perfil genético único para cada individuo, y a su vez, establecer las relaciones de parentesco, ya que estos marcadores tipo STR son de herencia mendeliana, es decir, que el ADN de cada individuo procede de sus padres biológicos, de modo que, la mitad del ADN es de origen materno y la otra mitad de origen paterno. </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Descripción de las operaciones realizadas:</Text>
              <Text style={[textStyle, { marginLeft: 10 }]}>Extracción del ADN:
                <Text style={dataTextStyle}> {` Se realizó la extracción de ADN a partir de las muestras mencionadas anteriormente. `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 10 }]}>Amplificación del ADN:
                <Text style={dataTextStyle}> {` Se empleó la técnica de la Reacción en Cadena de la Polimerasa (PCR), para el estudio de 16 marcadores autosómicos de tipo STR utilizando el Kit Identifiler Plus. `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 10 }]}>Visualización de los resultados:
                <Text style={dataTextStyle}> {` Una vez amplificado el ADN, se realiza la electroforesis capilar, en un analizador genético. `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Resultados:</Text>
              <Text style={[textStyle, dataTextStyle]}> En la siguiente tabla se encuentran los resultados de los marcadores de ADN estudiados en las muestras correspondientes. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8, marginHorizontal: 40 }]}>
          <Text style={[textStyle, dataTextStyle, { textAlign: 'center' }]}> ALELOS ESTABLECIDOS: </Text>
          <View style={[dataContainerStyle, { marginTop: 10, marginHorizontal: 55 }]}>
            <Table data={[
              { campo0: 'Locus', campo1: headerPadre, campo2: headerHijo },
            ]}>
              <TableBody textAlign={"center"} fontSize={FONT_SIZE} includeTopBorder={true}>
                <DataTableCell style={{ paddingVertical: 3, borderTop: 1, borderTopColor: '#000' }} getContent={(r) => r.campo0} />
                <DataTableCell style={{ paddingVertical: 3, borderTop: 1, borderTopColor: '#000' }} getContent={(r) => r.campo1} />
                <DataTableCell style={{ paddingVertical: 3, borderTop: 1, borderTopColor: '#000' }} getContent={(r) => r.campo2} />
              </TableBody>
            </Table>
          </View>
          <View style={[dataContainerStyle, { marginHorizontal: 55 }]}>
            <Table data={[
              { campo0: 'D8S1179', campo1: "locus_1_p", campo2: "locus_1_h" },
              { campo0: 'D21S11', campo1: "locus_2_p", campo2: "locus_2_h" },
              { campo0: 'D7S820', campo1: "locus_3_p", campo2: "locus_3_h" },
              { campo0: 'CSF1PO', campo1: "locus_4_p", campo2: "locus_4_h" },
              { campo0: 'D3S1358', campo1: "locus_5_p", campo2: "locus_5_h" },
              { campo0: 'TH01', campo1: "locus_6_p", campo2: "locus_6_h" },
              { campo0: 'D13S317', campo1: "locus_7_p", campo2: "locus_7_h" },
              { campo0: 'D16S539', campo1: "locus_8_p", campo2: "locus_8_h" },
              { campo0: 'D2S1338', campo1: "locus_9_p", campo2: "locus_9_h" },
              { campo0: 'D19S433', campo1: "locus_10_p", campo2: "locus_10_h" },
              { campo0: 'vWA', campo1: "locus_11_p", campo2: "locus_11_h" },
              { campo0: 'TPOX', campo1: "locus_12_p", campo2: "locus_12_h" },
              { campo0: 'D18S51', campo1: "locus_13_p", campo2: "locus_13_h" },
              { campo0: 'Amelogenina*', campo1: "locus_14_p", campo2: "locus_14_h" },
              { campo0: 'D5S818', campo1: "locus_15_p", campo2: "locus_15_h" },
              { campo0: 'FGA', campo1: "locus_16_p", campo2: "locus_16_h" }
            ]}>
              <TableBody textAlign={"center"} fontSize={FONT_SIZE}>
                <DataTableCell getContent={(r) => r.campo0} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo1)} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo2)} />
              </TableBody>
            </Table>
          </View>
          <Text style={[longTextStyle, { marginHorizontal: 65, marginTop: 5 }]}> * Amelogenina: X – X: sexo femenino</Text>
          <Text style={[longTextStyle, { marginHorizontal: 65 }]}> {`                         X – Y: sexo masculino`}</Text>
        </View>

        <View style={[mainContainerStyle, { marginTop: 12 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Conclusión:
                <Text style={dataTextStyle}>{` La muestra rotulada como perteneciente ${paciente.sexo === "Femenino" ? "a la Señora" : "al Señor"}: ${paciente.nombres} ${paciente.apellidos}, ${getValueField(estudio.camposEstudio, "resultado_final")} como ${paciente.sexo === "Femenino" ? "madre biológica" : "padre biológico"} frente a la muestra rotulada como perteneciente a: ${getValueField(estudio.camposEstudio, 'nombre_hijo')}. La probabilidad de ${paciente.sexo === "Femenino" ? "maternidad" : "paternidad"} de la muestra rotulada como perteneciente al ${paciente.sexo === "Femenino" ? "a la Señora" : "al Señor"}: ${paciente.nombres} ${paciente.apellidos} frente a la muestra rotulada como perteneciente a: ${getValueField(estudio.camposEstudio, 'nombre_hijo')}, es de 99,9999 %. `}</Text>
              </Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="natalia"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />
        <Footer CS />
      </Page>
  )
}

export default ReportPP;