import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import imgFirma from '../assets/ruth.png';
import Header from './informes/Header';
import Footer from './informes/Footer';
import moment from 'moment';
import WaterMark from './informes/WaterMark';
import { getEdad, calculateResultO, getOResult } from '../utils/common';
const TIPO_DE_PRUEBA1 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const TECNICA = "ELISA";

const ReportO = ({ item, qrValue }) => {
  const { pageStyle, mainContainerStyle, containerTituloStyle, dataContainerStyle,
    blueTextStyle, titleStyle, textStyle, dividerStyle, ResultTextStyle, rowLeftStyle, rowRightStyle,
    notesTextStyle, showSign, notShowSign, interpretacionHeadlineStyle, qrImageStyle
  } = styles;

  let defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 2);
  if (defaultDateReport > moment()) {
    defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 1);
  }

  const edadPaciente = getEdad(item.clientID.fecha_nacimiento, item.clientID.edad);
  const resultadoAN = item.resultado.split('|');
  const valueAN = calculateResultO(resultadoAN[0], resultadoAN[1], moment(item.fechaReporte, "DD-MM-YYYY"))
  const resultLabel = getOResult(valueAN);

  const renderInterpretacion = (tipo) => {
    switch (tipo) {
      case 'NEGATIVO': {
        return 'No se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%';
      }
      case 'INDETERMINADO': {
        return 'Se detectaron anticuerpos neutralizantes con una tasa de inhibición dentro del rango de 30% a 35%';
      }
      case 'POSITIVO': {
        return 'Se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%';
      }
    }
  }

  return (
    <Document>
      <Page size="A4" wrap={true} style={[pageStyle]}>
        <Header />
        <WaterMark typePage="A4" />
        <View style={mainContainerStyle}>
          <View style={containerTituloStyle}>
            <Text style={[blueTextStyle, titleStyle]}>Reporte del paciente</Text>
          </View>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Informacion del paciente:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Nombres: {item.clientID.nombres ? `${item.clientID.nombres}` : ""}</Text>
              <Text style={textStyle}>Apellidos: {item.clientID.apellidos ? `${item.clientID.apellidos}` : ""}</Text>
              <Text style={textStyle}>Carnet de Identidad: {item.clientID.ci ? `${item.clientID.ci}` : ""}</Text>
              {
                item.clientID.passport ?
                  <Text style={textStyle}>Nro. de Pasaporte: {item.clientID.passport ? `${item.clientID.passport}` : ""}</Text> : null
              }
              <Text style={textStyle}>C&oacute;digo de Paciente: {`${item.codigo}`}</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha de Nacimiento: {item.clientID.fecha_nacimiento ? `${moment(item.clientID.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")}` : ""}</Text>
              <Text style={textStyle}>Edad: {edadPaciente} </Text>
              <Text style={textStyle}>Sexo: {item.clientID.sexo ? `${item.clientID.sexo}` : ""}</Text>
              <Text style={textStyle}>Tel&eacute;fono: {item.clientID.telefono ? `${item.clientID.telefono}` : ""}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
            <Text style={textStyle}>Prueba: {TIPO_DE_PRUEBA1}</Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 0 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha Toma de Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha de Recepci&oacute;n de la Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")} </Text>
              <Text style={textStyle}>Fecha del Informe: {item.fechaReporte ? moment(item.fechaReporte, "DD-MM-YYYY").format("DD/MM/YYYY") : defaultDateReport.format("DD/MM/YYYY")} </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra: {item.tipoDeMuestra}</Text>
              <Text style={textStyle}>T&eacute;cnica: {TECNICA}</Text>
            </View>
          </View>
          
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 0, marginBottom: 0 }]}>
            <Text style={textStyle}>Resultado:</Text>
            <Text style={ResultTextStyle}>{`${resultLabel}`}</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 0, marginBottom: 0 }]}>
            <Text style={textStyle}>Anticuerpos Neutralizantes:</Text>
            <Text style={ResultTextStyle}>{`${valueAN}% `}</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 0, marginBottom: 0 }]}>
            <Text style={textStyle}>Interpretación: </Text>
            <Text style={textStyle}>{` ${renderInterpretacion(resultLabel)} `}</Text>
          </View>

          <View style={dividerStyle}></View>

          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={[textStyle, {marginBottom: 5}]}>Valores de Referencia:</Text>
              <Text style={textStyle}>{`NEGATIVO: < 30%`}</Text>
              <Text style={textStyle}>{`INDETERMINADO: DE 30 A 35%`}</Text>
              <Text style={textStyle}>{`POSITIVO: > 35%`}</Text>
            </View>
          </View>

          
          <View style={[dataContainerStyle, {marginTop:  10}]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los anticuerpos neutralizantes son capaces de bloquear eficazmente la infección, evitando que el virus se una y entre en las células y, en consecuencia, su replicación dentro del organismo. Por lo tanto, también se denominan anticuerpos inhibidores de virus y son los anticuerpos más asociados con la protección y la inmunidad.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los pacientes asintomáticos o que presentan síntomas leves pueden tardar más en dar positivo en la prueba o producir anticuerpos perceptibles con las técnicas utilizadas.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los niveles altos de anticuerpos neutralizantes pueden requerir más de 21 días para establecerse y puede ser necesario un seguimiento serológico.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Las pruebas de detección de IgG e IgM actuales (incluso aquellas que utilizan las proteínas S1, la subunidad RBD y la proteína S2 como diana) no investigan la función neutralizante. Estas pruebas solo indican si existe o no producción de anticuerpos contra ciertas proteínas (incluso aquellas pruebas que liberan resultados en concentración). Por tanto, la presencia de estos anticuerpos no garantiza necesariamente la neutralización del virus, ya que su existencia no significa FUNCIÓN neutralizante.`}</Text>
            </View>
          </View>
          <View style={{ marginTop: 30 }}>
            <Image
              src={imgFirma}
              style={item.isSigned ? showSign : notShowSign}
            />
          </View>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  mainContainerStyle: {
    marginHorizontal: 40 //60
  },
  containerTituloStyle: {
    marginTop: 0, //20
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10 // 40
  },
  dataContainerStyle: {
    flexDirection: 'row',
  },
  medicoTextStyle: {
    color: '#333',
    fontSize: 12,
    paddingVertical: 2,
    marginLeft: 10
  },
  blueTextStyle: {
    textTransform: 'uppercase',
    color: '#61C0D4',
    fontSize: 12,
    paddingVertical: 1
  },
  titleStyle: {
    fontSize: 18
  },
  textStyle: {
    color: '#333',
    fontSize: 11,
    paddingVertical: 1
  },
  notesTextStyle: {
    color: '#333',
    fontSize: 9,
    paddingVertical: 1
  },
  ResultTextStyle: {
    color: '#000',
    fontSize: 12,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginLeft: 8,
    marginTop: 0
  },
  dividerStyle: {
    height: 2,
    backgroundColor: '#2DB9B4',
    marginVertical: 5 //20
  },
  rowLeftStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5
  },
  rowRightStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5
  },
  showSign: {
    objectFit: 'contain',
    height: 100,
    opacity: 1
  },
  qrImageStyle: {
    objectFit: 'contain',
    height: 80,
    width: 80
  },
  notShowSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 0
  },
  interpretacionHeadlineStyle: {
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
})

export default ReportO;