import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import CabeceraUnificada from '../informes/CabeceraUnificada';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';




const ReportUnificado = ({ estudios, paciente, qrValue, infoCabecera }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle
  } = stylesReport;

  return (
    <Document>
      {
        estudios.map((est) => {
          return (est)
        })
      }
    </Document>
  )
}

export default ReportUnificado;