import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import WaterMark from "../informes/WaterMark";

const ReportVPHG = ({ estudio, paciente }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico={true}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Prueba:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.nombre} `}</Text>
            </Text>
            <Text style={[textStyle, { marginLeft: 31 }]}>
              <Text
                style={dataTextStyle}
              >{`${estudio.estudioID.description} `}</Text>
            </Text>
            <Text style={textStyle}>
              Técnica:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              ADN HPV Genotipo 16:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "resultado_16"
              )} `}</Text>
            </Text>
            <Text style={textStyle}>
              ADN HPV Genotipo 18:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "resultado_18"
              )} `}</Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={[blueTextStyle, { textAlign: "center" }]}>
            Interpretaci&oacute;n
          </Text>
        </View>

        <View style={[dataContainerStyle, { marginTop: 10 }]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[
                notesTextStyle,
                { textAlign: "center", fontWeight: "800", fontSize: 10 },
              ]}
            >
              Resultado
            </Text>
          </View>
          <View style={[{ flexDirection: "column", flex: 3 }, rowRightStyle]}>
            <Text
              style={[
                notesTextStyle,
                { textAlign: "center", fontWeight: "800", fontSize: 10 },
              ]}
            >
              Interpretaci&oacute;n
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 10 }]}
            >
              NO DETECTADO
            </Text>
          </View>
          <View style={[{ flexDirection: "column", flex: 3 }, rowRightStyle]}>
            <Text style={notesTextStyle}>
              No se detect&oacute; material gen&eacute;tico viral (L&iacute;mite
              de detecci&oacute;n: 100 copias de virus/ul de muestra). Este
              resultado puede ser indicativo de que el paciente NO es portador
              del virus o la carga viral presente est&aacute; por debajo del
              l&iacute;mite de detecci&oacute;n.
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View
            style={[
              { flexDirection: "column", flex: 1, borderBottomWidth: 1 },
              rowLeftStyle,
            ]}
          >
            <Text style={[notesTextStyle, { textAlign: "center" }]}>
              DETECTADO
            </Text>
          </View>
          <View
            style={[
              { flexDirection: "column", flex: 3, borderBottomWidth: 1 },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              Se detect&oacute; material gen&eacute;tico viral.
            </Text>
          </View>
        </View>
      </View>
      <View style={[mainContainerStyle, { marginTop: 10 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Sensibilidad:
              <Text style={textStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "sensibilidad"
              )} ${getValueField(
                estudio.camposEstudio,
                "sensibilidad",
                "unidad"
              )} `}</Text>
            </Text>
            {getValueField(estudio.camposEstudio, "observaciones") !== "" ? (
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  estudio.camposEstudio,
                  "observaciones"
                )} `}</Text>
              </Text>
            ) : null}
          </View>
        </View>
      </View>
      <FirmaEspecialista
        nombreEspecialista="sebastian"
        styleFromParent={{ marginTop: 20 }}
        isSigned={estudio.isSigned}
        fechaReporte={estudio.fechaReporte}
      />
      <Footer />
    </Page>
  );
};

export default ReportVPHG;
