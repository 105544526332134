import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportAM = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={[pageStyle, {paddingBottom: 100}]}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> {estudio.estudioID.metodo}  </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:</Text>
              <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion_abierta')}</Text>
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Notas:</Text>
              <Text style={longTextStyle}> -El resultado de este estudio deberá ser siempre interpretado en el contexto de datos clínicos y familiares. </Text>
              <Text style={longTextStyle}> -Puede ser beneficioso para esta persona y sus familiares un asesoramiento genético-clínico para discutir las implicancias directas e indirectas de éste resultado.</Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Metodología:</Text>
              <Text style={longTextStyle}> Se amplificó mediante un pool de primers AmpliSeq TM diseñado con el software Ampliseq TM para el gen TTR y se analizó por secuenciación de amplicones mediante Post LightTM Ion Semicondutor Sequencing (Next Generation Sequencing, NGS) en la plataforma Ion Personal Genome Machine ® System. La amplificación mediante la utilización de este pool de primers permite amplificar un 100% de la secuencia codificante de este gen y regiones intrónicas flanqueantes Se analizaron las variantes con respecto al genoma humano de referencia hg19 utilizando el Variant Caller como plug in para su detección. Se analizan las variantes exónicas y las regiones intrónicas cercanas al exón (distancia menor o igual a 13 pares de bases). Se utiliza el Software Ion ReporterTM para anotar las variantes encontradas y para el filtrado e interpretación de la significancia clínica de las mismas. Búsqueda de las variantes detectadas en bases de datos internacionales, entre ellas ClinVar, Lovd.nl y bibliografía disponible. Las variantes se clasifican de acuerdo con las guías del Colegio Americano de Genética Médica y Genómica (ACMG). Las categorías de clasificación son: patogénicas, probablemente patogénicas, variantes de significado incierto, variantes probablemente benignas y benignas. Las variantes benignas no son reportadas, siguiendo las recomendaciones de las guías europeas (Dequeker, 2009). </Text>
              <Text style={longTextStyle}> Variantes de significado incierto y variantes probablemente benignas se reportan en observaciones. </Text>
              <Text style={longTextStyle}> Transcripto de referencia: TTR (NM_000371.3) </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Limitaciones técnicas:</Text>
              <Text style={longTextStyle}> - Esta metodología permite detectar INS/DEL de hasta 15 pb aproximadamente. Tampoco se pueden detectar grandes rearreglos, para ello es recomendable realizar la técnica de MLPA. </Text>
              <Text style={longTextStyle}> - Diferentes situaciones pueden resultar en errores en la detección de variantes genéticas, entre ellas: muestras contaminadas previo al arribo a nuestro laboratorio, presencia de mosaicismos, presencia de variantes genéticas que producen "allelic drop-outs", estudios realizados sobre ADN extraído a partir de tejidos incluidos en parafina, presencia de pseudogenes (regiones con homología), identificación incorrecta de variantes en zonas de homopolímeros o con alto contenido de GC. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Trazabilidad de la muestra:</Text>
              <Text style={longTextStyle}> Hemos desarrollado un método eficaz para la identificación y seguimiento de la muestra dentro de nuestro laboratorio que nos permite conocer la identidad de la muestra de forma inequívoca desde el ingreso hasta el final del proceso. Para ellos se incluyen 6 STR (microsatélites) con un alto poder de discriminación al panel de amplicones del estudio genético a realizar. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 0 }]} break>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Comentarios:</Text>
              <Text style={longTextStyle}> - Es altamente recomendado que la interpretación de este informe se realice con el asesoramiento de un médico familiarizado con el fenotipo del paciente y las causas genéticas que lo producen. Los resultados de este estudio son complementarios y deben ser interpretados en el contexto clínico de cada paciente. </Text>
              <Text style={longTextStyle}> - La ausencia de variantes patogénicas en los genes estudiados no descarta la predisposición genética a la enfermedad. Tampoco excluye la presencia de variantes patogénicas en otros genes potencialmente asociadas con esta patología. </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Bibliografía y/o bases de datos consultadas:</Text>
              <Text style={longTextStyle}> http://www.omim.org/ </Text>
              <Text style={longTextStyle}> http://www.ncbi.nlm.nih.gov/clinvar/ </Text>
              <Text style={longTextStyle}> https://databases.lovd.nl/shared/genes/TTR </Text>
            </View>
          </View>
        </View>

        <FirmaEspecialista
          nombreEspecialista="amiloidosis"
          styleFromParent={{ marginTop: 20 }}
          isSigned={estudio.isSigned}
        />

        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )

}



export default ReportAM;