import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
const TIPO_DE_PRUEBA1 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const ReportAN = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle
  } = stylesReport;

  const RenderValorReferencia = ({ tipo }) => {
    switch (tipo) {
      case 'negativo': {
        return 'Menor a 1.0';
      }
      case 'bajo-positivo': {
        return '1.0 a 4.9';
      }
      case 'positivo-medio': {
        return '5.0 a 9.9';
      }
      case 'positivo-alto': {
        return 'Mayor a 10';
      }
    }
  }

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <View style={[{ marginTop: 0 }]}>
          <InformacionDelPaciente
            paciente={paciente}
            fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
            fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
            codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
            ordenNo={estudio.ordenNo}
            medico={estudio.ordenID.nombreMedico}
            spaceMedico
          />
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> ELISA </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Prueba: {TIPO_DE_PRUEBA1}</Text>
          </View>
          <Text style={textStyle}>Fecha toma de muestra:
            <Text style={dataTextStyle}>
              {
                moment(estudio.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                    ` ${moment(estudio.fechaTomaDeMuestra).utc().format('DD/MM/YYYY')}`
                      :
                  ` ${moment(estudio.fechaTomaDeMuestra).utc().format('DD/MM/YYYY HH:mm')}`
              }
            </Text>
          </Text>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>

          {
            getValueField(estudio.camposEstudio, "observacion") !== "" ?
            <View style={[dataContainerStyle, { marginTop: 8 }]}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Observaciones:
                  <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observacion")} `}</Text>
                </Text>
              </View>
            </View> : null
          }

          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 16 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}>Interpretaci&oacute;n del informe:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>NEGATIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}> <RenderValorReferencia tipo="negativo" /> </Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente no ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>BAJO POSITIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}><RenderValorReferencia tipo="bajo-positivo" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, sin embargo es indicativo de un desarrollo bajo de anticuerpos.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>POSITIVO MEDIO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}><RenderValorReferencia tipo="positivo-medio" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, sin embargo es indicativo de un desarrollo moderado de anticuerpos.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>POSITIVO ALTO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}><RenderValorReferencia tipo="positivo-alto" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, borderBottomWidth: 1, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Indica que el paciente ha desarrollado anticuerpos neutralizantes contra el antigeno Spike / RBD, es indicativo de un alto desarrollo de anticuerpos.</Text>
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 25 }}
          isSigned={estudio.isSigned}
        />
        <View style={[mainContainerStyle]}>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
  )

}

export default ReportAN;