import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Typography,
  DatePicker,
  Table,
  Tag,
} from "antd";
import { IdcardOutlined } from "@ant-design/icons";
import { useManualQuery } from "graphql-hooks";
import {
  GET_FICHAS_PACIENTE_PUBLIC,
  GET_ESTUDIOS_PACIENTE_PUBLIC,
} from "../queries";
import { NOT_AVAILABLES_ESTUDIO_FOR_DOWNLOAD } from '../config/constants';
import moment from "moment";
import DownloadButton from "../components/informes/DownloadButton";
import OldDownloadButton from "../components/DownloadButton";
import { Context as AuthContext } from "../context/AuthContext";
import DescargarTodos from "../components/informes/DescargarTodos";
import QRCode from "qrcode.react";
import jwt from "jsonwebtoken";
import AttachmentFile from '../molecules/AttachmentFile';

const SECRET_HASH = process.env.REACT_APP_SECRET_HASH;

const ERROR_REPORTE_EMPRESA = `Por favor comunicarse con su empresa, o centro de salud\npara saber mas detalles sobre el resultado de sus pruebas.`;
const ERROR_REPORTE_PAGO = `Por favor confirmar su pago para acceder al resultado.`;
const DATELINE = moment("10/08/2020", "DD/MM/YYYY");
const INFORMES_NO_DISPONIBLES = [
  "EN PROCESO",
  "NULO",
  "CANCELADO",
  "PENDIENTE",
];

const EncuentraMiResultado = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [error, setError] = useState(null);
  const { setNavigation } = useContext(AuthContext);
  const [resultSearch, setResultSearch] = useState([]);
  const [ordenesNF, setOrdenenesNF] = useState([]);
  const [showCleanBtn, setShowCleanBtn] = useState(false);
  const [getFichasByClientPublic] = useManualQuery(GET_FICHAS_PACIENTE_PUBLIC);
  const [getOrdenesByClientPublic] = useManualQuery(
    GET_ESTUDIOS_PACIENTE_PUBLIC
  );

  const { Title, Text, Paragraph } = Typography;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNavigation(1);
    forceUpdate({});
  }, []);

  const fetchOldFichas = (carnet, fecha_nacimiento, array) => {
    getFichasByClientPublic({
      variables: {
        ci: carnet,
        fechaNacimiento: fecha_nacimiento.format("DD/MM/YYYY"),
      },
    }).then(({ data, error }) => {
      if (error) {
        // setError("Hubo un error consultando la informacion.");
        // setLoading(false);
        console.log(error);
        return;
      }
      if (data.getFichasByClientPublic) {
        if (data.getFichasByClientPublic.length === 0 && array.length === 0) {
          setError(
            "Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada."
          );
          console.log(
            "Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada."
          );
        } else {
          let estudiosOrdenadosPorOrden = [];
          let ordenesNumeros = [];
          for (var i = 0; i < array.length; i++) {
            const orden = array[i];
            if (!ordenesNumeros.includes(orden.ordenNo)) {
              ordenesNumeros.push(orden.ordenNo);

              const ordenesFiltradas = array.filter((item) => {
                if (item.ordenNo === orden.ordenNo) return item;
              });

              estudiosOrdenadosPorOrden.push(ordenesFiltradas);
            } else {
              continue;
            }
          }

          setOrdenenesNF(estudiosOrdenadosPorOrden);
          setShowCleanBtn(true);
        }
        // setLoading(false);
      }
    });
  };

  const fetchNewOrders = (carnet, fecha_nacimiento) => {
    getOrdenesByClientPublic({
      variables: {
        ci: carnet,
        fechaNacimiento: fecha_nacimiento.format("DD/MM/YYYY"),
      },
    }).then(({ data, error }) => {
      if (error) {
        setError("Hubo un error consultando la información.");
        setLoading(false);
        return;
      }

      if (data.getOrdenesByClientPublic) {
        fetchOldFichas(carnet, fecha_nacimiento, data.getOrdenesByClientPublic);
        // if (data.getOrdenesByClientPublic.length === 0) {
        //   setError("Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada.")
        // } else {
        //   setResultSearch(data.getOrdenesByClientPublic);
        //   setShowCleanBtn(true);
        // }
        setLoading(false);
      }
    });
  };

  const RenderEstatus = ({ item }) => {
    let color = "orange";
    const reportIDHash = jwt.sign({ estudioXOrdenId: item.id }, SECRET_HASH);
    const verifiedReportURL = `https://vigilant-goldberg-714272.netlify.app/verificar-estudio?key=${reportIDHash}`;

    if (item.estadoEstudio !== "PROCESADO") {
      return <Tag color={color}>{item.estadoEstudio}</Tag>;
    }

    color = "green";

    return (
      <>
        <Tag color={color}>DISPONIBLE</Tag>
        <div style={{ display: "none" }}>
          <QRCode
            id={`canvas-${item.id}`}
            value={verifiedReportURL}
            size={128}
          />
        </div>
      </>
    );
  };

  const EstudiosRealizados = ({ data }) => {
    // console.log(data);

    if (
      data[0].ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" &&
      !data[0].ordenID.proveedorID.informesPublicos
    ) {
      return (
        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: "mas" }}>
          {ERROR_REPORTE_EMPRESA}
        </Paragraph>
      );
    }

    return (
      <>
        {data.map((item) => {
          return (
            <Col
              id={`est-${item.id}`}
              style={{ display: "block", paddingTop: 5, paddingBottom: 5 }}
            >
              <Text style={{ marginRight: 5 }}>{item.estudioID?.nombre}</Text>
              <RenderEstatus item={item} />
            </Col>
          );
        })}
      </>
    );
  };

  const columnsNuevoFormato = [
    {
      title: "Orden No",
      dataIndex: "ordenNo",
      key: "ordenNo",
      render: (ordenNo, item) => {
        // console.log(item);
        return <Text>{item[0].ordenNo}</Text>;
      },
    },
    {
      title: "Fecha",
      dataIndex: "fechaOrden",
      key: "fechaOrden",
      render: (fechaOrden, item) => {
        return (
          <Text>{`${moment(item[0].fechaSolicitud)
            .utc()
            .format("DD/MM/YYYY")}`}</Text>
        );
      },
    },
    {
      title: "Estudios Realizados",
      dataIndex: "estudios",
      key: "estudiosR",
      render: (estudiosR, item) => {
        return <EstudiosRealizados data={item} />;
      },
    },
    {
      title: "Descarga",
      dataIndex: "descarga",
      key: "descarga",
      fixed: "right",
      render: (estudiosR, item) => {
        if (item.length == 1) {
          if (
            item[0].estadoEstudio !== "PROCESADO" ||
            (item[0].ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" &&
              !item[0].ordenID.proveedorID.informesPublicos)
          ) {
            return null;
          }

          if (
            NOT_AVAILABLES_ESTUDIO_FOR_DOWNLOAD.includes(item[0].estudioID?.id)
          ) {
            return null;
          }

          return (
            <>
              <DownloadButton
                size="small"
                disabled={false}
                estudio={item[0]}
                cliente={item[0].ordenID.clientID}
              />
              {item[0].attachmentFiles.map((item) => (
                <AttachmentFile
                  key={item.id}
                  iconStyle={{ fontSize: '24px' }}
                  style={{ verticalAlign: 'middle', marginLeft: '10px' }}
                  mimeType={item.mimeType}
                  url={item.url}
                />
              ))}
            </>
          );
        }

        const attachmentFiles = [];
        item.forEach((order) => {
          order.attachmentFiles.forEach((file) => {
            attachmentFiles.push(file);
          });
        });

        return (
          <>
            <DescargarTodos
              allEstudies={item}
              miOrden={item[0].ordenID}
              ordenNo={item[0].ordenNo}
            />
            {attachmentFiles.map((item) => (
              <AttachmentFile
                key={item.id}
                iconStyle={{ fontSize: '24px' }}
                style={{ verticalAlign: 'middle', marginLeft: '10px' }}
                mimeType={item.mimeType}
                url={item.url}
              />
            ))}
          </>
        );
      },
    },
  ];

  const cleanData = () => {
    form.resetFields();
    setResultSearch([]);
    setShowCleanBtn(false);
    setError("");
    setOrdenenesNF([]);
  };

  const handleSearch = (values) => {
    setLoading(true);
    setError("");
    const { carnet, fecha_nacimiento } = values;
    fetchNewOrders(carnet, fecha_nacimiento);
  };

  return (
    <>
      <Row type="flex" style={{ backgroundColor: "white" }}>
        <Col
          xs={{ span: 0 }}
          sm={{ span: 0 }}
          md={{ span: 8, offset: 4 }}
          lg={{ span: 8, offset: 4 }}
        >
          <img
            src={require("../assets/imagen.jpg")}
            style={{ width: "100%" }}
            alt="Logo Laboratorio Origen"
          />
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 8 }}
          lg={{ span: 8 }}
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            alignSelf: "center",
          }}
        >
          <Title
            level={3}
            style={{
              marginTop: "30px",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Bienvenido!
          </Title>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            onFinish={handleSearch}
          >
            <Form.Item
              label="Carnet de Identidad"
              name="carnet"
              rules={[
                {
                  required: true,
                  message:
                    "Por favor escriba un numero de carnet de identidad válido, solo numeros!",
                },
              ]}
            >
              <Input
                prefix={<IdcardOutlined className="site-form-item-icon" />}
                type="text"
                placeholder="00000000"
                style={{ borderRadius: "8px" }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Fecha de Nacimiento"
              name="fecha_nacimiento"
              rules={[
                { required: true, message: "Por favor seleccione una fecha!" },
              ]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="31-12-2019"
                size="large"
                style={{ width: "100%", borderRadius: "8px" }}
                disabledDate={(d) => !d || d.isAfter(moment())}
              />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {loading ? "Buscando" : "Buscar"}
                </Button>
              )}
            </Form.Item>
            {showCleanBtn ? (
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  danger
                  type="link"
                  onClick={() => {
                    cleanData();
                  }}
                >
                  Limpiar
                </Button>
              </Form.Item>
            ) : null}
          </Form>
        </Col>
      </Row>
      <Row>
        {error ? (
          <Col span={24} style={{ padding: "20px" }}>
            <Typography
              level={4}
              style={{ textAlign: "center", color: "red", alignSelf: "center" }}
            >
              {error}
            </Typography>
          </Col>
        ) : null}
        {ordenesNF && ordenesNF.length > 0 ? (
          <Col span={24}>
            <Table
              rowKey={(record) => record.ordenNo}
              columns={columnsNuevoFormato}
              dataSource={ordenesNF}
              style={{ minWidth: "100%" }}
            />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default EncuentraMiResultado;
