import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Input, Button, Typography, DatePicker, Table, Tag } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { useManualQuery } from 'graphql-hooks';
import { GET_FICHAS_PACIENTE_PUBLIC, GET_ESTUDIOS_PACIENTE_PUBLIC } from '../queries';
import moment from 'moment';
import DownloadButton from '../components/informes/DownloadButton';
import OldDownloadButton from '../components/DownloadButton';
import { Context as AuthContext } from '../context/AuthContext';

const ERROR_REPORTE_EMPRESA = `Por favor comunicarse con su empresa, o centro de salud\npara saber mas detalles sobre el resultado de su prueba.`;
const ERROR_REPORTE_PAGO = `Por favor confirmar su pago para acceder al resultado.`;
const DATELINE = moment("10/08/2020", "DD/MM/YYYY");
const INFORMES_NO_DISPONIBLES = ['EN PROCESO', 'NULO', 'CANCELADO', "PENDIENTE"];

const EncuentraMiResultado = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [error, setError] = useState(null);
  const { setNavigation } = useContext(AuthContext);
  const [resultSearch, setResultSearch] = useState([]);
  const [showCleanBtn, setShowCleanBtn] = useState(false);
  const [getFichasByClientPublic] = useManualQuery(GET_FICHAS_PACIENTE_PUBLIC);
  const [getOrdenesByClientPublic] = useManualQuery(GET_ESTUDIOS_PACIENTE_PUBLIC);

  const { Title, Text, Paragraph } = Typography;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNavigation(1);
    forceUpdate({});
  }, []);


  const fetchOldFichas = (carnet, fecha_nacimiento, array) => {
    getFichasByClientPublic({
      variables: { ci: carnet, fechaNacimiento: fecha_nacimiento.format("DD/MM/YYYY") }
    }).then(({ data, error }) => {
      if (error) {
        // setError("Hubo un error consultando la informacion.");
        // setLoading(false);
        console.log(error)
        return;
      }
      if (data.getFichasByClientPublic) {
        if (data.getFichasByClientPublic.length === 0 && array.length === 0) {
          setError("Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada.")
          console.log("Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada.")
        } else {
          setResultSearch([...array, ...data.getFichasByClientPublic]);
          setShowCleanBtn(true);
        }
        // setLoading(false);
      }
    })
  }

  const fetchNewOrders = (carnet, fecha_nacimiento) => {

    getOrdenesByClientPublic({
      variables: { ci: carnet, fechaNacimiento: fecha_nacimiento.format("DD/MM/YYYY") }
    }).then(({ data, error }) => {
      if (error) {
        setError("Hubo un error consultando la información.");
        setLoading(false);
        return;
      }

      if (data.getOrdenesByClientPublic) {
        fetchOldFichas(carnet, fecha_nacimiento, data.getOrdenesByClientPublic);
        // if (data.getOrdenesByClientPublic.length === 0) {
        //   setError("Lo sentimos, no hemos encontrado ningun paciente con la información proporcionada.")
        // } else {
        //   setResultSearch(data.getOrdenesByClientPublic);
        //   setShowCleanBtn(true);
        // }
        setLoading(false);
      }
    })

  }

  const columns = [
    {
      title: 'Nombre Estudio',
      dataIndex: 'estudioID.nombre',
      key: 'estudioID',
      fixed: true,
      render: (estudioID, item) => {
        if (item.testID) {
          return (
            <Text>{item.testID.nombre}</Text>
          )
        }
        return (
          <Text>{item.estudioID.nombre}</Text>
        )
      }
    },
    {
      title: 'Fecha Toma de Muestra',
      dataIndex: 'fechaTomaDeMuestra',
      key: 'fechaTomaDeMuestra',
      render: (fechaTomaDeMuestra, item) => {
        if (item.testID) {
          return (
            <Text>{item.fechaSolicitud}</Text>
          )
        }
        return (
          <Text>
            {
              moment(item.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                  ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY')}`
                    :
                ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY HH:mm')}`
            }
          </Text>
        )
      }
    },
    {
      title: 'Codigo Informe',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (codigo, item) => {
        if (item.testID) {
          return (
            <Text>{item.codigo}</Text>
          )
        }
        return (
          <Text>{item.codigoEstudio}{item.estudioNumero}</Text>
        )
      }
    },
    {
      title: 'Estatus',
      key: 'estadoEstudio',
      dataIndex: 'estadoEstudio',
      render: (estadoEstudio, item) => {
        let color = 'orange';
        if (item.testID) {
          if (item.tipoFicha.trim().toUpperCase() !== "PARTICULAR" && item.tipoFicha.trim().toUpperCase() !== "DOMICILIO") {
            return (
              <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mas' }}>
                {ERROR_REPORTE_EMPRESA}
              </Paragraph>
            )
          }

          if (moment(item.fechaSolicitud, "DD/MM/YYYY").isSameOrAfter(DATELINE)) {
            if (!item.pagoStatus || item.pagoStatus === "") {
              return (
                <Tag color={color}>
                  EN PROCESO
                </Tag>
              )
            }

            if (item.pagoStatus === "POR COBRAR") {
              return (
                <Tag color='orange'>
                  EN PROCESO
                </Tag>
              )
            }
          }

          if (!item.resultado) {
            return (
              <Tag color={color}>
                NO DISPONIBLE
              </Tag>
            )
          }

          if (INFORMES_NO_DISPONIBLES.includes(item.resultado.toUpperCase())) {
            return (
              <Tag color={color}>
                NO DISPONIBLE
              </Tag>
            )
          }

          return (
            <Tag color='green'>
              DISPONIBLE
            </Tag>
          )
        }

        if (item.ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" && !item.ordenID.proveedorID.informesPublicos) {
          return (
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mas' }}>
              {ERROR_REPORTE_EMPRESA}
            </Paragraph>
          )
        }

        if (item.estadoEstudio !== 'PROCESADO') {
          return (
            <Tag color={color}>
              {item.estadoEstudio}
            </Tag>
          )
        }

        color = 'green';

        return (
          <Tag color={color}>
            DISPONIBLE
          </Tag>
        )
      },
    },
    {
      title: '',
      key: 'action',
      fixed: 'right',
      render: (text, item) => {
        if (item.testID) {
          if (item.tipoFicha.trim().toUpperCase() !== "PARTICULAR" && item.tipoFicha.trim().toUpperCase() !== "DOMICILIO") {
            return null;
          }

          if (moment(item.fechaSolicitud, "DD/MM/YYYY").isSameOrAfter(DATELINE)) {
            if (!item.pagoStatus) {
              return null;
            }

            if (item.pagoStatus === "POR COBRAR") {
              return null;
            }
          }

          if (!item.resultado) {
            return null;
          }

          if (INFORMES_NO_DISPONIBLES.includes(item.resultado.toUpperCase())) {
            return null;
          }

          return (
            <OldDownloadButton rowData={item} test={item.testID} />
          )
        }

        if (item.estadoEstudio !== 'PROCESADO' || item.ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" && !item.ordenID.proveedorID.informesPublicos) {
          return null;
        }

        return (
          <DownloadButton size="small" disabled={false} estudio={item} cliente={item.ordenID.clientID} />
        )
      },
    },
  ];

  const cleanData = () => {
    form.resetFields();
    setResultSearch([]);
    setShowCleanBtn(false);
    setError("");
  }

  const handleSearch = (values) => {
    setLoading(true);
    setError("");
    const { carnet, fecha_nacimiento } = values;
    fetchNewOrders(carnet, fecha_nacimiento);
  }

  return (
    <>
      <Row type="flex" style={{ backgroundColor: 'white' }}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 4 }} lg={{ span: 8, offset: 4 }} >
          <img src={require('../assets/imagen.jpg')} style={{ width: '100%' }} alt="Logo Laboratorio Origen" />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }} style={{ paddingLeft: '10px', paddingRight: '10px', alignSelf: 'center' }}>
          <Title level={3} style={{ marginTop: '30px', marginBottom: '20px', textAlign: 'center' }}>Bienvenido!</Title>
          <Form form={form} name="horizontal_login" layout="vertical" onFinish={handleSearch}>
            <Form.Item
              label="Carnet de Identidad"
              name="carnet"
              rules={[{ required: true, message: 'Por favor escriba un numero de carnet de identidad válido, solo numeros!' }]}
            >
              <Input
                prefix={<IdcardOutlined className="site-form-item-icon" />}
                type="text"
                placeholder="00000000"
                style={{ borderRadius: '8px' }}
                size="large"
              />
            </Form.Item>
            <Form.Item
              label="Fecha de Nacimiento"
              name="fecha_nacimiento"
              rules={[{ required: true, message: 'Por favor seleccione una fecha!' }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                placeholder="31-12-2019"
                size="large"
                style={{ width: "100%", borderRadius: '8px' }}
                disabledDate={d => !d || d.isAfter(moment())}
              />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
              {() => (
                <Button
                  style={{ width: '100%' }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {loading ? 'Buscando' : 'Buscar'}
                </Button>
              )}
            </Form.Item>
            {
              showCleanBtn ?
                <Form.Item>
                  <Button
                    style={{ width: '100%' }}
                    danger
                    type="link"
                    onClick={() => {
                      cleanData();
                    }}
                  >
                    Limpiar
                  </Button>
                </Form.Item> : null
            }
          </Form>
        </Col>
      </Row>
      <Row>
        {
          error ?
            <Col span={24} style={{ padding: '20px' }}>
              <Typography level={4} style={{ textAlign: 'center', color: 'red', alignSelf: 'center' }}>{error}</Typography>
            </Col> : null
        }
        {
          resultSearch && resultSearch.length > 0 ?
            <Col span={24}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={resultSearch}
                style={{ minWidth: '100%' }}
                sticky
              />
            </Col> :
            null
        }
      </Row>
    </>

  )
}

export default EncuentraMiResultado;