import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportFP = ({ estudio, paciente }) => {

  const {
    pageStyle,
    pageBigInformStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          SG={getValueField(estudio.camposEstudio, "semana_gestacion")}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Indicación Clínica:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "indicacion_clinica")} `} </Text>
              </Text>
              <Text style={textStyle}>Tipo de Estudio:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "tipo_de_estudio")} `} </Text>
              </Text>
              <Text style={textStyle}>Metodología:
                <Text style={dataTextStyle}> {` ${estudio.estudioID.metodo} `} </Text>
              </Text>
              <View style={dividerStyle}></View>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Fish:</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Total Células Analizadas:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "total_celulas_analizadas")} `}</Text>
              </Text>
              <Text style={textStyle}>Punto de Corte Cromosoma 13:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_cromosoma_13")} `}</Text>
              </Text>
              <Text style={textStyle}>Punto de Corte Cromosoma 18:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_cromosoma_18")} `}</Text>
              </Text>
              <Text style={textStyle}>Punto de Corte Cromosoma 21:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_cromosoma_21")} `}</Text>
              </Text>
              <Text style={textStyle}>Punto de Corte para Aneuplodias:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_aneuploidias")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Punto de Corte Cromosoma X:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_cromosoma_x")} `}</Text>
              </Text>
              <Text style={textStyle}>Punto de Corte Cromosoma Y:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "punto_corte_cromosoma_y")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado")} `}</Text>
              </Text>
              <Text style={textStyle}>Interpretación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "interpretacion")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Recomendaciones:</Text>
              <Text style={[textStyle, dataTextStyle]}> {` ${getValueField(estudio.camposEstudio, "recomendacion_abierta")} `}</Text>
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco3") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco3", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco4") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco4", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco5") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco5", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco6") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco6", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco7") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco7", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco8") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco8", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco9") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco9", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco10") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco10", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco11") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco11", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Limitaciones:</Text>
              <Text style={[textStyle, dataTextStyle]}> La prueba de FISH en células de interface solo está diseñada para detectar anomalías numéricas comunes para los cromosomas 13, 18, 21, X e Y y no detectará aproximadamente el 30% de las anomalías cromosómicas prenatales.</Text>
              <Text style={[textStyle, dataTextStyle]}> Estas incluyen mosaicismo, anomalías estructurales y otras anomalías cromosómicas numéricas no contempladas en el estudio. Por lo tanto, se recomienda el análisis citogenético de rutina o cariotipo para la interpretación final del resultado reflejado en este estudio.</Text>
              <Text style={[textStyle, dataTextStyle]}>Podría existir una variación genética en el ADN de las aneuploidias estudiadas que conlleve a errores en su detección.</Text>
              <Text style={[textStyle, dataTextStyle]}>Las decisiones médicas no deben tomarse únicamente en base al resultado de esta prueba prenatal. El Colegio Americano de Genética Médica recomienda que el estudio FISH se realice en conjunto con el análisis de cariotipo o citogenética convencional para un resultado definitivo.</Text>
            </View>
          </View>
        </View>

        <View style={[dataContainerStyle, { marginTop: 15 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
        </View>
        <Footer />
      </Page>
  )
}

export default ReportFP;