import React from "react";
import { View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from 'moment';
import stylesReport from "./styles";
import sebastian from "../../assets/sebastian.png";
import victoria from "../../assets/victoria.png";
import yamila from "../../assets/firmaYamila.png";
import natalia from "../../assets/natalia.png";
import ruth from "../../assets/ruth.png";
import amiloidosis from "../../assets/amiloidosis.png";
import acgh from "../../assets/acgh.png";
import exoma from "../../assets/exoma.png";
import pdm from "../../assets/pdm.png";

const FirmaEspecialista = ({
  isSigned = true,
  nombreEspecialista,
  styleFromParent,
  bigInform = false,
  fechaReporte,
}) => {
  const { showSign, showSignSmall, notShowSign } = stylesReport;

  const getAssetPicture = (nombre) => {
    if (nombre === "sebastian") {
      if (fechaReporte != null && moment(fechaReporte).isSameOrAfter('2023-07-26T00:00:00.000Z')) {
        return victoria;
      }
      return sebastian;
    } else if (nombre === "yamila") {
      return yamila;
    } else if (nombre === "natalia") {
      return natalia;
    } else if (nombre === "ruth") {
      return ruth;
    } else if (nombre === "amiloidosis") {
      return amiloidosis;
    } else if (nombre === "acgh") {
      return acgh;
    } else if (nombre === "exoma") {
      return exoma;
    } else if (nombre === "pdm") {
      return pdm;
    }
  };

  return (
    <View style={styleFromParent}>
      <Image
        src={getAssetPicture(nombreEspecialista)}
        style={isSigned ? (bigInform ? showSignSmall : showSign) : notShowSign}
      />
    </View>
  );
};

export default FirmaEspecialista;
