export const areasResultadosSimple = [
  "611278e3823cb4b940865386",
  "611278fb823cb4b940865387",
  "6112790c823cb4b940865388",
  "6131802b9873090004263279",
  "61318052987309000426327a",
  "61318089987309000426327b",
  "61b0f4600ec50300045729ac",
];
// IDS de estudio que pertecen a las areas de resultado simple, pero que requieren un informe individual
export const excepcionesEstudiosResultadoSimple = [
  "614be1657245160004f3b6f6",
  "6154e93ccd0f3600046433ac"
];
export const excepcionesEstudiosNoSencillos = [
  "6195941a1351a600049b8eea",
  "619594c81351a600049b8eec",
  "61a7fd50834304000486a2d9",
  "61a7fe28834304000486a2e0",
  "61a7ff55834304000486a2e4",
  "61a8002a834304000486a2e9",
  "61a80710834304000486a30b",
  "61a80abb834304000486a312",
  "61a80b36834304000486a313",
  "61a80bac834304000486a314",
  "61a80bfb834304000486a316",
  "61a80c7f834304000486a317",
  "61a80cf2834304000486a318",
  "61a90d41de40b600047346a6",
  "61a90dc2de40b600047346ad",
  "61a9132fde40b600047346de",
  "61b0fa6aa216590004772203",
  "61b0faf0a21659000477220f",
  "61b0fb59a216590004772212",
  "61bbf2178c5bae00041b2b97",
  "61bbf2d88c5bae00041b2b98",
  "61bbf3938c5bae00041b2b99",
  "61bbf4188c5bae00041b2b9a",
  "61bbf4908c5bae00041b2b9b",
  "61bbf5048c5bae00041b2b9c",
  "61bbf5728c5bae00041b2b9d",
  "61e9869f299eff00045d145e",
  "620af9df172a5100047c012b",
  "620b048d172a5100047c0142",
  "620b06d9172a5100047c0143",
  "620b0943172a5100047c0144",
  "620b0a42172a5100047c0145",
  "620b0ec3172a5100047c0147",
  "620b0fdf172a5100047c0148",
  "620b118d172a5100047c0149",
  "620b12d8172a5100047c014a",
  "620bd803d788d40004f7ede4",
  "620bd8a1d788d40004f7edea",
  "620bd92cd788d40004f7edeb",
  "620bd98dd788d40004f7edec",
  "620bd9fcd788d40004f7edf1",
  "620bda64d788d40004f7edf9",
  "620bdb06d788d40004f7edfb",
  "620bdbb0d788d40004f7edfd",
  "620bdc51d788d40004f7edff",
  "620bdcafd788d40004f7ee00",
  "620bdd0bd788d40004f7ee01",
  "620d7de9a3e9b50004f158b7",


];
// Codigos de estudios que tienen soporte para ingles
export const CODES_ESTUDIES = ["PCRC", "PCRCS", "ASC", "ANTU", "AA"];

export const ESTUDIO_PRUEBA_PATERNIDAD = '60bd4e9a1c87d60004dbb1ff';
export const NOT_AVAILABLES_ESTUDIO_FOR_DOWNLOAD = [ESTUDIO_PRUEBA_PATERNIDAD];
