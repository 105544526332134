import React, { useContext } from 'react';
import Navbar from '../components/Navbar';
import Routes from '../routes/routes';
import { Layout } from 'antd';
import '../styles/App.css';
import 'antd/dist/antd.css';
import { Context as AuthContext } from '../context/AuthContext';

const App = () => {
  const { Content } = Layout;
  const { state } = useContext(AuthContext);

  return (
    <Layout>
      <Navbar {...{ state }} />
      <Content>
        <Routes />
      </Content>
    </Layout>
  )
};

export default App;
