import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import stylesReport from "./styles";

const Footer = ({ RS = true, CS = false }) => {
  const { pageNumberStyle, mainContainerStyle } = stylesReport;
  return (
    <View fixed style={styles.footerStyle}>
      <View style={{ flexDirection: "row" }}>
        <View style={{ flexDirection: "column", flex: 1 }}>
          {CS ? (
            <View style={{ marginHorizontal: 40 }}>
              <Text style={styles.compraServiciosTextStyle}>
                Nota: Compra de servicios
              </Text>
            </View>
          ) : null}
        </View>
        <View style={{ flexDirection: "column", flex: 1 }}>
          {RS ? (
            <View style={{ marginHorizontal: 40 }}>
              <Text style={styles.registroTextStyle}>
                Registro SEDES: 09-RC-457-LG
              </Text>
            </View>
          ) : null}
        </View>
      </View>

      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={{ width: 80, height: 4, backgroundColor: "#fff" }}></View>
        <View style={{ flex: 1, height: 4, backgroundColor: "#8ac6d1" }}></View>
        <View style={{ flex: 1, height: 4, backgroundColor: "#b2deec" }}></View>
        <View></View>
      </View>
      <View style={styles.addressContainer}>
        <Text style={styles.textFooter}>
          Calle Robore #31, Edif. BioTecno 3er piso, entre Av. Pirai - Roca y
          Coronado • (591) 77043905 • laboratorio.origen@gmail.com
        </Text>
      </View>
      <Text
        style={[pageNumberStyle, mainContainerStyle]}
        render={({ pageNumber, totalPages }) =>
          `Página ${pageNumber} de ${totalPages}`
        }
        fixed
      />
    </View>
  );
};

// Azul clarito #b2deec
// Azul oscurito #8ac6d1

const styles = StyleSheet.create({
  footerStyle: {
    position: "absolute",
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: "center",
    marginTop: 100,
  },
  addressContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  textFooter: {
    color: "#bbbfca",
    fontSize: 8,
    letterSpacing: 0.3,
  },
  registroTextStyle: {
    color: "#000",
    fontSize: 9,
    textAlign: "right",
    marginVertical: 5,
  },
  compraServiciosTextStyle: {
    color: "#000",
    fontSize: 9,
    textAlign: "left",
    marginVertical: 5,
  },
});

export default Footer;
