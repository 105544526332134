import React from "react";
import { Text, View } from "@react-pdf/renderer";
import ReportElisa from "./ReportElisa";
import ReportPCRCOVID from "./C";
import ReportPPNI from "./PPNI";
import ReportPPNIE from "./PPNIE";
import ReportFishPrenatal from "./FP";
import ReportFishPOC from "../reports/FPOC";
import ReportCP from "./CP";
import ReportCPPT from "./CPPT";
import ReportCPPT2 from "./CPPT2";
import ReportDP from "./DP";
import ReportVPH from "./VPH";
import ReportCHEB from "./CHEB";
import ReportMT from "./MT";
import ReportHIV from "./HIV";
import ReportARVI from "./ARVI";
import TPB from "./TPB";
import TPA from "./TPA";
import R from "./R";
import TXG from "./TXG";
import CPRE from "./CPRE";
import HSG from "./HSG";
import PJ from "./PJ";
import FE from "./FE";
import AN from "./AN";
import ASC from "./ASC";
import AM from "./AM";
import ACGH from "./ACGH";
import EX from "./EX";
import FQ from "./FQ";
import XF from "./XF";
import PR from "./PR";
import PP from "./PP";
import BRNM from "./BRNM";
import CH from "./CH";
import HA from "./HA";
import EstudiosXArea from "./EstudiosArea";
import ANT from "./ANT";
import CEN from "./C-EN";
import ReportASC_EN from "./ASC-EN";
import COR from "./COR";
import Simple from "./Simple";
import HO from "./HO";
import HC from "./HC";
import DZC from "./DZC";
import CAB from "./CAB";
import GHLA from "./GHLA";
import ReportFH from "./FH";
import ReportVPHG from "./VPHG";
import ReportPDM from "./PDM";
import { getValueField } from "../../utils/common";

import stylesReport from "../informes/styles";

const {
  pageStyle,
  dataContainerStyle,
  blueTextStyle,
  textStyle,
  mainContainerStyle,
  dataTextStyle,
  dividerStyle,
  longTextStyle,
  notaDeServicio,
} = stylesReport;

const RenderCampoEstudio = ({ campo }) => {
  if (campo.tipo === "DIVISOR") {
    if (campo.nombre.includes("show")) {
      return (
        <View
          style={[
            dataContainerStyle,
            {
              marginBottom: 5,
              paddingBottom: 5,
              borderBottom: 1,
              borderBottomColor: "#848484",
            },
          ]}
        >
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { textAlign: "center", textTransform: "uppercase" },
              ]}
            >
              {" "}
              {` ${campo.label} `}{" "}
            </Text>
          </View>
        </View>
      );
    }

    return null;
  }

  if (campo.label === "Observaciones") {
    if (campo.valor !== "") {
      return (
        <View style={[dataContainerStyle, { marginBottom: 5 }]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>
              Observaciones:
              <Text style={dataTextStyle}>{` ${campo.valor} `}</Text>
            </Text>
          </View>
        </View>
      );
    }
    return null;
  }

  return (
    <View style={[dataContainerStyle, { marginBottom: 5 }]}>
      <View style={{ flexDirection: "column", flex: 2 }}>
        <Text style={[textStyle, dataTextStyle]}>{`    ${campo.label} `}</Text>
      </View>
      <View style={{ flexDirection: "column", flex: 2 }}>
        <Text
          style={[textStyle, dataTextStyle, { textAlign: "right" }]}
        >{` ${campo.valor} `}</Text>
      </View>
      <View style={{ flexDirection: "column", flex: 2 }}>
        <Text
          style={[textStyle, dataTextStyle, { textAlign: "center" }]}
        >{` ${campo.unidad} `}</Text>
      </View>
      <View style={{ flexDirection: "column", flex: 2 }}>
        <Text
          style={[textStyle, dataTextStyle, { textAlign: "right" }]}
        >{` ${campo.referencia} `}</Text>
      </View>
    </View>
  );
};

const ResultadosHemograma = ({ estudio }) => {
  const { camposEstudio } = estudio;

  return (
    <View>
      <View style={[dataContainerStyle, { marginBottom: 5 }]}>
        <View style={{ flexDirection: "column", flex: 2 }}>
          <Text
            style={[textStyle, dataTextStyle, { textTransform: "uppercase" }]}
          >
            {estudio.estudioID.nombre}
          </Text>
        </View>
      </View>
      {estudio.camposEstudio.map((field, index) => {
        return <RenderCampoEstudio campo={field} key={`campo-${index}`} />;
      })}
    </View>
  );
};

const ResultadosEstudio = ({ estudio }) => {
  const { camposEstudio } = estudio;
  if (camposEstudio.length === 3) {
    return (
      <View>
        <View style={[dataContainerStyle, { marginBottom: 5 }]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textTransform: "uppercase" }]}
            >
              {estudio.estudioID.nombre}
            </Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "right" }]}
            >{` ${getValueField(camposEstudio, "resultado")} `}</Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "center" }]}
            >{` ${getValueField(camposEstudio, "resultado", "unidad")} `}</Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "right" }]}
            >{` ${getValueField(
              camposEstudio,
              "resultado",
              "referencia"
            )} `}</Text>
          </View>
        </View>
        {getValueField(camposEstudio, "observaciones") !== "" ? (
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  camposEstudio,
                  "observaciones"
                )} `}</Text>
              </Text>
            </View>
          </View>
        ) : null}
      </View>
    );
  }
  // Estudios con mas de un solo resultado por estudio
  return (
    <View>
      <View style={[dataContainerStyle, { marginBottom: 5 }]}>
        <View style={{ flexDirection: "column", flex: 2 }}>
          <Text
            style={[textStyle, dataTextStyle, { textTransform: "uppercase" }]}
          >
            {estudio.estudioID.nombre}
          </Text>
        </View>
      </View>

      {estudio.camposEstudio.map((field, index) => {
        return <RenderCampoEstudio campo={field} key={`campo-${index}`} />;
      })}
    </View>
  );
};

const ResultadosEstudioUnificado = ({
  estudio,
  noEstudios,
  metodoAComprar,
  tipoDeMuestraAComparar,
  metodo,
  tipoDeMuestra,
  currentIndex,
}) => {
  const { camposEstudio } = estudio;
  let dividorAnticipado = false;

  if (noEstudios > 1 && currentIndex > 0) {
    if (metodoAComprar !== "" || tipoDeMuestraAComparar !== "") {
      dividorAnticipado = true;
    }
  }

  if (camposEstudio.length === 3) {
    return (
      <View>
        {dividorAnticipado ? (
          <DivisorDeAreas
            noEstudios={noEstudios}
            metodoAComprar={metodoAComprar}
            tipoDeMuestraAComparar={tipoDeMuestraAComparar}
            metodo={metodo}
            tipoDeMuestra={tipoDeMuestra}
            currentIndex={currentIndex}
          />
        ) : null}

        <View style={[dataContainerStyle, { marginBottom: 5 }]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textTransform: "uppercase" }]}
            >
              {estudio.estudioID.nombre}
            </Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "right" }]}
            >{` ${getValueField(camposEstudio, "resultado")} `}</Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "center" }]}
            >{` ${getValueField(camposEstudio, "resultado", "unidad")} `}</Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text
              style={[textStyle, dataTextStyle, { textAlign: "right" }]}
            >{` ${getValueField(
              camposEstudio,
              "resultado",
              "referencia"
            )} `}</Text>
          </View>
        </View>
        {getValueField(camposEstudio, "observaciones") !== "" ? (
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  camposEstudio,
                  "observaciones"
                )} `}</Text>
              </Text>
            </View>
          </View>
        ) : null}
        {estudio.estudioID.isDerivable ? (
          <View style={[dataContainerStyle, { marginBottom: 3 }]}>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <Text style={[textStyle, notaDeServicio]}>
                Compra de servicios
              </Text>
            </View>
          </View>
        ) : null}
        {!dividorAnticipado ? (
          <DivisorDeAreas
            noEstudios={noEstudios}
            metodoAComprar={metodoAComprar}
            tipoDeMuestraAComparar={tipoDeMuestraAComparar}
            metodo={metodo}
            tipoDeMuestra={tipoDeMuestra}
            currentIndex={currentIndex}
          />
        ) : null}
      </View>
    );
  }
  // Estudios con mas de un solo resultado por estudio
  return (
    <View>
      {dividorAnticipado ? (
        <DivisorDeAreas
          noEstudios={noEstudios}
          metodoAComprar={metodoAComprar}
          tipoDeMuestraAComparar={tipoDeMuestraAComparar}
          metodo={metodo}
          tipoDeMuestra={tipoDeMuestra}
          currentIndex={currentIndex}
        />
      ) : null}
      <View style={[dataContainerStyle, { marginBottom: 5 }]}>
        <View style={{ flexDirection: "column", flex: 2 }}>
          <Text
            style={[textStyle, dataTextStyle, { textTransform: "uppercase" }]}
          >
            {estudio.estudioID.nombre}
          </Text>
        </View>
      </View>

      {estudio.camposEstudio.map((field, index) => {
        return <RenderCampoEstudio campo={field} key={`campo-${index}`} />;
      })}

      {estudio.estudioID.isDerivable ? (
        <View style={[dataContainerStyle, { marginBottom: 3 }]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={[textStyle, notaDeServicio]}>Compra de servicios</Text>
          </View>
        </View>
      ) : null}

      {!dividorAnticipado ? (
        <DivisorDeAreas
          noEstudios={noEstudios}
          metodoAComprar={metodoAComprar}
          tipoDeMuestraAComparar={tipoDeMuestraAComparar}
          metodo={metodo}
          tipoDeMuestra={tipoDeMuestra}
          currentIndex={currentIndex}
        />
      ) : null}
    </View>
  );
};

const Metodo = ({ metodo, tipoDeMuestra }) => {
  return (
    <View>
      <View style={[dataContainerStyle]}>
        <View style={{ flexDirection: "column", flex: 2 }}>
          {metodo !== "-" && metodo !== "--" ? (
            <Text style={textStyle}>
              Método:
              <Text style={dataTextStyle}>{` ${metodo} `}</Text>
            </Text>
          ) : null}
        </View>
      </View>
      <View style={[dataContainerStyle]}>
        <View style={{ flexDirection: "column", flex: 2 }}>
          {tipoDeMuestra !== "Sin Muestra" ? (
            <Text style={textStyle}>
              Tipo de muestra:
              <Text style={dataTextStyle}>{` ${tipoDeMuestra} `}</Text>
            </Text>
          ) : null}
        </View>
      </View>
      <View style={[dividerStyle, { marginBottom: 8 }]}></View>
    </View>
  );
};

const DivisorDeAreas = ({
  noEstudios,
  metodoAComprar,
  tipoDeMuestraAComparar,
  metodo,
  tipoDeMuestra,
  currentIndex,
}) => {
  if (noEstudios > 1) {
    if (metodoAComprar !== "" || tipoDeMuestraAComparar !== "") {
      return <Metodo metodo={metodo} tipoDeMuestra={tipoDeMuestra} />;
    } else {
      if (noEstudios === currentIndex + 1) {
        return <Metodo metodo={metodo} tipoDeMuestra={tipoDeMuestra} />;
      }

      return null;
    }
  } else {
    return <Metodo metodo={metodo} tipoDeMuestra={tipoDeMuestra} />;
  }
};

export {
  ReportElisa,
  ReportPCRCOVID,
  ReportPPNI,
  ReportPPNIE,
  ReportFishPrenatal,
  ReportFishPOC,
  ReportCP,
  ReportCPPT,
  ReportCPPT2,
  ReportDP,
  ReportVPH,
  ReportCHEB,
  ReportMT,
  ReportHIV,
  ReportARVI,
  TPB,
  TPA,
  R,
  TXG,
  CPRE,
  HSG,
  PJ,
  FE,
  AN,
  ASC,
  AM,
  ACGH,
  EX,
  FQ,
  XF,
  PR,
  PP,
  BRNM,
  CH,
  HA,
  EstudiosXArea,
  ANT,
  CEN,
  ReportASC_EN,
  COR,
  Simple,
  RenderCampoEstudio,
  ResultadosEstudio,
  DivisorDeAreas,
  ResultadosHemograma,
  HO,
  HC,
  DZC,
  CAB,
  GHLA,
  ResultadosEstudioUnificado,
  ReportFH,
  ReportVPHG,
  ReportPDM,
};
