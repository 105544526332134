import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import imgEncabezado from '../../assets/encabezado2.png'

const Header = ({ children }) => {
  return (
    <View fixed style={styles.encabezadoStyle}>
      <Image
        src={imgEncabezado}
      />
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  encabezadoStyle: {
    width: '75%',
    marginTop: 2, //30
    paddingHorizontal: 20,
    paddingTop: 20,
    marginBottom: 20,
    backgroundColor: 'white',
  },
})

export default Header;