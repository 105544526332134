import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { getAge, getEdad } from '../../utils/common'
import stylesReport from './styles';
import moment from 'moment';

const InformacionDelPaciente = ({ paciente, fechaSolicitud, fechaReporte, codigoEstudio, ordenNo, SG, CR, medico, spaceMedico, language, CRH, IDR, IDARCHIVO }) => {

  const edadPaciente = getEdad(paciente.fecha_nacimiento, paciente.edad);
  const edadPacienteEN = getAge(paciente.fecha_nacimiento, paciente.edad);

  const {
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  if (language === 'EN') {
    return (
      <View style={[mainContainerStyle, { marginTop: 25 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>Patient Information:</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 2 }}>
            <Text style={textStyle}>
              Full name:
              <Text style={dataTextStyle}>
                {paciente.nombres ? ` ${paciente.nombres} ` : ""}
              </Text>
              <Text style={dataTextStyle}>
                {paciente.apellidos ? `${paciente.apellidos}` : ""}
              </Text>
            </Text>
            <Text style={textStyle}>ID number:
              <Text style={dataTextStyle}>
                {paciente.ci ? ` ${paciente.ci} ` : ""}
              </Text>
            </Text>
            {
              paciente.passport ?
                <Text style={textStyle}>Passport number:
                  <Text style={dataTextStyle}>
                    {paciente.passport ? ` ${paciente.passport} ` : ""}
                  </Text>
                </Text>
                : null
            }
            <Text style={textStyle}>Date of birth:
              <Text style={dataTextStyle}>
                {paciente.fecha_nacimiento ? ` ${moment(paciente.fecha_nacimiento, "DD/MM/YYYY").utc().format("MM/DD/YYYY")} ` : ""}
              </Text>
            </Text>

            <Text style={textStyle}>Test code:
              <Text style={dataTextStyle}>
                {` ${codigoEstudio} `}
              </Text>
            </Text>
            <Text style={textStyle}>Receipt date:
              <Text style={dataTextStyle}>
                {` ${fechaSolicitud} `}
              </Text>
            </Text>
          </View>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <Text style={textStyle}>Gender:
              <Text style={dataTextStyle}>
                {paciente.sexo ? ` ${paciente.sexo} ` : ""}
              </Text>
            </Text>
            <Text style={textStyle}>Age:
              <Text style={dataTextStyle}>
                {` ${edadPacienteEN} `}
              </Text>
            </Text>
            <Text style={textStyle}>Phone number:
              <Text style={dataTextStyle}>
                {paciente.telefono ? ` ${paciente.telefono} ` : ""}
              </Text>
            </Text>
            <Text style={textStyle}>Order number:
              <Text style={dataTextStyle}>
                {` ${ordenNo} `}
              </Text>
            </Text>

            <Text style={textStyle}>Report date:
              <Text style={dataTextStyle}>
                {` ${fechaReporte} `}
              </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>
    )
  }

  return (
    <View style={[mainContainerStyle, { marginTop: 0 }]}>
      <View style={dataContainerStyle}>
        <Text style={blueTextStyle}>NOMBRE DEL MÉDICO SOLICITANTE :
          <Text style={[textStyle, dataTextStyle, { textTransform: 'capitalize' }]}>{medico ? ` ${medico} ` : ""}</Text>
        </Text>
      </View>
      {
        spaceMedico ?
          <View style={{ marginTop: 15 }}></View> : null
      }
      <View style={dataContainerStyle}>
        <Text style={blueTextStyle}>Información del paciente:</Text>
      </View>
      <View style={[dataContainerStyle]}>
        <View style={{ flexDirection: 'column', flex: 2 }}>
          <Text style={textStyle}>
            Nombres y Apellidos:
            <Text style={dataTextStyle}>
              {paciente.nombres ? ` ${paciente.nombres} ` : ""}
            </Text>
            <Text style={dataTextStyle}>
              {paciente.apellidos ? `${paciente.apellidos}` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Carnet de Identidad:
            <Text style={dataTextStyle}>
              {paciente.ci ? ` ${paciente.ci} ` : ""}
            </Text>
          </Text>
          {
            paciente.passport ?
              <Text style={textStyle}>Nro de Pasaporte:
                <Text style={dataTextStyle}>
                  {paciente.passport ? ` ${paciente.passport} ` : ""}
                </Text>
              </Text>
              : null
          }
          <Text style={textStyle}>Fecha de Nacimiento:
            <Text style={dataTextStyle}>
              {paciente.fecha_nacimiento ? ` ${paciente.fecha_nacimiento} ` : ""}
            </Text>
          </Text>

          <Text style={textStyle}>C&oacute;digo del Estudio:
            <Text style={dataTextStyle}>
              {` ${codigoEstudio} `}
            </Text>
          </Text>
          {
            CRH ?
              <Text style={textStyle}>C&oacute;digo recoleci&oacute;n:
                <Text style={dataTextStyle}>
                  {` ${CRH} `}
                </Text>
              </Text> : null
          }
          {
            SG ?
              <Text style={textStyle}>Semana de Gestaci&oacute;n:
                <Text style={dataTextStyle}>
                  {` ${SG} semanas`}
                </Text>
              </Text> : null
          }
          <Text style={textStyle}>Fecha Solicitud:
            <Text style={dataTextStyle}>
              {` ${fechaSolicitud} `}
            </Text>
          </Text>
        </View>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <Text style={textStyle}>Sexo:
            <Text style={dataTextStyle}>
              {paciente.sexo ? ` ${paciente.sexo} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Edad:
            <Text style={dataTextStyle}>
              {` ${edadPaciente} `}
            </Text>
          </Text>
          <Text style={textStyle}>Tel&eacute;fono:
            <Text style={dataTextStyle}>
              {paciente.telefono ? ` ${paciente.telefono} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Nro de Orden:
            <Text style={dataTextStyle}>
              {` ${ordenNo} `}
            </Text>
          </Text>
          {
            CR ?
              <Text style={textStyle}>C&oacute;digo recoleci&oacute;n de kit PANORAMA:
                <Text style={dataTextStyle}>
                  {` ${CR} `}
                </Text>
              </Text> : null
          }
          {
            IDR ?
              <Text style={textStyle}>ID de referencia:
                <Text style={dataTextStyle}>
                  {` ${IDR} `}
                </Text>
              </Text> : null
          }
          {
            IDARCHIVO ?
              <Text style={textStyle}>ID de archivo del caso:
                <Text style={dataTextStyle}>
                  {` ${IDARCHIVO} `}
                </Text>
              </Text> : null
          }



          <Text style={textStyle}>Fecha de Entrega de Resultados:
            <Text style={dataTextStyle}>
              {` ${fechaReporte} `}
            </Text>
          </Text>
        </View>
      </View>
      <View style={dividerStyle}></View>
    </View>
  )
}

export default InformacionDelPaciente;

