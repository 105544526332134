import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const ReportDP = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    subtitleBlue
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: 'center' }]}> PREECLAMPSIA </Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 16 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Datos del Cribado :</Text>
              <Text style={textStyle}>Raza / Etnia:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "raza")} `}</Text>
              </Text>
              <Text style={textStyle}>Peso:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "peso")} ${getValueField(estudio.camposEstudio, "peso", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Altura:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "altura")} ${getValueField(estudio.camposEstudio, "altura", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Indice de masa corporal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "indice_m")} ${getValueField(estudio.camposEstudio, "indice_m", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Tensión Arterial:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "tension_arterial_1")} ${getValueField(estudio.camposEstudio, "tension_arterial_1", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Fecha del Cribado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_screening")} `}</Text>
              </Text>
              <Text style={textStyle}>Fumadora:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fumadora")} `}</Text>
              </Text>
              <Text style={textStyle}>Diabética:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "diabetica")} `}</Text>
              </Text>
              <Text style={textStyle}>Ovulación Inducida:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "ovulacion_inducida")} `}</Text>
              </Text>
              <Text style={textStyle}>Gemelos:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "gemelos")} `}</Text>
              </Text>
              <Text style={textStyle}>Nulipara:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "nulipara")} `}</Text>
              </Text>
              <Text style={textStyle}>Hipertensión Cronica:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "hipertension_cronica")} `}</Text>
              </Text>
              <Text style={textStyle}>Enfermedad Renal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "enfermedad_renal")} `}</Text>
              </Text>
              <Text style={textStyle}>Enfermedad Autoinmune:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "enfermedad_autoinmune")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>INFORMACIÓN BIOQUÍMICA:</Text>
              <Text style={textStyle}>Fecha del Análisis de Sangre:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_analisis")} `}</Text>
              </Text>
              <Text style={textStyle}>PAPP-A:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "papp_a")} ${getValueField(estudio.camposEstudio, "papp_a", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>PLGF:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "plgf")} ${getValueField(estudio.camposEstudio, "plgf", "unidad")} `}</Text>
              </Text>
              <Text style={[subtitleBlue, { marginTop: 4 }]}>INFORMACIÓN ECOGRÁFICA:</Text>
              <Text style={textStyle}>Fecha de la Ecografía:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fecha_ecografia")} `}</Text>
              </Text>
              <Text style={textStyle}>CRL:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "crl")} ${getValueField(estudio.camposEstudio, "crl", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>UAPI:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "uapi")} ${getValueField(estudio.camposEstudio, "uapi", "unidad")} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle]}> Resultados del cribado</Text>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[subtitleBlue, { textTransform: 'none' }]}>MARCADORES GAUSIANOS MoM :</Text>
              <Text style={textStyle}>PIGF:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "pigf")} ${getValueField(estudio.camposEstudio, "pigf", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Tensión Arterial:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "tension_arterial")} ${getValueField(estudio.camposEstudio, "tension_arterial", "unidad")} `}</Text>
              </Text>
              <Text style={textStyle}>Doppler de Arteria Uterina:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "dopler")} ${getValueField(estudio.camposEstudio, "dopler", "unidad")} `}</Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>FECHA PREVISTA DE PARTO Y EDAD GESTACIONAL:</Text>
              <Text style={textStyle}>Día estimado del Parto:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "dia_parto")} `}</Text>
              </Text>
              <Text style={textStyle}>Edad Gestacional en la fecha del Cribado:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "edad_gestacional")} `}</Text>
              </Text>
              <Text style={textStyle}>Edad Gestacional Método de Cálculo:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "edad_gestacional_calculo")} `}</Text>
              </Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Calculo del riesgo:</Text>
              <Text style={textStyle}>Preeclampsia Tardia:
                <Text style={dataTextStyle}>{`        ${getValueField(estudio.camposEstudio, "preeclampsia_tardia")}          ${getValueField(estudio.camposEstudio, "preeclampsia_tardia") === "Riesgo Positivo"? getValueField(estudio.camposEstudio, "cantidad_preeclampsia_tardia_p") : getValueField(estudio.camposEstudio, "cantidad_preeclampsia_tardia_n") }`}</Text>
              </Text>
              <Text style={textStyle}>Preeclampsia Temprana:
                <Text style={dataTextStyle}>{`  ${getValueField(estudio.camposEstudio, "preeclampsia_temprana")}          ${getValueField(estudio.camposEstudio, "preeclampsia_temprana") === "Riesgo Positivo"? getValueField(estudio.camposEstudio, "cantidad_preeclampsia_temprana_p") : getValueField(estudio.camposEstudio, "cantidad_preeclampsia_temprana_n") }`}</Text>
              </Text>
            </View>
          </View>
        </View>

        {
          getValueField(estudio.camposEstudio, "observaciones") !== "" ?
          <View style={[mainContainerStyle, { marginTop: 8 }]}>
            <View style={[dataContainerStyle]}>
              <View style={{ flexDirection: 'column', flex: 1 }}>
                <Text style={subtitleBlue}>Observaciones: <Text style={[textStyle, dataTextStyle, {textTransform: 'none'}]}> {` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text></Text>
              </View>
            </View>
          </View>
          : null
        }

        <View style={[mainContainerStyle, { marginTop: 10 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={subtitleBlue}>Comentarios:</Text>
              <Text style={[textStyle, dataTextStyle]}> Esta es una prueba de screening.</Text>
              <Text style={[textStyle, dataTextStyle]}> El índice de riesgo es un cálculo estadístico y no tiene ningún valor diagnóstico.</Text>
              <Text style={[textStyle, dataTextStyle]}>Por favor revise cuidadosamente todos los detalles del informe.</Text>
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 10 }}
          isSigned={estudio.isSigned}
        />
        <Footer />
      </Page>
  )
}

export default ReportDP;