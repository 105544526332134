import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista'
import WaterMark from '../informes/WaterMark';

const ReportPPNI = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    semiTitlesTextStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="A4" wrap={true} style={[pageStyle, {paddingBottom: 100}]}>
        <Header />
        <WaterMark typePage="A4" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          SG={getValueField(estudio.camposEstudio, "semana_gestacion")}
          CR={getValueField(estudio.camposEstudio, "codigo_recoleccion_kit")}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> Detección de polimorfismos de nucleótidos simples (SNP) por secuenciación de nueva generación (NGS) </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado final:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "resultado_final")} `}</Text>
              </Text>
              <Text style={textStyle}>Sexo Fetal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "sexo_fetal")} `}</Text>
              </Text>
              <Text style={textStyle}>Fraci&oacute;n Fetal:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "fraccion_fetal_adn")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 4 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultados Detallados:</Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 2, paddingHorizontal: 4 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={semiTitlesTextStyle}>
                S&iacute;ndromes G&eacute;neticos
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
                Trisom&iacute;a 21
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                Trisom&iacute;a 18
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                Trisom&iacute;a 13
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                Monosom&iacute;a
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                Triploid&iacute;a
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={semiTitlesTextStyle}>
                Resultado
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 4 }]}>
                {` ${getValueField(estudio.camposEstudio, "trisomia_21")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {` ${getValueField(estudio.camposEstudio, "trisomia_18")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {` ${getValueField(estudio.camposEstudio, "trisomia_13")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {` ${getValueField(estudio.camposEstudio, "monosomia_x")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {` ${getValueField(estudio.camposEstudio, "triploidia")} `}
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={semiTitlesTextStyle}>
                Riesgo antes del examen
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 4 }]}>
                {getValueField(estudio.camposEstudio, "trisomia_21") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t21_riesgo_antes_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t21_riesgo_antes_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "trisomia_18") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t18_riesgo_antes_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t18_riesgo_antes_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "trisomia_13") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t13_riesgo_antes_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t13_riesgo_antes_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "monosomia_x") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "mx_riesgo_antes_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "mx_riesgo_antes_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "triploidia") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t_riesgo_antes_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t_riesgo_antes_del_examen_alto")} `}
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={semiTitlesTextStyle}>
                Riesgo despu&eacute;s del examen
              </Text>
              <Text style={[textStyle, dataTextStyle, { marginTop: 4 }]}>
                {getValueField(estudio.camposEstudio, "trisomia_21") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t21_riesgo_despues_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t21_riesgo_despues_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "trisomia_18") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t18_riesgo_despues_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t18_riesgo_despues_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "trisomia_13") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t13_riesgo_despues_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t13_riesgo_despues_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "monosomia_x") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "mx_riesgo_despues_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "mx_riesgo_despues_del_examen_alto")} `}
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                {getValueField(estudio.camposEstudio, "triploidia") === "Bajo Riesgo" ? ` ${getValueField(estudio.camposEstudio, "t_riesgo_despues_del_examen_bajo")} ` : ` ${getValueField(estudio.camposEstudio, "t_riesgo_despues_del_examen_alto")} `}
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Sobre Panorama &trade;:</Text>
              <Text style={longTextStyle}> No es una prueba diagn&oacute;stica, por ende un resultado de bajo riesgo no garantiza un feto sano.</Text>
              <Text style={longTextStyle}> Esta prueba eval&uacute;a la informaci&oacute;n gen&eacute;tica en la sangre materna, que es una mezcla entre el ADN de la madre y el ADN fetal placentario, para determinar la posibilidad de anomal&iacute;as cromos&oacute;micas o s&iacute;ndromes gen&eacute;ticos espec&iacute;ficos.</Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 4 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>L&iacute;mites de Estudio:</Text>
              <Text style={longTextStyle}> Esta prueba ha sido validada en mujeres con embarazos simples, gemelares o de donante de &oacute;vulos de al menos nueve semanas de gestaci&oacute;n.</Text>
              <Text style={longTextStyle}> Estas incluyen mosaicismo, anomalías estructurales y otras anomalías cromosómicas numéricas no contempladas en el estudio. Por lo tanto, se recomienda el análisis citogenético de rutina o cariotipo para la interpretación final del resultado reflejado en este estudio.</Text>
              <Text style={longTextStyle}>Esta prueba no está disponible para múltiplos de orden superior a dos, y gestación múltiple de donante o sustituto de óvulos, o receptores de trasplante de médula ósea.</Text>
              <Text style={longTextStyle}>El panel de prueba completo no está disponible para gestaciones gemelas y embarazos por donante de óvulos o un sustituto.</Text>
              <Text style={longTextStyle}>Para embarazos gemelares con un valor de fracción fetal por debajo del límite para el análisis, se informará una suma de las fracciones fetales para ambos gemelos.</Text>
              <Text style={longTextStyle}>No se informarán los hallazgos de importancia desconocida.</Text>
              <Text style={longTextStyle}>Como este ensayo es una prueba de detección y no un diagnóstico, pueden ocurrir falsos positivos y falsos negativos.</Text>
              <Text style={longTextStyle}>Los resultados de las pruebas de alto riesgo necesitan confirmación diagnóstica mediante métodos de prueba alternativos.</Text>
              <Text style={longTextStyle}>Los resultados de bajo riesgo no excluyen completamente el diagnóstico de ninguno de los síndromes ni excluyen la posibilidad de otras anomalías cromosómicas o defectos de nacimiento, que no forman parte de esta prueba.</Text>
              <Text style={longTextStyle}>Las posibles fuentes de resultados inexactos incluyen, entre otros, mosaicismo, fracción fetal baja, limitaciones de las técnicas de diagnóstico actuales o identificación errónea de muestras.</Text>
              <Text style={longTextStyle}>Esta prueba no identificará todas las deleciones asociadas con cada síndrome de microdeleción.</Text>
              <Text style={longTextStyle}>Esta prueba se ha validado en deleciones de región completa y es posible que no pueda detectar deleciones más pequeñas para los síndromes de microdeleción.</Text>
              <Text style={longTextStyle}>La puntuación de riesgo de microdeleción depende de la fracción fetal, ya que las deleciones en la copia heredada por vía materna son difíciles de identificar en las fracciones fetales más bajas.</Text>
              <Text style={longTextStyle}>Los resultados de la prueba siempre deben ser interpretados por un médico en el contexto de los datos clínicos y familiares con la disponibilidad de asesoramiento genético cuando sea apropiado.</Text>
              <Text style={longTextStyle}>La prueba prenatal Panorama fue desarrollada por Natera, Inc., un laboratorio certificado bajo las Enmiendas de Mejora del Laboratorio Clínico (CLIA).</Text>
              <Text style={longTextStyle}>Esta prueba no ha sido aprobada por la Administración de Drogas y Alimentos de los EE. UU. (FDA).</Text>
            </View>
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )

}

export default ReportPPNI;