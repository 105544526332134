import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import WaterMark from "../informes/WaterMark";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField, getResultElisaComplete } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
const TIPO_DE_PRUEBA1 = "SARS-CoV-2 (COVID19) IgG";
const TIPO_DE_PRUEBA2 = "SARS-CoV-2 (COVID19) IgM";

const ReportElisa = ({ estudio, paciente, qrValue }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    rowLeftStyle,
    rowRightStyle,
    notesTextStyle,
    qrImageStyle,
  } = stylesReport;

  const fechaSolicitud = moment(estudio.fechaSolicitud).utc();

  const RenderValorReferencia = ({ tipo }) => {
    switch (tipo) {
      case "positivo": {
        const reference =
          1.1 *
          Number(getValueField(estudio.camposEstudio, "factor_multiplicador"));
        return `> ${reference} ${getValueField(
          estudio.camposEstudio,
          "resultado_igg",
          "unidad"
        )}`;
      }
      case "negativo": {
        const reference =
          0.9 *
          Number(getValueField(estudio.camposEstudio, "factor_multiplicador"));
        return `< ${reference} ${getValueField(
          estudio.camposEstudio,
          "resultado_igg",
          "unidad"
        )}`;
      }
      case "indeterminado": {
        const reference1 =
          0.9 *
          Number(getValueField(estudio.camposEstudio, "factor_multiplicador"));
        const reference2 =
          1.1 *
          Number(getValueField(estudio.camposEstudio, "factor_multiplicador"));
        return `${reference1} – ${reference2} ${getValueField(
          estudio.camposEstudio,
          "resultado_igg",
          "unidad"
        )}`;
      }
    }
  };

  const TablaInterpretacion = () => {
    if (
      moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrAfter("2022-01-15")
    ) {
      return (
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={[blueTextStyle, { textAlign: "center" }]}>
              Interpretaci&oacute;n (&iacute;ndice):
            </Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
            <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                REACTIVO
              </Text>
            </View>
            <View style={[{ flexDirection: "column", flex: 1 }, rowRightStyle]}>
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                {" > 1,1"}
              </Text>
            </View>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
            <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                INDETERMINADO
              </Text>
            </View>
            <View style={[{ flexDirection: "column", flex: 1 }, rowRightStyle]}>
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                {" 0,9 – 1,1"}
              </Text>
            </View>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
            <View
              style={[
                {
                  flexDirection: "column",
                  flex: 1,
                  borderBottomWidth: 1,
                },
                rowLeftStyle,
              ]}
            >
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                NO REACTIVO
              </Text>
            </View>
            <View
              style={[
                {
                  flexDirection: "column",
                  flex: 1,
                  borderBottomWidth: 1,
                },
                rowRightStyle,
              ]}
            >
              <Text style={[notesTextStyle, { textAlign: "center" }]}>
                {" < 0,9 "}
              </Text>
            </View>
            <View style={[{ flexDirection: "column", flex: 1 }]}></View>
          </View>
        </View>
      );
    }
    return (
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={[blueTextStyle, { textAlign: "center" }]}>
            Interpretaci&oacute;n del informe:
          </Text>
        </View>

        <View style={[dataContainerStyle, { marginTop: 10 }]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 10 }]}
            >
              REACTIVO
            </Text>
          </View>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 10 }]}
            >
              {" "}
              <RenderValorReferencia tipo="positivo" />{" "}
            </Text>
          </View>
          <View
            style={[
              { flexDirection: "column", flex: 3, padding: 4 },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              Los anticuerpos contra el pat&oacute;geno est&aacute;n presentes.
              Ha producido un contacto con el ant&iacute;geno (pat&oacute;geno
              resp. vacuna).
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 20 }]}
            >
              INDETERMINADO
            </Text>
          </View>
          <View style={[{ flexDirection: "column", flex: 1 }, rowLeftStyle]}>
            <Text
              style={[notesTextStyle, { textAlign: "center", paddingTop: 20 }]}
            >
              <RenderValorReferencia tipo="indeterminado" />
            </Text>
          </View>
          <View
            style={[
              { flexDirection: "column", flex: 3, padding: 4 },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              Los anticuerpos contra el pat&oacute;geno no se pudieron detectar
              claramente. Se recomienda repetir la prueba con una muestra fresca
              en 2 a 4 semanas. Si el resultado es de nuevo en la zona
              intermedia, la muestra se considera como negativa.
            </Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View
            style={[
              {
                flexDirection: "column",
                flex: 1,
                borderBottomWidth: 1,
                paddingTop: 10,
              },
              rowLeftStyle,
            ]}
          >
            <Text style={[notesTextStyle, { textAlign: "center" }]}>
              NO REACTIVO
            </Text>
          </View>
          <View
            style={[
              {
                flexDirection: "column",
                flex: 1,
                borderBottomWidth: 1,
                paddingTop: 10,
              },
              rowLeftStyle,
            ]}
          >
            <Text style={[notesTextStyle, { textAlign: "center" }]}>
              <RenderValorReferencia tipo="negativo" />
            </Text>
          </View>
          <View
            style={[
              {
                flexDirection: "column",
                flex: 3,
                borderBottomWidth: 1,
                padding: 4,
              },
              rowRightStyle,
            ]}
          >
            <Text style={notesTextStyle}>
              La muestra no contiene anticuerpos contra el pat&oacute;geno. Un
              contacto previo con el ant&iacute;geno (pat&oacute;geno resp.
              vacuna) es poco probable.
            </Text>
          </View>
        </View>
      </View>
    );
  };

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tecnica:
              <Text style={dataTextStyle}> ELISA </Text>
            </Text>
            <Text style={textStyle}>
              Fecha toma de muestra:
              <Text style={dataTextStyle}>
                {moment(estudio.fechaTomaDeMuestra).utc().format("HH:mm") ===
                "00:00"
                  ? ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY")}`
                  : ` ${moment(estudio.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY HH:mm")}`}
              </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>Prueba: {TIPO_DE_PRUEBA1}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrBefore(
              "2022-01-14"
            ) ? (
              <Text style={textStyle}>
                Resultado:
                <Text style={dataTextStyle}>
                  {" "}
                  {getResultElisaComplete(estudio.camposEstudio, "igg")}
                </Text>
              </Text>
            ) : null}
            {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrAfter(
              "2022-01-15"
            ) ? (
              <Text style={textStyle}>
                Resultado:
                <Text style={[dataTextStyle, { fontWeight: "800" }]}>
                  {" "}
                  {getValueField(estudio.camposEstudio, "resultado_igg")}
                </Text>
              </Text>
            ) : null}
          </View>
        </View>
        <View style={[dataContainerStyle, { marginTop: 5 }]}>
          <Text style={blueTextStyle}>Prueba: {TIPO_DE_PRUEBA2}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrBefore(
              "2022-01-14"
            ) ? (
              <Text style={textStyle}>
                Resultado:
                <Text style={dataTextStyle}>
                  {" "}
                  {getResultElisaComplete(estudio.camposEstudio, "igm")}{" "}
                </Text>
              </Text>
            ) : null}
            {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrAfter(
              "2022-01-15"
            ) ? (
              <Text style={textStyle}>
                Resultado:
                <Text style={[dataTextStyle, { fontWeight: "800" }]}>
                  {" "}
                  {getValueField(estudio.camposEstudio, "resultado_igm")}
                </Text>
              </Text>
            ) : null}
          </View>
        </View>
        <View style={dividerStyle}></View>

        <Text style={textStyle}>Observaciones:</Text>
        {getValueField(estudio.camposEstudio, "observaciones") !== "" ? (
          <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(
            estudio.camposEstudio,
            "observacion"
          )} `}</Text>
        ) : null}

        <Text
          style={[textStyle, dataTextStyle]}
        >{` Los resultados emitidos deben ser interpretados por el médico tratante y/o valoración médica. `}</Text>
      </View>
      <TablaInterpretacion />

      <FirmaEspecialista
        nombreEspecialista="ruth"
        styleFromParent={{ marginTop: 20 }}
        isSigned={estudio.isSigned}
      />
      <View style={[mainContainerStyle]}>
        <View style={dataContainerStyle}>
          <Image source={{ uri: qrValue }} style={[qrImageStyle]} />
        </View>
      </View>
      <Footer />
    </Page>
  );
};

export default ReportElisa;
