import React, { useEffect, useState, useContext } from 'react';
import { Row, Col, Form, Input, Button, Typography, DatePicker, Table, Tag } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { useManualQuery } from 'graphql-hooks';
import { GET_ORDEN_DETALLES_PUBLIC } from '../queries';
import moment from 'moment';
import DownloadButton from '../components/informes/DownloadButton';
import OldDownloadButton from '../components/DownloadButton';
import { Context as AuthContext } from '../context/AuthContext';
import { Redirect } from "react-router";
import RingLoader from "react-spinners/RingLoader";
import { css } from "@emotion/core";

const ERROR_REPORTE_EMPRESA = `Por favor comunicarse con su empresa, o centro de salud\npara saber mas detalles sobre el resultado de su prueba.`;
const ERROR_REPORTE_PAGO = `Por favor confirmar su pago para acceder al resultado.`;
const DATELINE = moment("10/08/2020", "DD/MM/YYYY");
const INFORMES_NO_DISPONIBLES = ['EN PROCESO', 'NULO', 'CANCELADO', "PENDIENTE"];
const queryString = require('query-string');

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const ResultadosOrden = (props) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState();
  const [error, setError] = useState(null);
  const { setNavigation } = useContext(AuthContext);
  const [resultSearch, setResultSearch] = useState([]);
  const [showCleanBtn, setShowCleanBtn] = useState(false);
  const [getEstudiosPorOrdenPublic] = useManualQuery(GET_ORDEN_DETALLES_PUBLIC);

  const { Title, Text, Paragraph } = Typography;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (!parsed.key) {
      return <Redirect to="/" />;
    }
    verificarEstudio(parsed.key);
  }, [props.location.search]);

  const verificarEstudio = (key) => {
    console.log(key);
    getEstudiosPorOrdenPublic({
      variables: { keyHash: key }
    }).then(({ data, error }) => {
      if (error) {
        setError(error.graphQLErrors[0].message);
        return;
      }
      if (data.getEstudiosPorOrdenPublic) {
        console.log(data.getEstudiosPorOrdenPublic);
        setResultSearch(data.getEstudiosPorOrdenPublic)
      }
    })
  }

  const columns = [
    {
      title: 'Nombre Estudio',
      dataIndex: 'estudioID.nombre',
      key: 'estudioID',
      render: (estudioID, item) => {
        if (item.testID) {
          return (
            <Text>{item.testID.nombre}</Text>
          )
        }
        return (
          <Text>{item.estudioID.nombre}</Text>
        )
      }
    },
    {
      title: 'Fecha Toma de Muestra',
      dataIndex: 'fechaTomaDeMuestra',
      key: 'fechaTomaDeMuestra',
      render: (fechaTomaDeMuestra, item) => {
        if (item.testID) {
          return (
            <Text>{item.fechaSolicitud}</Text>
          )
        }
        return (
          <Text>
            {
              moment(item.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                  ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY')}`
                    :
                ` ${moment(item.fechaTomaDeMuestra).utc().format('DD/MM/YYYY HH:mm')}`
            }
          </Text>
        )
      }
    },
    {
      title: 'Codigo Informe',
      dataIndex: 'codigo',
      key: 'codigo',
      render: (codigo, item) => {
        if (item.testID) {
          return (
            <Text>{item.codigo}</Text>
          )
        }
        return (
          <Text>{item.codigoEstudio}{item.estudioNumero}</Text>
        )
      }
    },
    {
      title: 'Estatus',
      key: 'estadoEstudio',
      dataIndex: 'estadoEstudio',
      render: (estadoEstudio, item) => {
        let color = 'orange';

        if (item.ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" && !item.ordenID.proveedorID.informesPublicos) {
          return (
            <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'mas' }}>
              {ERROR_REPORTE_EMPRESA}
            </Paragraph>
          )
        }

        if (item.estadoEstudio !== 'PROCESADO') {
          return (
            <Tag color={color}>
              {item.estadoEstudio}
            </Tag>
          )
        }

        color = 'green';

        return (
          <Tag color={color}>
            DISPONIBLE
          </Tag>
        )
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, item) => {
        if (item.testID) {
          if (item.tipoFicha.trim().toUpperCase() !== "PARTICULAR" && item.tipoFicha.trim().toUpperCase() !== "DOMICILIO") {
            return null;
          }

          if (moment(item.fechaSolicitud, "DD/MM/YYYY").isSameOrAfter(DATELINE)) {
            if (!item.pagoStatus) {
              return null;
            }

            if (item.pagoStatus === "POR COBRAR") {
              return null;
            }
          }

          if (!item.resultado) {
            return null;
          }

          if (INFORMES_NO_DISPONIBLES.includes(item.resultado.toUpperCase())) {
            return null;
          }

          return (
            <OldDownloadButton rowData={item} test={item.testID} />
          )
        }

        if (item.estadoEstudio !== 'PROCESADO' || item.ordenID.proveedorID.tipo.toUpperCase() !== "PARTICULAR" && !item.ordenID.proveedorID.informesPublicos) {
          return null;
        }

        return (
          <DownloadButton size="small" disabled={false} estudio={item} cliente={item.ordenID.clientID} />
        )
      },
    },
  ];

  const cleanData = () => {
    form.resetFields();
    setResultSearch([]);
    setShowCleanBtn(false);
    setError("");
  }

  const handleSearch = (values) => {
    setLoading(true);
    setError("");
    const { carnet, fecha_nacimiento } = values;
    // fetchNewOrders(carnet, fecha_nacimiento);
  }

  return (
    <>
      <Row type="flex" style={{ backgroundColor: 'white' }}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 8, offset: 8 }} lg={{ span: 8, offset: 8 }} >
          <img src={require('../assets/imagen.jpg')} style={{ width: '100%' }} alt="Logo Laboratorio Origen" />
        </Col>

      </Row>
      <Row>
        {
          error ?
            <Col span={24} style={{ padding: '20px' }}>
              <Typography level={4} style={{ textAlign: 'center', color: 'red', alignSelf: 'center' }}>{error}</Typography>
            </Col> : null
        }
        <Col span={24} style={{ backgroundColor: 'white' }}>
          <RingLoader
            css={override}
            size={150}
            color={"#2DB9B4"}
            loading={loading}
          />
        </Col>
        {
          resultSearch && resultSearch.length > 0 ?
            <Col span={24}>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={resultSearch}
                style={{ minWidth: '100%' }}
              />
            </Col> :
            null
        }
      </Row>
    </>

  )
}

export default ResultadosOrden;