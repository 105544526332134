import axios from 'axios';
import { getToken } from '../utils/common';

const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    const token = getToken();
    if (token) {
      config.headers['authorization'] = `Bearer ${token}`
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// instance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     console.log(error.response.status);
//     if (error.response) {
//       if (error.response.status === 401) {
//         AsyncStorage.removeItem('token');
//         navigate('Login', { token_error: 'Your session has expired, please login again.' });
//         return Promise.reject(error);
//       }
//       return Promise.reject(error);
//     }
//     return Promise.reject(error);
//   }
// );

export default instance;