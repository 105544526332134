import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody, TableCell } from '@david.kucsai/react-pdf-table'
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const FONT_SIZE = 8;

const ReportFP = ({ estudio, paciente }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  const calculatePercentage = (value1, value2) => {
    const percentaje = ((Number(value1) * 100) / Number(value2));
    return Number(percentaje).toFixed(2);
  }

  const FRECUENCIA13 = Number(getValueField(estudio.camposEstudio, "total_normales_13")) / Number(getValueField(estudio.camposEstudio, "total_13"));
  const FRECUENCIA21 = Number(getValueField(estudio.camposEstudio, "total_normales_21")) / Number(getValueField(estudio.camposEstudio, "total_21"));
  const FRECUENCIA18 = Number(getValueField(estudio.camposEstudio, "total_normales_18")) / Number(getValueField(estudio.camposEstudio, "total_18"));
  const FRECUENCIAXY = Number(getValueField(estudio.camposEstudio, "total_normales_xy")) / Number(getValueField(estudio.camposEstudio, "total_xy"));

  const MULTIPLICACION = FRECUENCIA13 * FRECUENCIA21 * FRECUENCIA18;
  const RAIZ_CUBICA = Math.pow(MULTIPLICACION, 1 / 3);
  const TOTAL_NORMALES = (FRECUENCIAXY * (Math.pow(RAIZ_CUBICA, 22)) * 100);
  const RIESGO_GENETICO = Number(100) - Number(TOTAL_NORMALES);

  return (
      <Page size="LETTER" wrap={true} style={[pageStyle, {paddingBottom: 100}]}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "estudio")} `} </Text>
              </Text>
              <Text style={textStyle}>Metodología:
                <Text style={dataTextStyle}> {` ${estudio.estudioID.metodo} `} </Text>
              </Text>
              <Text style={textStyle}>Sondas utilizada:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "sondas_utilizadas")} `} </Text>
              </Text>
              <View style={dividerStyle}></View>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Resultado:</Text>
            </View>
          </View>
        </View>


        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <Table>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE} includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                <TableCell style={{ borderColor: 'transparent', borderWidth: 0 }}></TableCell>
                <TableCell style={{ backgroundColor: '#b2deec', paddingVertical: 5 }}> Normales </TableCell>
                <TableCell style={{ backgroundColor: '#b2deec', paddingVertical: 5, borderColor: 'transparent', borderWidth: 0 }} > Anormales </TableCell>
              </TableHeader>
            </Table>
          </View>
          <View>
            <Table data={[
              { campo0: '13', campo1: "total_13", campo2: "total_normales_13", campo3: "porcentaje_n_13", campo4: "total_anormales_13", campo5: "porcentaje_a_13" },
              { campo0: '21', campo1: "total_21", campo2: "total_normales_21", campo3: "porcentaje_n_21", campo4: "total_anormales_21", campo5: "porcentaje_a_21" },
              { campo0: '18', campo1: "total_18", campo2: "total_normales_18", campo3: "porcentaje_n_18", campo4: "total_anormales_18", campo5: "porcentaje_a_18" },
              { campo0: 'X+Y', campo1: "total_xy", campo2: "total_normales_xy", campo3: "porcentaje_n_xy", campo4: "total_anormales_xy", campo5: "porcentaje_a_xy" }
            ]}>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE}>
                <TableCell> Cromosomas </TableCell>
                <TableCell> Total </TableCell>
                <TableCell> N° </TableCell>
                <TableCell> % </TableCell>
                <TableCell> N° </TableCell>
                <TableCell> % </TableCell>
              </TableHeader>
              <TableBody textAlign={"center"} fontSize={FONT_SIZE}>
                <DataTableCell getContent={(r) => r.campo0} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo1)} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo2)} />
                <DataTableCell getContent={(r) => calculatePercentage(getValueField(estudio.camposEstudio, r.campo2), getValueField(estudio.camposEstudio, r.campo1))} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo4)} />
                <DataTableCell getContent={(r) => calculatePercentage(getValueField(estudio.camposEstudio, r.campo4), getValueField(estudio.camposEstudio, r.campo1))} />
              </TableBody>
            </Table>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 15, paddingHorizontal: 90 }]}>
          <View style={[dataContainerStyle]}>
            <Table>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE} includeBottomBorder={false}>
                <TableCell style={{ fontWeight: '800' }}>Total normales (%)*</TableCell>
                <TableCell>{`${Number(TOTAL_NORMALES).toFixed(2)}`}</TableCell>
              </TableHeader>
            </Table>
          </View>
          <View>
            <Table>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE}>
                <TableCell style={{ fontWeight: '800' }}>Riesgo Genético (%)</TableCell>
                <TableCell>{`${Number(RIESGO_GENETICO).toFixed(2)}`}</TableCell>
              </TableHeader>
            </Table>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Valores de Corte:</Text>
              <Text style={[textStyle, dataTextStyle]}> Riesgo genético en la población normal menor o igual al 15 %.</Text>
              <Text style={[textStyle, dataTextStyle]}> Solo valores de Riesgo Genético menores de 25 % han resultado en embarazo bioquímico o clínico.</Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <Table>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE} includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false} includeBottomBorder={false}>
                <TableCell weighting={0.5} style={{ borderColor: 'transparent', borderWidth: 0 }}></TableCell>
                <TableCell style={{ backgroundColor: '#bababa', paddingVertical: 3 }}> Anormales disómicos</TableCell>
                <TableCell style={{ backgroundColor: '#bababa', paddingVertical: 3, borderColor: 'transparent', borderWidth: 0 }} > Anormales nulisómicos </TableCell>
              </TableHeader>
            </Table>
          </View>
          <View>
            <Table data={[
              { campo0: '13', campo1: "anormales_d_13", campo2: "porcentaje_d_13", campo3: "anormales_n_13", campo4: "porcentaje_n_13", campo5: "total_13" },
              { campo0: '21', campo1: "anormales_d_21", campo2: "porcentaje_d_21", campo3: "anormales_n_21", campo4: "porcentaje_n_21", campo5: "total_21" },
              { campo0: '18', campo1: "anormales_d_18", campo2: "porcentaje_d_18", campo3: "anormales_n_18", campo4: "porcentaje_n_18", campo5: "total_18" },
              { campo0: 'X/Y', campo1: "anormales_d_xy", campo2: "porcentaje_d_xy", campo3: "anormales_n_xy", campo4: "porcentaje_n_xy", campo5: "total_xy" }
            ]}>
              <TableHeader textAlign={"center"} fontSize={FONT_SIZE}>
                <TableCell> Cromosomas </TableCell>
                <TableCell> N° </TableCell>
                <TableCell> % </TableCell>
                <TableCell> N° </TableCell>
                <TableCell> % </TableCell>
              </TableHeader>
              <TableBody textAlign={"center"} fontSize={FONT_SIZE}>
                <DataTableCell getContent={(r) => r.campo0} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo1)} />
                <DataTableCell getContent={(r) => calculatePercentage(getValueField(estudio.camposEstudio, r.campo1), getValueField(estudio.camposEstudio, r.campo5))} />
                <DataTableCell getContent={(r) => getValueField(estudio.camposEstudio, r.campo3)} />
                <DataTableCell getContent={(r) => calculatePercentage(getValueField(estudio.camposEstudio, r.campo3), getValueField(estudio.camposEstudio, r.campo5))} />
              </TableBody>
            </Table>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Interpretación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "interpretacion")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Recomendaciones:</Text>
              {
                getValueField(estudio.camposEstudio, "recomendacion_abierta") ?
                  <Text style={[textStyle, dataTextStyle]}> {` ${getValueField(estudio.camposEstudio, "recomendacion_abierta")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco1") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco1", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco2") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco2", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco3") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco3", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco4") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco4", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco5") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco5", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco6") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco6", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco7") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco7", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco8") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco8", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco9") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco9", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco10") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco10", "label")} `}</Text> : null
              }
              {
                getValueField(estudio.camposEstudio, "reco11") === "mostrar" ?
                  <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "reco11", "label")} `}</Text> : null
              }
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Nota:</Text>
              <Text style={[textStyle, dataTextStyle]}> El porcentaje de espermatozoides normales se refiere al resultado obtenido extrapolando los datos de los 5 cromosomas analizados a los 24 pares cromosómicos.</Text>
              <Text style={[textStyle, dataTextStyle]}> Se define como riesgo genético la probabilidad que un espermatozoide con alguna anomalía cromosómica fecunde un ovocito naturalmente.</Text>
            </View>
          </View>
        </View>

        <View style={[dataContainerStyle, { marginTop: 30 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )
}

export default ReportFP;