import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { Table, DataTableCell, TableHeader, TableBody, TableCell } from '@david.kucsai/react-pdf-table'
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
import WaterMark from '../informes/WaterMark';

const FONT_SIZE = 8;

const ReportPR = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <InformacionDelPaciente
          paciente={paciente}
          fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
          fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
          codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
          ordenNo={estudio.ordenNo}
          medico={estudio.ordenID.nombreMedico}
        />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Estudio:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Indicación:
                <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, 'indicacion')} `}</Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>Tecnica:
                <Text style={dataTextStyle}> {estudio.estudioID.metodo}  </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {getValueField(estudio.camposEstudio, 'resultado')}</Text>
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Interpretación:
                {
                  getValueField(estudio.camposEstudio, 'interpretacion') !== "" ?
                    <Text style={[textStyle, dataTextStyle]}> {getValueField(estudio.camposEstudio, 'interpretacion')}</Text> : null
                }
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Alcance de la prueba:</Text>
              <Text style={longTextStyle}> Se determinan 21 microdeleciones para fenotipo de retraso cognitivo y retraso en desarrollo psicomotor, mediante la técnica de amplificación de sondas dependiente de ligandos y  electroforesis capilar. </Text>
              <Text style={longTextStyle}> El kit  permite analizar las siguientes microdeleciones: </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 15, paddingHorizontal: 20 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle]}>
                •	1p36
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	2p16.1
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	2q23.1
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	2q32
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	3q29
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	3q29 (microduplicacion)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	4p16.3 (Wolf-Hirschhorn)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	8q2 (Langer-Giedion)
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle]}>
                •	9q22.3
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	15q24 (Witteveen-Kolk)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17p13.3 (Miller-Dieker)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17p11.2 (Smith-Magenis)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17p11.2 (Potocki-Lupski)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17q11.2 (Microdeleción NF1)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17q21.31 Koolen-de Vries
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	17q21.31 (Microduplicación)
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={[textStyle, dataTextStyle]}>
                •	22q11.2 (Microduplicación)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	22q11.2  (Distal)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	22q13.33 (Phelan-Mc Dermind)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	Xq28 (Rett)
              </Text>
              <Text style={[textStyle, dataTextStyle]}>
                •	Xq28 ( Duplicación MECP2)
              </Text>
            </View>
          </View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}> Limitaciones:</Text>
              <Text style={longTextStyle}> La técnica permite determinar el número de copias en genes involucrados en las regiones estudiadas, no permite estudiar mosaicismos. Otras enfermedades no podrán ser detectadas por esta metodología.  </Text>
            </View>
          </View>
        </View>

        <View style={[dataContainerStyle, { marginTop: 20 }]}>
          <FirmaEspecialista
            nombreEspecialista="natalia"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
          />
          <FirmaEspecialista
            nombreEspecialista="sebastian"
            styleFromParent={{ flexDirection: 'column', flex: 1 }}
            isSigned={estudio.isSigned}
            fechaReporte={estudio.fechaReporte}
          />
        </View>
        <Footer CS />
      </Page>
  )

}



export default ReportPR;