import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import imgFirma from '../assets/firmaYamila.png';
import Header from './informes/Header';
import Footer from './informes/Footer';
import moment from 'moment';
import WaterMark from './informes/WaterMark';
import { getEdad, getResultElisa } from '../utils/common';
const TIPO_DE_PRUEBA1 = "SARS-CoV-2 (COVID19) IgG";
const TIPO_DE_PRUEBA2 = "SARS-CoV-2 (COVID19) IgM";

const TECNICA = "ELISA";

const ReportElisa = ({ item, qrValue }) => {
  const { pageStyle, mainContainerStyle, containerTituloStyle, dataContainerStyle,
    blueTextStyle, titleStyle, textStyle, dividerStyle, ResultTextStyle, rowLeftStyle, rowRightStyle,
    notesTextStyle, showSign, notShowSign, interpretacionHeadlineStyle, qrImageStyle
  } = styles;

  let defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 2);
  if (defaultDateReport > moment()) {
    defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 1);
  }

  const edadPaciente = getEdad(item.clientID.fecha_nacimiento, item.clientID.edad);
  const resultadoElisa = item.resultado.split('|');

  const getResultLabel = (value) => {
    if (moment(item.fechaReporte, "DD-MM-YYYY").isSameOrAfter('2020-11-24') && moment(item.fechaReporte, "DD-MM-YYYY").isBefore('2020-12-04')) {
      if (Number(value) > 1.1) return "(Reactivo)";
      if (Number(value) < 0.9) return "(No Reactivo)"
      if (Number(value) >= 0.9 && Number(value) <= 1.1) {
        return "(Indeterminado)"
      }
    } else {
      if (Number(value) > 11) return "NTU (Reactivo)";
      if (Number(value) < 9) return "NTU (No Reactivo)"
      if (Number(value) >= 9 && Number(value) <= 11) {
        return "NTU (Indeterminado)"
      }
    }
  }

  const RenderValorReferencia = ({ tipo }) => {
    switch (tipo) {
      case 'positivo': {
        if (moment(item.fechaReporte, "DD-MM-YYYY").isSameOrAfter('2020-11-24') && moment(item.fechaReporte, "DD-MM-YYYY").isBefore('2020-12-04')) {
          return `> 1.1`;
        } else {
          return `> 11 NTU`;
        }
      }
      case 'negativo': {
        if (moment(item.fechaReporte, "DD-MM-YYYY").isSameOrAfter('2020-11-24') && moment(item.fechaReporte, "DD-MM-YYYY").isBefore('2020-12-04')) {
          return `< 0.9`;
        } else {
          return `< 9 NTU`;
        }
      }

      case 'indeterminado': {
        if (moment(item.fechaReporte, "DD-MM-YYYY").isSameOrAfter('2020-11-24') && moment(item.fechaReporte, "DD-MM-YYYY").isBefore('2020-12-04')) {
          return `0.9 – 1.1`;
        } else {
          return `9 – 11 NTU`;
        }
      }
    }
  }

  return (
    <Document>
      <Page size="A4" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="A4" />
        <View style={mainContainerStyle}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Informacion del paciente:</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Nombres: {item.clientID.nombres ? `${item.clientID.nombres}` : ""}</Text>
              <Text style={textStyle}>Apellidos: {item.clientID.apellidos ? `${item.clientID.apellidos}` : ""}</Text>
              <Text style={textStyle}>Carnet de Identidad: {item.clientID.ci ? `${item.clientID.ci}` : ""}</Text>
              <Text style={textStyle}>C&oacute;digo de Paciente: {`${item.codigo}`}</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha de Nacimiento: {item.clientID.fecha_nacimiento ? `${moment(item.clientID.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")}` : ""}</Text>
              <Text style={textStyle}>Edad: {edadPaciente} </Text>
              <Text style={textStyle}>Sexo: {item.clientID.sexo ? `${item.clientID.sexo}` : ""}</Text>
              <Text style={textStyle}>Tel&eacute;fono: {item.clientID.telefono ? `${item.clientID.telefono}` : ""}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha Toma de Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha de Recepci&oacute;n de la Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha del Informe: {item.fechaReporte ? moment(item.fechaReporte, "DD-MM-YYYY").format("DD/MM/YYYY") : defaultDateReport.format("DD/MM/YYYY")} </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra: {item.tipoDeMuestra}</Text>
              <Text style={textStyle}>T&eacute;cnica: {TECNICA}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>PRUEBA: {TIPO_DE_PRUEBA1}</Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 4, marginBottom: 3 }]}>
            <Text style={textStyle}>RESULTADO:</Text>
            <Text style={ResultTextStyle}>{getResultElisa(resultadoElisa[0], resultadoElisa[1], moment(item.fechaReporte, "DD-MM-YYYY"))} {getResultLabel(getResultElisa(resultadoElisa[0], resultadoElisa[1], moment(item.fechaReporte, "DD-MM-YYYY")))}</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 5 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>PRUEBA: {TIPO_DE_PRUEBA2}</Text>
            </View>
          </View>
          <View style={[dataContainerStyle, { marginTop: 4, marginBottom: 3 }]}>
            <Text style={textStyle}>RESULTADO:</Text>
            <Text style={ResultTextStyle}>{getResultElisa(resultadoElisa[2], resultadoElisa[3], moment(item.fechaReporte, "DD-MM-YYYY"))} {getResultLabel(getResultElisa(resultadoElisa[2], resultadoElisa[3], moment(item.fechaReporte, "DD-MM-YYYY")))}</Text>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 5 }]}>
            <Text style={[interpretacionHeadlineStyle]}>Interpretaci&oacute;n del informe</Text>
          </View>

          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>REACTIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}> <RenderValorReferencia tipo="positivo" /> </Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Los anticuerpos contra el pat&oacute;geno est&aacute;n presentes. Ha producido un contacto con el ant&iacute;geno (pat&oacute;geno resp. vacuna).</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 20 }]}>INDETERMINADO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 20 }]}><RenderValorReferencia tipo="indeterminado" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Los anticuerpos contra el pat&oacute;geno no se pudieron detectar claramente. Se recomienda repetir la prueba con una muestra fresca en 2 a 4 semanas. Si el resultado es de nuevo en la zona intermedia, la muestra se considera como negativa.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>NO REACTIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1, paddingTop: 10 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}><RenderValorReferencia tipo="negativo" /></Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, borderBottomWidth: 1, padding: 4 }, rowRightStyle]}>
              <Text style={notesTextStyle}>La muestra no contiene anticuerpos contra el pat&oacute;geno. Un contacto previo con el ant&iacute;geno (pat&oacute;geno resp. vacuna) es poco probable.</Text>
            </View>
          </View>
          <View style={{ marginTop: 20 }}>
            <Image
              src={imgFirma}
              style={item.isSigned ? showSign : notShowSign}
            />
          </View>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  mainContainerStyle: {
    marginHorizontal: 60 //60
  },
  containerTituloStyle: {
    marginTop: 0, //20
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10 // 40
  },
  dataContainerStyle: {
    flexDirection: 'row',
  },
  medicoTextStyle: {
    color: '#333',
    fontSize: 12,
    paddingVertical: 2,
    marginLeft: 10
  },
  blueTextStyle: {
    textTransform: 'uppercase',
    color: '#61C0D4',
    fontSize: 12,
    paddingVertical: 2
  },
  titleStyle: {
    fontSize: 18
  },
  textStyle: {
    color: '#333',
    fontSize: 11,
    paddingVertical: 1
  },
  notesTextStyle: {
    color: '#333',
    fontSize: 9,
    paddingVertical: 1
  },
  ResultTextStyle: {
    color: '#000',
    fontSize: 13,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginLeft: 8,
    marginTop: -1
  },
  dividerStyle: {
    height: 2,
    backgroundColor: '#2DB9B4',
    marginVertical: 5 //20
  },
  rowLeftStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5
  },
  rowRightStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5
  },
  showSign: {
    objectFit: 'contain',
    height: 100,
    opacity: 1
  },
  qrImageStyle: {
    objectFit: 'contain',
    height: 80,
    width: 80
  },
  notShowSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 0
  },
  interpretacionHeadlineStyle: {
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
})

export default ReportElisa;