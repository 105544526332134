import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField, getEdad, calculateResultO, getOResult  } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
const TIPO_DE_PRUEBA1 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const ReportCOR = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    notesTextStyle,
    qrImageStyle
  } = stylesReport;

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <View style={[{ marginTop: 30 }]}>
          <InformacionDelPaciente
            paciente={paciente}
            fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
            fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
            codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
            ordenNo={estudio.ordenNo}
            medico={estudio.ordenID.nombreMedico}
            spaceMedico
          />
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
          
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${estudio.estudioID.nombre} `}</Text>
              </Text>
              <Text style={[textStyle, { marginLeft: 31 }]}>
                <Text style={dataTextStyle}>{`${estudio.estudioID.description} `}</Text>
              </Text>
              <Text style={textStyle}>Fecha toma de muestra:
                <Text style={dataTextStyle}> {moment(estudio.fechaTomaDeMuestra).utc().format("DD/MM/YYYY")} </Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>T&eacute;cnica: 
                <Text style={dataTextStyle}> {` ${estudio.estudioID.metodo} `} </Text>
              </Text>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:</Text>
              <Text style={textStyle}>{`${getValueField(estudio.camposEstudio, "anticuerpos_igm", "label")}`}:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "anticuerpos_igm")} `}</Text>
              </Text>
              <Text style={textStyle}>{`${getValueField(estudio.camposEstudio, "anticuerpos_igg", "label")}`}:
                <Text style={dataTextStyle}> {` ${getValueField(estudio.camposEstudio, "anticuerpos_igg")} `}</Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Observaciones:</Text>
              {
                getValueField(estudio.camposEstudio, "observaciones") !== "" ?
                <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "observaciones")} `}</Text>:null
              }
              
              <Text style={[textStyle, dataTextStyle]}>{` Los resultados emitidos deben ser interpretados por el médico tratante y/o valoración médica. `}</Text>
            </View>
          </View>
        </View>
        
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 25 }}
          isSigned={estudio.isSigned}
        />
        <View style={[mainContainerStyle]}>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
  )

}

export default ReportCOR;