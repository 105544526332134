import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import WaterMark from "../informes/WaterMark";

const TPB = ({ estudio, paciente }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
  } = stylesReport;

  return (
    <Page size="LETTER" wrap={true} style={pageStyle}>
      <Header />
      <WaterMark typePage="LETTER" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
        spaceMedico={true}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={[blueTextStyle, { textAlign: "center" }]}>
            {" "}
            TAMIZAJE NEONATAL - BASICO{" "}
          </Text>
        </View>
        <View style={[dataContainerStyle, { marginTop: 8 }]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>
              Metodo:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.metodo} `}</Text>
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[mainContainerStyle, { marginTop: 20, paddingHorizontal: 4 }]}
      >
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={blueTextStyle}> Prueba </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 10 }]}>
              TSH - Tirotrofina Neonatal
            </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
              17 - Hidroxi Progesterona Neonatal
            </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
              PKU - Fenilalanina Neonatal
            </Text>
            <Text style={[textStyle, dataTextStyle, { marginTop: 2 }]}>
              IRT - Fibrosis Quistica Neonatal
            </Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={[blueTextStyle, { textAlign: "right" }]}>
              {" "}
              Resultado{" "}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 10, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "tsh")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "hidroxi")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "pku")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "irt")} `}
            </Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={[blueTextStyle, { textAlign: "center" }]}>
              {" "}
              Unidad{" "}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 10, textAlign: "center" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "tsh", "unidad")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "center" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "hidroxi", "unidad")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "center" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "pku", "unidad")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "center" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "irt", "unidad")} `}
            </Text>
          </View>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={[blueTextStyle, { textAlign: "right" }]}>
              {" "}
              Valor de Referencia{" "}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 10, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "tsh", "referencia")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(
                estudio.camposEstudio,
                "hidroxi",
                "referencia"
              )} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "pku", "referencia")} `}
            </Text>
            <Text
              style={[
                textStyle,
                dataTextStyle,
                { marginTop: 2, textAlign: "right" },
              ]}
            >
              {` ${getValueField(estudio.camposEstudio, "irt", "referencia")} `}
            </Text>
          </View>
        </View>
        {getValueField(estudio.camposEstudio, "observaciones") !== "" ? (
          <View style={[dataContainerStyle, { marginTop: 20 }]}>
            <View style={{ flexDirection: "column", flex: 1 }}>
              <Text style={textStyle}>
                Observaciones:
                <Text style={dataTextStyle}>{` ${getValueField(
                  estudio.camposEstudio,
                  "observaciones"
                )} `}</Text>
              </Text>
            </View>
          </View>
        ) : null}
      </View>

      <FirmaEspecialista
        nombreEspecialista="ruth"
        styleFromParent={{ marginTop: 20 }}
        isSigned={estudio.isSigned}
      />
      <Footer />
    </Page>
  );
};

export default TPB;
