import React from "react";
import { Route, Switch } from "react-router-dom";
import EncuentraMiResultado from "../pages/EncuentraMiResultado";
import VerificarInforme from "../pages/VerificarInforme";
import MisResultados from "../pages/MisResultados";
import VerificarEstudio from "../pages/VerificarEstudio";
import ResultadosOrden from "../pages/ResultadosOrden";
import EncuentraMiResultadoMejorado from "../pages/EncuentraMiResultado2";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={EncuentraMiResultadoMejorado} />
    <Route exact path="/verify" component={VerificarInforme} />
    <Route exact path="/verificar-estudio" component={VerificarEstudio} />
    <Route exact path="/mis-resultados" component={MisResultados} />
    <Route exact path="/resultados-orden" component={ResultadosOrden} />
    <Route path="*" component={EncuentraMiResultadoMejorado} />
  </Switch>
);

export default Routes;
