import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Card } from "antd";
import { Redirect } from "react-router";
import { useManualQuery } from "graphql-hooks";
import { VERIFY_ESTUDIO_ORDEN } from "../queries";
import {
  calculateResultO,
  getOResult,
  getValueField,
  getResultElisaComplete,
  calculateResultAN,
  getANResult,
} from "../utils/common";
import moment from "moment";

// verificar-estudio?key=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlc3R1ZGlvWE9yZGVuSWQiOiI2MTM2YmNlOWNlOTU2MzAwMDQyZDc1M2EiLCJpYXQiOjE2MzEwNjcwMDB9.6ERIvOaLhKLx9eD1Fw1pMeMzrZrjP1BBxptqX2AJLrw

const queryString = require("query-string");
const TIPO_DE_PRUEBA1 = "SARS-CoV-2 (COVID19) IgG";
const TIPO_DE_PRUEBA2 = "SARS-CoV-2 (COVID19) IgM";
const TIPO_DE_PRUEBA3 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const VerificarEstudio = (props) => {
  const { Title } = Typography;
  const [getEstudioPublic] = useManualQuery(VERIFY_ESTUDIO_ORDEN);
  const [error, setError] = useState(null);
  const [information, setInfo] = useState(null);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    if (!parsed.key) {
      return <Redirect to="/" />;
    }
    verificarEstudio(parsed.key);
  }, [props.location.search]);

  const verificarEstudio = (key) => {
    getEstudioPublic({
      variables: { keyHash: key },
    }).then(({ data, error }) => {
      if (error) {
        let errors = "Hubo un problema al consultar el QR.";
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          error.graphQLErrors.map((e) => {
            errors = errors + `\n${e.message} `;
          });
        }
        alert(errors);
        return;
      }
      if (data.getEstudioPublic) {
        // console.log(data.getEstudioPublic);
        setInfo(data.getEstudioPublic);
      }
    });
  };

  const renderInterpretacion = (tipo) => {
    switch (tipo) {
      case "NEGATIVO": {
        return "No se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%";
      }
      case "INDETERMINADO": {
        return "Se detectaron anticuerpos neutralizantes con una tasa de inhibición dentro del rango de 30% a 35%";
      }
      case "POSITIVO": {
        return "Se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%";
      }
    }
  };

  const renderResultado = (codigoEstudio, campos) => {
    if (
      codigoEstudio === "PCRC" ||
      codigoEstudio === "PCRCS" ||
      codigoEstudio === "C" ||
      codigoEstudio === "ANTU" ||
      codigoEstudio === "AA" ||
      codigoEstudio === "ASC"
    ) {
      return (
        <p>
          Resultado: <strong>{getValueField(campos, "resultado")}</strong>
        </p>
      );
    }
    if (codigoEstudio === "E" || codigoEstudio === "ELICOV") {
      const fechaSolicitud = moment(information.fechaSolicitud).utc();

      //   const resultadoElisa = resultado.split('|');
      return (
        <>
          {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrAfter(
            "2022-01-15"
          ) ? (
            <>
              <p>PRUEBA: {TIPO_DE_PRUEBA1}</p>
              <p>
                Resultado:{" "}
                <strong> {getValueField(campos, "resultado_igg")} </strong>
              </p>
              <p>PRUEBA:{TIPO_DE_PRUEBA2}</p>
              <p>
                Resultado:{" "}
                <strong> {getValueField(campos, "resultado_igm")} </strong>
              </p>
            </>
          ) : null}
          {moment(fechaSolicitud.format("YYYY-MM-DD")).isSameOrBefore(
            "2022-01-14"
          ) ? (
            <>
              <p>PRUEBA: {TIPO_DE_PRUEBA1}</p>
              <p>
                Resultado:{" "}
                <strong> {`${getResultElisaComplete(campos, "igg")}`}</strong>
              </p>
              <p>PRUEBA:{TIPO_DE_PRUEBA2}</p>
              <p>
                Resultado:{" "}
                <strong> {`${getResultElisaComplete(campos, "igm")}`}</strong>
              </p>
            </>
          ) : null}
        </>
      );
    }
    if (codigoEstudio === "ANT") {
      const valueAN = calculateResultO(
        getValueField(campos, "valor_muestra"),
        getValueField(campos, "cutoff"),
        moment()
      );
      const resultLabel = getOResult(valueAN);
      // muestra, cutoff, date
      return (
        <>
          <p>PRUEBA: {TIPO_DE_PRUEBA3}</p>
          <p>
            Resultado: <strong> {`${resultLabel} `}</strong>
          </p>
          <p>
            Anticuerpos Neutralizantes: <strong> {`${valueAN}% `}</strong>
          </p>
          <p>
            Interpretación:{" "}
            <strong> {` ${renderInterpretacion(resultLabel)} `}</strong>
          </p>
        </>
      );
    }

    if (codigoEstudio === "AN") {
      const fechaSolicitud = moment(information.fechaSolicitud).utc();
      if (fechaSolicitud.isSameOrBefore("2021-11-25")) {
        const valorAN = calculateResultAN(
          getValueField(campos, "valor_muestra"),
          getValueField(campos, "cutoff")
        );
        const resultLabel = getANResult(valorAN);
        return (
          <>
            <p>PRUEBA: {TIPO_DE_PRUEBA3}</p>
            <p>
              Resultado: <strong> {`${valorAN}%    ${resultLabel} `}</strong>
            </p>
          </>
        );
      } else {
        // se usa la misma formula que la prueba de anticuerpos neutralizantes de TOTAL
        const valueAN = calculateResultO(
          getValueField(campos, "valor_muestra"),
          getValueField(campos, "cutoff"),
          moment()
        );
        const resultLabel = getOResult(valueAN);

        return (
          <>
            <p>PRUEBA: {TIPO_DE_PRUEBA3}</p>
            <p>
              Resultado: <strong> {`${resultLabel} `}</strong>
            </p>
            <p>
              Anticuerpos Neutralizantes: <strong> {`${valueAN}% `}</strong>
            </p>
            <p>
              Interpretación:{" "}
              <strong> {` ${renderInterpretacion(resultLabel)} `}</strong>
            </p>
          </>
        );
      }
    }

    if (codigoEstudio === "COR") {
      const valueAN = calculateResultO(
        getValueField(campos, "valor_muestra"),
        getValueField(campos, "cutoff"),
        moment()
      );
      // muestra, cutoff, date
      return (
        <>
          <p>PRUEBA: {information.estudioID.nombre}</p>
          <p>Resultado:</p>
          <p>
            {getValueField(campos, "anticuerpos_igm", "label")}:{" "}
            <strong> {`${getValueField(campos, "anticuerpos_igm")} `}</strong>
          </p>
          <p>
            {getValueField(campos, "anticuerpos_igg", "label")}:{" "}
            <strong> {`${getValueField(campos, "anticuerpos_igg")} `}</strong>
          </p>
        </>
      );
    }

    if (codigoEstudio === "CAB") {
      return (
        <>
          <p>PRUEBA: {information.estudioID.nombre}</p>
          <p>Resultado:</p>
          <p>
            {getValueField(campos, "sars", "label")}:{" "}
            <strong> {`${getValueField(campos, "sars")} `}</strong>
          </p>
          <p>
            {getValueField(campos, "influ_a", "label")}:{" "}
            <strong> {`${getValueField(campos, "influ_a")} `}</strong>
          </p>
          <p>
            {getValueField(campos, "influ_b", "label")}:{" "}
            <strong> {`${getValueField(campos, "influ_b")} `}</strong>
          </p>
        </>
      );
    }
  };

  return (
    <>
      <Row type="flex" style={{ backgroundColor: "white" }}>
        {error ? (
          <Col span={24} style={{ padding: "40px" }}>
            <Title
              level={2}
              style={{ textAlign: "center", color: "red", alignSelf: "center" }}
            >
              {error}
            </Title>
          </Col>
        ) : null}
        {information ? (
          <Col span={24}>
            <Card
              title={`Informe ${information.codigoEstudio}${information.estudioNumero} ${information.estudioID.nombre}`}
              style={{ width: "100%" }}
            >
              <p>Nombres: {information.ordenID.clientID.nombres} </p>
              <p>Apellidos: {information.ordenID.clientID.apellidos}</p>
              <p>CI: {information.ordenID.clientID.ci}</p>
              {information.ordenID.clientID.passport ? (
                <p>
                  Nro. de Pasaporte: {information.ordenID.clientID.passport}
                </p>
              ) : null}
              {information.ordenID.clientID.fecha_nacimiento ? (
                <p>
                  Fecha Nacimiento:{" "}
                  {information.ordenID.clientID.fecha_nacimiento}
                </p>
              ) : null}
              <p>
                Fecha Toma de Muestra:
                {moment(information.fechaTomaDeMuestra)
                  .utc()
                  .format("HH:mm") === "00:00"
                  ? ` ${moment(information.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY")}`
                  : ` ${moment(information.fechaTomaDeMuestra)
                      .utc()
                      .format("DD/MM/YYYY HH:mm")}`}
              </p>
              <p>
                Fecha del Informe:{" "}
                {moment(information.fechaReporte).utc().format("DD/MM/YYYY")}
              </p>
              {renderResultado(
                information.codigoEstudio,
                information.camposEstudio
              )}
            </Card>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default VerificarEstudio;
