import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField, getEdad, calculateResultO, getOResult  } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';
const TIPO_DE_PRUEBA1 = "Anticuerpos Neutralizantes (Anti-S/RBD) SARS-CoV-2";

const ReportANT = ({ estudio, paciente, qrValue }) => {

  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    notesTextStyle,
    qrImageStyle
  } = stylesReport;


  const valueAN = calculateResultO(getValueField(estudio.camposEstudio, 'valor_muestra'), getValueField(estudio.camposEstudio, 'cutoff'), moment(estudio.fechaReporte).utc())
  const resultLabel = getOResult(valueAN);

  const renderInterpretacion = (tipo) => {
    switch (tipo) {
      case 'NEGATIVO': {
        return 'No se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%';
      }
      case 'INDETERMINADO': {
        return 'Se detectaron anticuerpos neutralizantes con una tasa de inhibición dentro del rango de 30% a 35%';
      }
      case 'POSITIVO': {
        return 'Se detectaron anticuerpos neutralizantes con una tasa de inhibición > 35%';
      }
    }
  }

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
          <InformacionDelPaciente
            paciente={paciente}
            fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("DD/MM/YYYY")}
            fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
            codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
            ordenNo={estudio.ordenNo}
            medico={estudio.ordenID.nombreMedico}
            spaceMedico
          />
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Prueba:
                <Text style={dataTextStyle}>{` ${TIPO_DE_PRUEBA1} `}</Text>
              </Text>
              <Text style={textStyle}>Fecha toma de muestra:
                <Text style={dataTextStyle}>
                  {
                    moment(estudio.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                        ` ${moment(estudio.fechaTomaDeMuestra).utc().format('DD/MM/YYYY')}`
                          :
                      ` ${moment(estudio.fechaTomaDeMuestra).utc().format('DD/MM/YYYY HH:mm')}`
                  }
                </Text>
              </Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra:
                <Text style={dataTextStyle}>{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
              </Text>
              <Text style={textStyle}>T&eacute;cnica: 
                <Text style={dataTextStyle}> ELISA </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>

        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Resultado:
                <Text style={dataTextStyle}> {`${resultLabel}`}</Text>
              </Text>
              <Text style={textStyle}>Anticuerpos Neutralizantes:
                <Text style={dataTextStyle}> {`${valueAN}% `}</Text>
              </Text>
              <Text style={textStyle}>Interpretación:
                <Text style={dataTextStyle}> {` ${renderInterpretacion(resultLabel)} `}</Text>
              </Text>
              {
                getValueField(estudio.camposEstudio, "observacion") !== "" ?
                <Text style={textStyle}>Observaciones:
                  <Text style={dataTextStyle}>{` ${getValueField(estudio.camposEstudio, "observacion")} `}</Text>
                </Text>:null
              }
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={[textStyle, {marginBottom: 2}]}>Valores de Referencia:</Text>
              <Text style={[textStyle, dataTextStyle]}>{`NEGATIVO: < 30%`}</Text>
              <Text style={[textStyle, dataTextStyle]}>{`INDETERMINADO: DE 30 A 35%`}</Text>
              <Text style={[textStyle, dataTextStyle]}>{`POSITIVO: > 35%`}</Text>
            </View>
          </View>
          <View style={[dataContainerStyle, {marginTop:  10}]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los anticuerpos neutralizantes son capaces de bloquear eficazmente la infección, evitando que el virus se una y entre en las células y, en consecuencia, su replicación dentro del organismo. Por lo tanto, también se denominan anticuerpos inhibidores de virus y son los anticuerpos más asociados con la protección y la inmunidad.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los pacientes asintomáticos o que presentan síntomas leves pueden tardar más en dar positivo en la prueba o producir anticuerpos perceptibles con las técnicas utilizadas.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Los niveles altos de anticuerpos neutralizantes pueden requerir más de 21 días para establecerse y puede ser necesario un seguimiento serológico.`}</Text>
              <Text style={[notesTextStyle, {textAlign: 'justify'}]}>{`- Las pruebas de detección de IgG e IgM actuales (incluso aquellas que utilizan las proteínas S1, la subunidad RBD y la proteína S2 como diana) no investigan la función neutralizante. Estas pruebas solo indican si existe o no producción de anticuerpos contra ciertas proteínas (incluso aquellas pruebas que liberan resultados en concentración). Por tanto, la presencia de estos anticuerpos no garantiza necesariamente la neutralización del virus, ya que su existencia no significa FUNCIÓN neutralizante.`}</Text>
            </View>
          </View>
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 25 }}
          isSigned={estudio.isSigned}
        />
        <View style={[mainContainerStyle]}>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
  )

}

export default ReportANT;