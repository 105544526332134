import React from "react";
import { Page, Text, View, Document } from "@react-pdf/renderer";
import Header from "../informes/Header";
import Footer from "../informes/Footer";
import InformacionDelPaciente from "../informes/InformacionDelPaciente";
import moment from "moment";
import stylesReport from "../informes/styles";
import { getValueField } from "../../utils/common";
import FirmaEspecialista from "../informes/FirmaEspecialista";
import WaterMark from "../informes/WaterMark";

const ReportPDM = ({ estudio, paciente, qrValue }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    longTextStyle,
  } = stylesReport;

  return (
    <Page size="A4" wrap={true} style={[pageStyle, { paddingBottom: 100 }]}>
      <Header />
      <WaterMark typePage="A4" />
      <InformacionDelPaciente
        paciente={paciente}
        fechaSolicitud={moment(estudio.fechaSolicitud)
          .utc()
          .format("DD/MM/YYYY")}
        fechaReporte={moment(estudio.fechaReporte).utc().format("DD/MM/YYYY")}
        codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
        ordenNo={estudio.ordenNo}
        medico={estudio.ordenID.nombreMedico}
      />
      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>{estudio.areaDeEstudioID.nombre}</Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 2 }}>
            <Text style={textStyle}>
              Estudio:
              <Text
                style={dataTextStyle}
              >{` ${estudio.estudioID.description} `}</Text>
            </Text>
            <Text style={textStyle}>
              Indicación:
              <Text style={dataTextStyle}>{` ${getValueField(
                estudio.camposEstudio,
                "indicacion"
              )} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tipo de Muestra:
              <Text
                style={dataTextStyle}
              >{` ${estudio.tipoDeMuestraID.nombre} `}</Text>
            </Text>
            <Text style={textStyle}>
              Tecnica:
              <Text style={dataTextStyle}> {estudio.estudioID.metodo} </Text>
            </Text>
          </View>
        </View>
        <View style={dividerStyle}></View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>
            {"Análisis de mutación puntual en el gen de la protrombina F2"}
          </Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>
              Resultado:
              <Text style={dataTextStyle}>
                {" "}
                {getValueField(estudio.camposEstudio, "resultado_pro")}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Interpretación:</Text>
            <Text style={[textStyle, dataTextStyle]}>
              {" "}
              {getValueField(estudio.camposEstudio, "interpretacion_pro")}
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Notas:</Text>
            <Text style={longTextStyle}>
              {" "}
              -El resultado de este estudio deberá ser siempre interpretado en
              el contexto de datos clínicos y familiares.{" "}
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -Puede ser beneficioso para esta persona y sus familiares un
              asesoramiento genético-clínico para discutir las implicancias
              directas e indirectas de este resultado.
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -La variante alélica c.*97G&gt;A, también conocida como G20210A,
              del gen F2, está asociada a un aumento de la concentración
              plasmática del Factor 2 de la coagulación (Protrombina), que
              confiere un mayor riesgo de trombosis venosa a los individuos que
              la presentan en heterocigosis u homocigosis. Se ha observado una
              incidencia de 18% en pacientes con historia familiar de trombosis,
              mientras que en sujetos sanos es de aproximadamente 2%. Esta
              variante también ha sido asociada a abortos precoces y abortos
              recurrentes. El análisis molecular es de gran importancia
              diagnóstica.
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Metodología:</Text>
            <Text style={longTextStyle}>
              {" "}
              Extracción de ADN a partir de la muestra remitida. Amplificación
              de la región específica del gen F2 (Protrombina) (Chr11:46761055
              GRCh37) para la variante c.*97G&gt;A, también conocida como
              G20210A, empleando primers y sondas específicas rs1799963
              Genotyping Assay diseñado por Life Technologies, en un equipo de
              PCR en tiempo real. Finalmente, los resultados obtenidos son
              analizados e interpretados por un especialista en Biología
              Molecular. Transcripto de referencia F2: NM_000506.5.{" "}
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]} break>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>
            {"Análisis de variante puntual en el gen MTHFR"}
          </Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>
              Resultado:
              <Text style={dataTextStyle}>
                {" "}
                {getValueField(estudio.camposEstudio, "resultado_mthfr")}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Interpretación:</Text>
            <Text style={[textStyle, dataTextStyle]}>
              {" "}
              {getValueField(estudio.camposEstudio, "interpretacion_mthfr")}
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Notas:</Text>
            <Text style={longTextStyle}>
              {" "}
              -El resultado de este estudio deberá ser siempre interpretado en
              el contexto de datos clínicos y familiares.{" "}
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -Puede ser beneficioso para esta persona y sus familiares un
              asesoramiento genético-clínico para discutir las implicancias
              directas e indirectas de este resultado.
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -La enzima metilen-tetrahidrofolato reductasa (MTHFR) cataliza la
              reducción de 5,10-metilentetrahidrofolato a
              5-metiltetrahidrofolato, la principal forma circulante de folato y
              el donante de metilos para la remetilación de homocisteína a
              metionina dependiente de vitamina B12. Algunas variantes en este
              gen (c.665C&gt;T p.(Ala222Val) también conocida como C677T, entre
              otras) generan una enzima con actividad disminuida, lo que lleva a
              la acumulación de homocisteína, un aminoácido producido por
              nuestro organismo que cuando excede los niveles normales puede
              obstruir las arterias y aumentar el riesgo de ataque cardiaco,
              derrame cerebral o formación de coágulos. Las personas homocigotas
              para la variante C677T son las que tienen mayor riesgo de
              acumulación de homocisteína.
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Metodología:</Text>
            <Text style={longTextStyle}>
              {" "}
              Extracción de ADN a partir de la muestra remitida. Amplificación
              de la región específica del gen MTHFR (metilen-tetrahidrofolato
              reductasa) (Chr1:11856378 GRCh37) para la variante c.665C&gt;T
              p.(Ala222Val) también conocida como C677T empleando primers y
              sondas específicas rs1801133 Genotyping Assay diseñado por Life
              Technologies, en un equipo de PCR en tiempo real. Finalmente, los
              resultados obtenidos son analizados e interpretados por un
              especialista en Biología Molecular. Transcripto de referencia
              MTHFR: NM_005957.5{" "}
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]} break>
        <View style={dataContainerStyle}>
          <Text style={blueTextStyle}>
            {"Análisis de la variante de Leiden en el Factor V"}
          </Text>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>
              Resultado:
              <Text style={dataTextStyle}>
                {" "}
                {getValueField(estudio.camposEstudio, "resultado_factor_v")}
              </Text>
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Interpretación:</Text>
            <Text style={[textStyle, dataTextStyle]}>
              {" "}
              {getValueField(estudio.camposEstudio, "interpretacion_factor_v")}
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Notas:</Text>
            <Text style={longTextStyle}>
              {" "}
              -El resultado de este estudio deberá ser siempre interpretado en
              el contexto de datos clínicos y familiares.{" "}
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -Puede ser beneficioso para esta persona y sus familiares un
              asesoramiento genético-clínico para discutir las implicancias
              directas e indirectas de este resultado.
            </Text>
            <Text style={longTextStyle}>
              {" "}
              -El Factor V Leiden se origina por la variante: c.1601G&gt;A
              (p.Arg534Gln) en el gen F5, que genera resistencia a la actividad
              anticoagulante de la Proteina C activada. Es responsable de la
              forma más frecuente de trombofilia hereditaria. El FV Leiden se ha
              encontrado en alrededor de 20% de los casos con trombosis venosa
              profunda (TVP) y de 50% de los casos de TVP donde existe historia
              familiar. El riesgo relativo de trombosis conferido por este
              factor es de 7 para los portadores heterocigotos y de 80 en los
              homocigotos (PMID: 7888671).
            </Text>
          </View>
        </View>
      </View>

      <View style={[mainContainerStyle, { marginTop: 8 }]}>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: "column", flex: 1 }}>
            <Text style={textStyle}>Metodología:</Text>
            <Text style={longTextStyle}>
              {" "}
              Extracción de ADN a partir de la muestra remitida. Amplificación
              de la región específica del gen del Factor V (Chr1:169519049
              GRCh37) para la variante c.1601G&gt;A (p.Arg534Gln), empleando
              primers y sondas específicas rs6025 Genotyping Assay diseñado por
              Life Technologies, en el equipo de real-time PCR. Finalmente, los
              resultados obtenidos son analizados e interpretados por un
              especialista en Biología Molecular. Transcripto de referencia F5:
              NM_000130.4.{" "}
            </Text>
          </View>
        </View>
      </View>

      <FirmaEspecialista
        nombreEspecialista="pdm"
        styleFromParent={{ marginTop: 5 }}
        isSigned={estudio.isSigned}
      />

      <View style={[dataContainerStyle, { marginTop: 20 }]}>
        <FirmaEspecialista
          nombreEspecialista="natalia"
          styleFromParent={{ flexDirection: "column", flex: 1 }}
          isSigned={estudio.isSigned}
        />
        <FirmaEspecialista
          nombreEspecialista="sebastian"
          styleFromParent={{ flexDirection: "column", flex: 1 }}
          isSigned={estudio.isSigned}
          fechaReporte={estudio.fechaReporte}
        />
      </View>
      <Footer CS />
    </Page>
  );
};

export default ReportPDM;
