import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, } from '@react-pdf/renderer';
import imgFirma from '../assets/sebastian.png';
import moment from 'moment';
import { getEdad } from '../utils/common';
import Header from './informes/Header';
import Footer from './informes/Footer';
import WaterMark from './informes/WaterMark';

const TIPO_DE_PRUEBA = "Detección de Virus SARS-CoV-2 (COVID19)";
const TECNICA = "Reacción en Cadena de la Polimerasa en Tiempo Real | RT PCR";

const ReportPCR = ({ item, qrValue }) => {
  const { pageStyle, mainContainerStyle, containerTituloStyle, dataContainerStyle,
    blueTextStyle, titleStyle, textStyle, dividerStyle, ResultTextStyle, rowLeftStyle, rowRightStyle,
    notesTextStyle, showSign, notShowSign, interpretacionHeadlineStyle, qrImageStyle
  } = styles;

  let defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 2);
  if (defaultDateReport > moment()) {
    defaultDateReport = moment(item.fechaSolicitud, "DD-MM-YYYY").add('days', 1);
  }
  const edadPaciente = getEdad(item.clientID.fecha_nacimiento, item.clientID.edad);

  const RenderInterpretacion = (value) => {
    if (moment(item.fechaReporte, "DD-MM-YYYY").isSameOrBefore('2021-06-09')) {
      return (
        <View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', fontWeight: '800', fontSize: 10 }]}>Resultado</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', fontWeight: '800', fontSize: 10 }]}>Interpretaci&oacute;n</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>NO DETECTADO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
              <Text style={notesTextStyle}>No se detect&oacute; material gen&eacute;tico viral (L&iacute;mite de detecci&oacute;n: 1000 copias de virus/ml de muestra). Este resultado puede ser indicativo de que el paciente NO es portador del virus o la carga viral presente est&aacute; por debajo del l&iacute;mite de detecci&oacute;n.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 5 }]}>POSITIVO BAJO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Se detect&oacute; material gen&eacute;tico viral en el margen inferior de detecci&oacute;n. Este resultado puede ser indicativo del comienzo de una infecci&oacute;n o el final de la misma.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1 }, rowLeftStyle]}>
              <Text style={[notesTextStyle, { textAlign: 'center' }]}>POSITIVO</Text>
            </View>
            <View style={[{ flexDirection: 'column', flex: 3, borderBottomWidth: 1 }, rowRightStyle]}>
              <Text style={notesTextStyle}>Se detect&oacute; material gen&eacute;tico viral.</Text>
            </View>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={[notesTextStyle, { marginTop: 15 }]}>Nota:</Text>
              <Text style={[notesTextStyle, { marginTop: 10, fontStyle: 'italicu' }]}>El resultado refleja directamente la carga viral presente en el hisopado del paciente al momento de la toma de muestra, no son resultados que reflejan s&iacute;ntomas del paciente.</Text>
              <Text style={[notesTextStyle, { marginTop: 10 }]}>Los resultados pueden ser alterados si el paciente estuvo o se encuentra bajo alg&uacute;n tratamiento m&eacute;dico.</Text>
            </View>
          </View>
        </View>
      )
    }

    return (
      <View>
        <View style={[dataContainerStyle, { marginTop: 10 }]}>
          <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
            <Text style={[notesTextStyle, { textAlign: 'center', fontWeight: '800', fontSize: 10 }]}>Resultado</Text>
          </View>
          <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
            <Text style={[notesTextStyle, { textAlign: 'center', fontWeight: '800', fontSize: 10 }]}>Interpretaci&oacute;n</Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={[{ flexDirection: 'column', flex: 1 }, rowLeftStyle]}>
            <Text style={[notesTextStyle, { textAlign: 'center' }]}>DETECTADO</Text>
          </View>
          <View style={[{ flexDirection: 'column', flex: 3 }, rowRightStyle]}>
            <Text style={notesTextStyle}>Se detect&oacute; material gen&eacute;tico viral.</Text>
          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={[{ flexDirection: 'column', flex: 1, borderBottomWidth: 1 }, rowLeftStyle]}>
            <Text style={[notesTextStyle, { textAlign: 'center', paddingTop: 10 }]}>NO DETECTADO</Text>
          </View>
          <View style={[{ flexDirection: 'column', flex: 3, borderBottomWidth: 1 }, rowRightStyle]}>
            <Text style={notesTextStyle}>No se detect&oacute; material gen&eacute;tico viral (L&iacute;mite de detecci&oacute;n: 1000 copias de virus/ml de muestra). Este resultado puede ser indicativo de que el paciente NO es portador del virus o la carga viral presente est&aacute; por debajo del l&iacute;mite de detecci&oacute;n.</Text>

          </View>
        </View>
        <View style={[dataContainerStyle]}>
          <View style={{ flexDirection: 'column', flex: 1 }}>
            <Text style={[notesTextStyle, { marginTop: 15 }]}>Nota:</Text>
            <Text style={[notesTextStyle, { marginTop: 10, fontStyle: 'italicu' }]}>El resultado refleja directamente la carga viral presente en el hisopado del paciente al momento de la toma de muestra, no son resultados que reflejan s&iacute;ntomas del paciente.</Text>
            <Text style={[notesTextStyle, { marginTop: 10 }]}>Los resultados pueden ser alterados si el paciente estuvo o se encuentra bajo alg&uacute;n tratamiento m&eacute;dico.</Text>
          </View>
        </View>
      </View>
    )
  }

  return (
    <Document>
      <Page size="A4" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="A4" />
        <View style={mainContainerStyle}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}>Informacion del paciente: { }</Text>
          </View>
          <View style={[dataContainerStyle, { marginTop: 10 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Nombres: {item.clientID.nombres ? `${item.clientID.nombres}` : ""}</Text>
              <Text style={textStyle}>Apellidos: {item.clientID.apellidos ? `${item.clientID.apellidos}` : ""}</Text>
              <Text style={textStyle}>Carnet de Identidad: {item.clientID.ci ? `${item.clientID.ci}` : ""}</Text>
              <Text style={textStyle}>C&oacute;digo de Paciente: {`${item.codigo}`}</Text>
            </View>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha de Nacimiento: {item.clientID.fecha_nacimiento ? `${moment(item.clientID.fecha_nacimiento, "DD/MM/YYYY").format("DD/MM/YYYY")}` : ""}</Text>
              <Text style={textStyle}>Edad: {edadPaciente} </Text>
              <Text style={textStyle}>Sexo: {item.clientID.sexo ? `${item.clientID.sexo}` : ""}</Text>
              <Text style={textStyle}>Tel&eacute;fono: {item.clientID.telefono ? `${item.clientID.telefono}` : ""}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Fecha Toma de Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha de Recepci&oacute;n de la Muestra: {moment(item.fechaSolicitud, "DD-MM-YYYY").format("DD/MM/YYYY")}</Text>
              <Text style={textStyle}>Fecha del Informe: {item.fechaReporte ? moment(item.fechaReporte, "DD-MM-YYYY").format("DD/MM/YYYY") : defaultDateReport.format("DD/MM/YYYY")} </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Tipo de Muestra: {item.tipoDeMuestra}</Text>
              <Text style={textStyle}>Prueba: {TIPO_DE_PRUEBA}</Text>
              <Text style={textStyle}>T&eacute;cnica: {TECNICA}</Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 3, marginBottom: 3 }]}>
            <Text style={textStyle}>Resultado:</Text>
            <Text style={ResultTextStyle}>{`${item.resultado}`}</Text>
          </View>
          {
            item.observacion && item.observacion !== "NINGUNA" ?
              <View style={[dataContainerStyle, { marginTop: 5 }]} >
                <Text style={textStyle}>Observaciones: {item.observacion}</Text>
              </View> : null
          }
          <View style={dividerStyle}></View>
          <View style={[dataContainerStyle, { marginTop: 5 }]}>
            <Text style={[interpretacionHeadlineStyle]}>Interpretaci&oacute;n del informe</Text>
          </View>
          <RenderInterpretacion />
          <View style={{ marginTop: 20 }}>
            <Image
              src={imgFirma}
              style={item.isSigned ? showSign : notShowSign}
            />
          </View>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  pageStyle: {
    flex: 1,
    backgroundColor: '#FFFFFF'
  },
  mainContainerStyle: {
    marginHorizontal: 60 //60
  },
  containerTituloStyle: {
    marginTop: 0, //20
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 8 // 40
  },
  dataContainerStyle: {
    flexDirection: 'row',
  },
  blueTextStyle: {
    textTransform: 'uppercase',
    color: '#61C0D4',
    fontSize: 12,
    paddingVertical: 2
  },
  titleStyle: {
    fontSize: 18
  },
  textStyle: {
    color: '#333',
    fontSize: 11,
    paddingVertical: 1
  },
  notesTextStyle: {
    color: '#333',
    fontSize: 9,
    paddingVertical: 1
  },
  ResultTextStyle: {
    color: '#000',
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    marginLeft: 15,
    marginTop: -2
  },
  dividerStyle: {
    height: 2,
    backgroundColor: '#2DB9B4',
    marginVertical: 5 //20
  },
  rowLeftStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 5
  },
  rowRightStyle: {
    borderColor: '#000',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    paddingHorizontal: 5
  },
  showSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 1
  },
  qrImageStyle: {
    objectFit: 'contain',
    height: 80,
    width: 80
  },
  notShowSign: {
    objectFit: 'contain',
    height: 80,
    opacity: 0
  },
  interpretacionHeadlineStyle: {
    fontSize: 14,
    fontWeight: '800',
    textTransform: 'uppercase',
    textAlign: 'center'
  }
})

export default ReportPCR;