import React from "react";
import { Button, Popconfirm } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { genererateReporteUnificado } from "../../utils/common";
import moment from "moment";

import ReportElisa from "../../components/reports/ReportElisa";
import ReportPCRCOVID from "../../components/reports/C";
import ReportPPNI from "../../components/reports/PPNI";
import ReportPPNIE from "../../components/reports/PPNIE";
import ReportFishPrenatal from "../../components/reports/FP";
import ReportFishPOC from "../reports/FPOC";
import ReportCP from "../../components/reports/CP";
import ReportCPPT from "../../components/reports/CPPT";
import ReportCPPT2 from "../../components/reports/CPPT2";
import ReportDP from "../../components/reports/DP";
import ReportVPH from "../../components/reports/VPH";
import ReportCHEB from "../../components/reports/CHEB";
import ReportMT from "../../components/reports/MT";
import ReportHIV from "../../components/reports/HIV";
import ReportARVI from "../../components/reports/ARVI";
import TPB from "../../components/reports/TPB";
import TPA from "../../components/reports/TPA";
import R from "../../components/reports/R";
import TXG from "../../components/reports/TXG";
import CPRE from "../../components/reports/CPRE";
import HSG from "../../components/reports/HSG";
import PJ from "../../components/reports/PJ";
import FE from "../../components/reports/FE";
import AN from "../../components/reports/AN";
import ASC from "../../components/reports/ASC";
import AM from "../../components/reports/AM";
import ACGH from "../../components/reports/ACGH";
import EX from "../../components/reports/EX";
import FQ from "../../components/reports/FQ";
import XF from "../../components/reports/XF";
import PR from "../../components/reports/PR";
import PP from "../../components/reports/PP";
import BRNM from "../../components/reports/BRNM";
import CH from "../../components/reports/CH";
import {
  HA,
  EstudiosXArea,
  ANT,
  CEN,
  ReportASC_EN,
  COR,
  Simple,
  HO,
  HC,
  DZC,
  CAB,
  GHLA,
  ReportFH,
  ReportVPHG,
  ReportPDM,
} from "../../components/reports";
import {
  areasResultadosSimple,
  CODES_ESTUDIES,
  excepcionesEstudiosNoSencillos,
  excepcionesEstudiosResultadoSimple,
} from "../../config/constants";

const DescargarTodos = ({ size, disabled, allEstudies, miOrden, ordenNo }) => {
  const unificarInformes = (language = "ES") => {
    let ordenadosPorAreas = [];
    let estudiosDisponibles = allEstudies.filter((e) => {
      return e.estadoEstudio === "PROCESADO";
    });

    for (var i = 0; i < areasResultadosSimple.length; i++) {
      let areaID = areasResultadosSimple[i];
      const estudiosXArea = estudiosDisponibles.filter((e) => {
        if (
          areaID === e.areaDeEstudioID.id &&
          !excepcionesEstudiosResultadoSimple.includes(e.estudioID.id)
        ) {
          return e;
        }
      });

      if (estudiosXArea.length > 0) {
        ordenadosPorAreas.push(estudiosXArea);
        for (var k = 0; k < estudiosXArea.length; k++) {
          const estudio = estudiosXArea[k];
          estudiosDisponibles = estudiosDisponibles.filter((e) => {
            if (e.id !== estudio.id) return e;
          });
        }
      }
    }

    if (ordenadosPorAreas.length > 0) {
      const estudiosXArea = partEstudiosPorArea(ordenadosPorAreas);
      const estudiosComponent = estudiosDisponibles.map((est, i) => {
        return partInforme(est, i, language);
      });
      genererateReporteUnificado(
        [...estudiosComponent, ...[estudiosXArea]],
        miOrden,
        ordenNo
      );
    } else {
      const estudiosComponent = estudiosDisponibles.map((est, i) => {
        return partInforme(est, i, language);
      });
      genererateReporteUnificado(estudiosComponent, miOrden, ordenNo);
    }
  };

  const confirm = () => {
    // generateLetterPdf("ES");
    unificarInformes("ES");
  };

  const cancel = (e) => {
    // generateLetterPdf("EN");
    unificarInformes("EN");
  };

  const partEstudiosPorArea = (estudios) => {
    return (
      <EstudiosXArea
        estudios={estudios}
        orden={miOrden}
        paciente={miOrden.clientID}
      />
    );
  };

  const partInforme = (estudio, index, language) => {
    const qrCodeCanvas = document.getElementById(`canvas-${estudio.id}`);
    const qrCodeDataUri = qrCodeCanvas.toDataURL("image/jpg", 0.3);

    switch (estudio.codigoEstudio) {
      case "PCRC": {
        if (language === "EN") {
          return (
            <CEN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              qrValue={qrCodeDataUri}
              key={index}
              language={language}
            />
          );
        }
        return (
          <ReportPCRCOVID
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            qrValue={qrCodeDataUri}
            key={index}
          />
        );
      }

      case "PCRCS": {
        if (language === "EN") {
          return (
            <CEN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              qrValue={qrCodeDataUri}
              key={index}
              language={language}
            />
          );
        }
        return (
          <ReportPCRCOVID
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            qrValue={qrCodeDataUri}
            key={index}
          />
        );
      }

      case "ELICOV": {
        return (
          <ReportElisa
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            qrValue={qrCodeDataUri}
            key={index}
          />
        );
      }

      case "ELICOV": {
        return (
          <ReportElisa
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            qrValue={qrCodeDataUri}
            key={index}
          />
        );
      }

      case "PPNI": {
        return (
          <ReportPPNI
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "PPNIE": {
        return (
          <ReportPPNIE
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "FP": {
        return (
          <ReportFishPrenatal
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "FPOC": {
        return (
          <ReportFishPOC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "FH": {
        return (
          <ReportFH
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "FPE": {
        return (
          <ReportFH
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "FE": {
        return (
          <FE
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CP": {
        return (
          <ReportCP
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CA": {
        return (
          <ReportCP
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CI": {
        return (
          <ReportCP
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CPRE": {
        return (
          <CPRE
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CPPT": {
        return (
          <ReportCPPT
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CPPT2": {
        return (
          <ReportCPPT2
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "DP": {
        return (
          <ReportDP
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "VPH": {
        return (
          <ReportVPH
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CHEB": {
        return (
          <ReportCHEB
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "MT": {
        return (
          <ReportMT
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HIV": {
        return (
          <ReportHIV
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "ARVI": {
        return (
          <ReportARVI
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "TPB": {
        return (
          <TPB
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "TPA": {
        return (
          <TPA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "R": {
        return (
          <R
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "TXG": {
        return (
          <TXG
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HSG": {
        return (
          <HSG
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "PJ": {
        return (
          <PJ
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "AN": {
        // Por uso de un nuevo reactivo a partir del 26/11/2021 se va a usar el template de ANT
        const fechaSolicitud = moment(estudio.fechaSolicitud).utc();
        if (fechaSolicitud.isSameOrBefore("2021-11-25")) {
          return (
            <AN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              key={index}
              qrValue={qrCodeDataUri}
            />
          );
        }
        return (
          <ANT
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }

      case "ANT": {
        return (
          <ANT
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }

      case "ASC": {
        if (language === "EN") {
          return (
            <ReportASC_EN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              key={index}
              qrValue={qrCodeDataUri}
              language={language}
            />
          );
        }
        return (
          <ASC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }

      case "ANTU": {
        if (language === "EN") {
          return (
            <ReportASC_EN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              key={index}
              qrValue={qrCodeDataUri}
              language={language}
            />
          );
        }
        return (
          <ASC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }

      case "AA": {
        if (language === "EN") {
          return (
            <ReportASC_EN
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              key={index}
              qrValue={qrCodeDataUri}
              language={language}
            />
          );
        }

        return (
          <ASC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }

      case "AM": {
        return (
          <AM
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "ACGH": {
        return (
          <ACGH
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "EX": {
        return (
          <EX
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "FQ": {
        return (
          <FQ
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "XF": {
        return (
          <XF
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "PR": {
        return (
          <PR
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "PP": {
        return (
          <PP
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "BRNM": {
        return (
          <BRNM
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "CH": {
        return (
          <CH
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HA": {
        return (
          <HA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HBC": {
        return (
          <HA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HBG": {
        return (
          <HA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HCC": {
        return (
          <HA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HCG": {
        return (
          <HA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "COR": {
        return (
          <COR
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            qrValue={qrCodeDataUri}
            key={index}
          />
        );
      }

      case "HO": {
        return (
          <HO
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "HC": {
        return (
          <HC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "DZC": {
        return (
          <DZC
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "CAB": {
        return (
          <CAB
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
            qrValue={qrCodeDataUri}
          />
        );
      }
      case "GHLA": {
        return (
          <GHLA
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }
      case "VPHG": {
        return (
          <ReportVPHG
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      case "PDM": {
        return (
          <ReportPDM
            estudio={{ ...estudio, ordenID: miOrden }}
            paciente={miOrden.clientID}
            key={index}
          />
        );
      }

      default: {
        if (excepcionesEstudiosNoSencillos.includes(estudio.estudioID.id)) {
          console.log("es un resultado simple");
          return (
            <Simple
              estudio={{ ...estudio, ordenID: miOrden }}
              paciente={miOrden.clientID}
              key={index}
            />
          );
        }

        console.log("NOT SUPPORTED " + estudio.codigoEstudio);
        break;
        // return (
        //   <Page size="LETTER" wrap={true} style={pageStyle} key={index}>

        //   </Page>
        // )
      }
    }
  };

  const ConfirmButton = () => {
    return (
      <Popconfirm
        title={
          "¿Algunos informes pueden descargarse en otro idioma, en que idioma desea descargarlos?"
        }
        onConfirm={(e) => confirm()}
        onCancel={cancel}
        okText="Español"
        cancelText="Ingles"
        placement="left"
      >
        <Button
          type={disabled ? "text" : "primary"}
          shape="round"
          size="middle"
          disabled={checkDisableButton(allEstudies)}
          icon={<DownloadOutlined />}
          onClick={(e) => {
            e.preventDefault();
          }}
        ></Button>
      </Popconfirm>
    );
  };

  const checkIfReportsLanguageSupport = (estudios) => {
    let showLanguageOption = false;

    for (var i = 0; i < estudios.length; i++) {
      if (CODES_ESTUDIES.includes(estudios[i].codigoEstudio)) {
        showLanguageOption = true;
        break;
      }
    }

    return showLanguageOption;
  };

  const checkDisableButton = (estudios) => {
    let estudiosDisponibles = estudios.filter((e) => {
      return e.estadoEstudio !== "PROCESADO";
    });

    return estudiosDisponibles.length === estudios.length;
  };

  return (
    <>
      {checkIfReportsLanguageSupport(allEstudies) ? (
        <ConfirmButton />
      ) : (
        <Button
          type={disabled ? "text" : "primary"}
          shape="round"
          icon={<DownloadOutlined />}
          size="middle"
          disabled={checkDisableButton(allEstudies)}
          onClick={() => {
            unificarInformes();
          }}
        ></Button>
      )}
    </>
  );
};

export default DescargarTodos;
