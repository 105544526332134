import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import imgWaterMark from '../../assets/WM.png';

const WaterMark = ({typePage}) => {
  return (
    <View fixed style={typePage === 'A4' ? styles.waterMarkStyle : styles.waterMarkLetterStyle}>
      <Image
        src={imgWaterMark}
        style={typePage === 'A4' ? styles.waterMarkImageStyle : styles.waterMarkLetterImageStyle}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  waterMarkStyle: {
    position: 'absolute',
    top: 145,
    left: 0,
    right: 0,
  },
  waterMarkLetterStyle: {
    position: 'absolute',
    top: 120,
    left: 0,
    right: 0,
  },
  waterMarkImageStyle: {
    opacity: 0.7,
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '80%',
    height: '98%',
    objectFit: 'cover'
  },
  waterMarkLetterImageStyle: {
    opacity: 0.7,
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '87%',
    height: '86%',
    objectFit: 'cover'
  },

})

export default WaterMark;