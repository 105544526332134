import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { getEdad } from '../../utils/common'
import stylesReport from './styles';

const CabeceraUnificada = ({ paciente, fechaSolicitud, ordenNo, medico, spaceMedico }) => {

  const edadPaciente = getEdad(paciente.fecha_nacimiento, paciente.edad);
  const {
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle
  } = stylesReport;

  return (
    <View style={[mainContainerStyle, { marginTop: 0 }]}>
      <View style={dataContainerStyle}>
        <Text style={blueTextStyle}>NOMBRE DEL MÉDICO SOLICITANTE :
          <Text style={[textStyle, dataTextStyle, { textTransform: 'capitalize' }]}>{medico ? ` ${medico} ` : ""}</Text>
        </Text>
      </View>
      {
        spaceMedico ?
          <View style={{ marginTop: 15 }}></View> : null
      }
      <View style={dataContainerStyle}>
        <Text style={blueTextStyle}>Información del paciente:</Text>
      </View>
      <View style={[dataContainerStyle]}>
        <View style={{ flexDirection: 'column', flex: 2 }}>
          <Text style={textStyle}>
            Nombres y Apellidos:
            <Text style={dataTextStyle}>
              {paciente.nombres ? ` ${paciente.nombres} ` : ""}
            </Text>
            <Text style={dataTextStyle}>
              {paciente.apellidos ? `${paciente.apellidos}` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Carnet de Identidad:
            <Text style={dataTextStyle}>
              {paciente.ci ? ` ${paciente.ci} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Fecha de Nacimiento:
            <Text style={dataTextStyle}>
              {paciente.fecha_nacimiento ? ` ${paciente.fecha_nacimiento} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Fecha Solicitud:
            <Text style={dataTextStyle}>
              {` ${fechaSolicitud} `}
            </Text>
          </Text>
        </View>
        <View style={{ flexDirection: 'column', flex: 1 }}>
          <Text style={textStyle}>Sexo:
            <Text style={dataTextStyle}>
              {paciente.sexo ? ` ${paciente.sexo} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Edad:
            <Text style={dataTextStyle}>
              {` ${edadPaciente} `}
            </Text>
          </Text>
          <Text style={textStyle}>Tel&eacute;fono:
            <Text style={dataTextStyle}>
              {paciente.telefono ? ` ${paciente.telefono} ` : ""}
            </Text>
          </Text>
          <Text style={textStyle}>Nro de Orden:
            <Text style={dataTextStyle}>
              {` ${ordenNo} `}
            </Text>
          </Text>
        </View>
      </View>
      <View style={dividerStyle}></View>
    </View>
  )
}

export default CabeceraUnificada;

