import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import Header from '../informes/Header';
import Footer from '../informes/Footer';
import WaterMark from '../informes/WaterMark';
import InformacionDelPaciente from '../informes/InformacionDelPaciente';
import moment from 'moment';
import stylesReport from '../informes/styles';
import { getValueField } from '../../utils/common'
import FirmaEspecialista from '../informes/FirmaEspecialista';

const ReportASC_EN = ({ estudio, paciente, qrValue, language }) => {
  const {
    pageStyle,
    dataContainerStyle,
    blueTextStyle,
    textStyle,
    mainContainerStyle,
    dataTextStyle,
    dividerStyle,
    qrImageStyle
  } = stylesReport;

  const englishResult = (value) => {
    switch (value) {
      case 'NO DETECTADO':
        return "NOT DETECTED";
      case 'DETECTADO':
        return "DETECTED";
      default:
        return ""
    }
  }

  return (
      <Page size="LETTER" wrap={true} style={pageStyle}>
        <Header />
        <WaterMark typePage="LETTER" />
        <View style={[{ marginTop: 50 }]}>
          <InformacionDelPaciente
            paciente={paciente}
            fechaSolicitud={moment(estudio.fechaSolicitud).utc().format("MM/DD/YYYY")}
            fechaReporte={moment(estudio.fechaReporte).utc().format("MM/DD/YYYY")}
            codigoEstudio={`${estudio.codigoEstudio}${estudio.estudioNumero}`}
            ordenNo={estudio.ordenNo}
            medico={estudio.ordenID.nombreMedico}
            spaceMedico={true}
            language={language}
          />
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={dataContainerStyle}>
            <Text style={blueTextStyle}> {` Immunology `}</Text>
          </View>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Test name:
                <Text style={dataTextStyle}>{` Detection of SARS-CoV-2 (COVID19) `}</Text>
              </Text>
              <Text style={textStyle}>Technique:
                <Text style={dataTextStyle}>{` Rapid Antigen Test `}</Text>
              </Text>
              <Text style={textStyle}>Sample type:
                <Text style={dataTextStyle}>{` Nasopharyngeal swab `}</Text>
              </Text>
              <Text style={textStyle}>Sampling date:
                <Text style={dataTextStyle}>
                  {
                    moment(estudio.fechaTomaDeMuestra).utc().format('HH:mm') === "00:00" ?
                        ` ${moment(estudio.fechaTomaDeMuestra).utc().format('MM/DD/YYYY')}`
                          :
                      ` ${moment(estudio.fechaTomaDeMuestra).utc().format('MM/DD/YYYY HH:mm')}`
                  }
                </Text>
              </Text>
            </View>
          </View>
          <View style={dividerStyle}></View>
        </View>
        <View style={[mainContainerStyle, { marginTop: 8 }]}>
          <View style={[dataContainerStyle]}>
            <View style={{ flexDirection: 'column', flex: 2 }}>
              <Text style={textStyle}>Test result:
                <Text style={dataTextStyle}>{` ${englishResult(getValueField(estudio.camposEstudio, "resultado"))} `}</Text>
              </Text>
            </View>
          </View>
          {
            getValueField(estudio.camposEstudio, "observacion") !== "" ?
            <View style={[dataContainerStyle, { marginTop: 8 }]}>
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <Text style={textStyle}>Remarks:</Text>
                <Text style={[textStyle, dataTextStyle]}>{` ${getValueField(estudio.camposEstudio, "observacion")} `}</Text>
            </View>
          </View>:null
          }
        </View>
        <FirmaEspecialista
          nombreEspecialista="ruth"
          styleFromParent={{ marginTop: 30 }}
          isSigned={estudio.isSigned}
        />
        <View style={[mainContainerStyle]}>
          <View style={dataContainerStyle}>
            <Image
              source={{ uri: qrValue }}
              style={[qrImageStyle]}
            />
          </View>
        </View>
        <Footer language={language} />
      </Page>
  )
}

export default ReportASC_EN;